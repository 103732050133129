import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'

export const Privacy = () => {
  const { t, i18n } = useTranslation()
  const [privacyPolicy, setPrivacyPolicy] = useState('')

  useEffect(() => {
    const fetchPrivacy = async () => {
      try {
        const lang = i18n.language
        const privacyPolicyData = await import(
          `../../locales/files/privacy-policy-${lang}.json`
        )
        const paragraphs = privacyPolicyData.default.privacyPolicy.split('\n')
        setPrivacyPolicy(paragraphs)
      } catch (error) {
        console.error(error)
      }
    }
    fetchPrivacy()
  }, [i18n.language])

  return (
    <div>
      <Seo name="Privacy" />
      <HeaderDark />
      <div className="flex justify-center mt-16">
        <div className="container">
          <p className="xxs:text-[25px] sm:text-[36px] lg:text-[55px] font-extrabold mt-12">
            {t('footer.privacy')}
          </p>
          {Array.isArray(privacyPolicy) &&
            privacyPolicy.map((paragraph, index) => {
              const isNumberedHeading =
                typeof paragraph === 'string' && /^\d+\)/.test(paragraph)
              return isNumberedHeading ? (
                <h2
                  key={index}
                  className="text-[#464646] mt-[30px] text-[20px] md:text-[24px] font-semibold leading-[30px] md:leading-[50px]"
                >
                  {paragraph}
                </h2>
              ) : (
                <p key={index} className="mt-[30px] text-[#464646]">
                  {paragraph}
                </p>
              )
            })}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Privacy
