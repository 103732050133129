import { useEffect, useState } from "react"

export const useLocationRating = (locationId) => {
  const [productDetails, setProductDetails] = useState(null)
 

  useEffect(() => {
    if (locationId) {
      fetch(
        `${process.env.REACT_APP_API_URL}/partners/locations/rating_reviews?LocationId=${locationId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setProductDetails(data)
        })
    }
  }, [locationId])

  return productDetails;
}
