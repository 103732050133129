const WrongIcon = () => {
  return (
    <svg width="128" height="118" viewBox="0 0 128 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.22241 95.0879H123.666C124.2 95.0879 124.713 95.3003 125.091 95.6784C125.469 96.0564 125.682 96.5692 125.682 97.1039V106.435C125.681 109.451 124.483 112.344 122.35 114.476C120.217 116.609 117.325 117.807 114.309 117.808H13.5792C10.5623 117.808 7.66904 116.609 5.53579 114.476C3.40255 112.343 2.2041 109.45 2.2041 106.433V97.1039C2.2041 96.8389 2.25632 96.5766 2.35778 96.3319C2.45924 96.0871 2.60794 95.8648 2.79539 95.6775C2.98284 95.4903 3.20535 95.3418 3.45021 95.2407C3.69507 95.1395 3.95747 95.0876 4.22241 95.0879Z" fill="#FFDD84"/>
    <path d="M125.684 106.435V97.1039C125.682 96.5694 125.468 96.0573 125.09 95.6796C124.712 95.3018 124.2 95.0891 123.665 95.0879H117.329C102.757 106.482 76.9418 113.262 54.1846 117.808H114.308C117.322 117.799 120.21 116.598 122.342 114.468C124.473 112.337 125.674 109.449 125.684 106.435Z" fill="#FFD059"/>
    <path d="M120.072 69.0176H7.82033C6.4655 69.0176 5.36719 70.1159 5.36719 71.4707V75.4864C5.36719 76.8412 6.4655 77.9395 7.82033 77.9395H120.072C121.426 77.9395 122.525 76.8412 122.525 75.4864V71.4707C122.525 70.1159 121.426 69.0176 120.072 69.0176Z" fill="#870303"/>
    <path d="M104.213 95.087H115.056C116.969 95.0809 118.803 94.318 120.156 92.9649C121.509 91.6118 122.272 89.7783 122.278 87.8648V85.1628C122.274 83.945 121.963 82.7478 121.373 81.6824C120.783 80.617 119.934 79.7178 118.904 79.0684C116.655 86.4952 109.398 91.9874 104.213 95.087Z" fill="#7C4226"/>
    <path d="M120.232 69.3074H8.81088C6.65771 67.7472 5.36719 66.4567 5.36719 64.8895V53.1307C5.36964 52.3711 5.67249 51.6432 6.20963 51.1061C6.74678 50.569 7.47459 50.2661 8.23422 50.2637H119.655C120.415 50.2661 121.143 50.569 121.68 51.1061C122.217 51.6432 122.52 52.3711 122.522 53.1307V64.8802C122.522 66.4567 121.232 67.7425 120.232 69.3074Z" fill="#4A2C1D"/>
    <path d="M127.405 56.6821L122.387 46.7207H5.50139L0.483508 56.6821C-2.08821 61.7976 6.30825 62.9858 9.00321 61.3325C10.1054 60.6536 10.9332 59.5723 12.0028 58.8562C15.472 56.5309 19.3366 57.8935 22.2873 60.5303C22.2873 60.5303 31.979 70.9033 40.9777 61.3465C43.9842 58.1563 47.6418 56.4402 51.6994 58.6074C55.3733 60.5676 58.1589 64.4205 62.0653 65.8621C64.7649 66.862 67.7366 65.9923 69.9712 64.1228C71.4733 62.8626 72.2639 60.914 73.9055 59.856C78.156 57.1146 83.3925 57.6052 86.9269 61.3465C95.9256 70.9033 105.617 60.5303 105.617 60.5303C108.568 57.8935 112.432 56.5356 115.902 58.8562C116.971 59.5723 117.799 60.6536 118.901 61.3325C121.58 62.9858 129.981 61.786 127.405 56.6821Z" fill="#3EBA68"/>
    <path d="M3.20801 48.4442C3.20801 25.6312 29.4182 0 62.7669 0C96.1155 0 124.477 25.6312 124.477 48.4442H3.20801Z" fill="#FFDD84"/>
    <path d="M99.6627 11.7559C92.4242 30.9205 75.7778 42.1212 61.5264 48.4436H124.401C124.129 33.7759 112.061 18.506 99.6627 11.7559Z" fill="#FFD059"/>
    <path d="M41.1775 16.7671C39.9661 18.2111 38.4523 18.9343 37.799 18.3948C37.1456 17.8554 37.599 16.23 38.8104 14.7884C40.0219 13.3467 41.5356 12.6212 42.189 13.1607C42.8424 13.7002 42.389 15.3255 41.1775 16.7671Z" fill="#FFEBD4"/>
    <path d="M84.8917 17.1107C83.264 18.0524 81.5922 18.2175 81.1713 17.4781C80.7505 16.7386 81.7224 15.3853 83.3524 14.4343C84.9824 13.4833 86.6542 13.3275 87.0728 14.0669C87.4913 14.8064 86.524 16.1689 84.8917 17.1107Z" fill="#FFEBD4"/>
    <path d="M63.4236 16.7706C63.816 14.928 63.4569 13.2901 62.6216 13.1122C61.7864 12.9344 60.7912 14.2839 60.3989 16.1265C60.0065 17.9691 60.3656 19.6071 61.2009 19.7849C62.0361 19.9628 63.0313 18.6132 63.4236 16.7706Z" fill="#FFEBD4"/>
    <path d="M53.2666 9.86584C54.5106 11.2796 54.9989 12.8887 54.3595 13.4467C53.72 14.0048 52.19 13.3212 50.9437 11.9074C49.6974 10.4937 49.2114 8.88458 49.8508 8.3242C50.4903 7.76381 52.0226 8.44976 53.2666 9.86584Z" fill="#FFEBD4"/>
    <path d="M74.5141 10.2931C76.265 10.9907 77.43 12.1975 77.1137 12.9904C76.7975 13.7833 75.1233 13.8624 73.3724 13.1671C71.6215 12.4719 70.4542 11.2651 70.7705 10.4722C71.0867 9.67925 72.7609 9.59787 74.5141 10.2931Z" fill="#FFEBD4"/>
    <path d="M26.8174 69.8184L57.5106 78.545H76.5242C76.5242 78.545 58.0524 70.1509 26.8174 69.8184Z" fill="#4F4F4F"/>
    <path d="M119.345 77.9395H8.54276C6.30569 77.9395 4.49219 79.753 4.49219 81.99V91.0329C4.49219 93.27 6.30569 95.0835 8.54276 95.0835H119.345C121.582 95.0835 123.396 93.27 123.396 91.0329V81.99C123.396 79.753 121.582 77.9395 119.345 77.9395Z" fill="#4A2C1D"/>
    <path d="M55.2637 77.9395C55.2637 77.9395 84.6222 86.4847 93.4465 93.0884L109.421 78.1836L55.2637 77.9395Z" fill="#1C0A00"/>
    <path d="M92.9705 88.3082L111.235 69.3504L22.3389 69.3086C22.3389 69.3086 62.8051 72.1082 92.9705 88.3082Z" fill="#FDE14E"/>
    <path d="M6.6543 44.9269C6.6543 44.9269 13.9997 12.5083 50.3689 5.30469C50.3759 5.30469 23.0775 20.5862 6.6543 44.9269Z" fill="#FFEDBE"/>
    <path d="M72.7442 64.9664C73.5736 64.9664 74.2647 64.2807 74.0269 63.4861C73.619 62.1236 72.6532 60.8615 71.2257 59.8623C69.2918 58.5086 66.6689 57.748 63.934 57.748C61.1991 57.748 58.5762 58.5086 56.6424 59.8623C55.2148 60.8615 54.2491 62.1236 53.8412 63.4861C53.6033 64.2807 54.2944 64.9664 55.1239 64.9664C55.9533 64.9664 56.605 64.2693 56.9297 63.5061C57.2733 62.6987 57.8998 61.9555 58.7662 61.349C60.1368 60.3896 61.9957 59.8506 63.934 59.8506C65.8723 59.8506 67.7312 60.3896 69.1018 61.349C69.9683 61.9555 70.5948 62.6987 70.9383 63.5061C71.2631 64.2693 71.9148 64.9664 72.7442 64.9664Z" fill="#130D00"/>
    <path d="M31 43L45.5 57.5" stroke="black" stroke-width="5" stroke-linecap="round"/>
    <path d="M45.5 43L31 57.5" stroke="black" stroke-width="5" stroke-linecap="round"/>
    <path d="M81 43L95.5 57.5" stroke="black" stroke-width="5" stroke-linecap="round"/>
    <path d="M95.5 43L81 57.5" stroke="black" stroke-width="5" stroke-linecap="round"/>
    </svg>
  )
}

const WentWrongRescan = () => {
  return (
    <div className="flex flex-col justify-center items-center p-[10px]">
      <div className="mt-[10%] flex justify-center items-center w-[180px] h-[180px]">
        <WrongIcon />
      </div>
      <h1 className="font-extrabold text-[20px] mt-[10px]">
          Uups! Ceva nu a mers bine ...
      </h1>
      <p className="text-[15px]">
          Pont: înceară să scanezi codul QR din nou.
      </p>
    </div>
  )
}

export default WentWrongRescan
