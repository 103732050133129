import React from 'react'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'
import {useTranslation} from "react-i18next";

const PosArticle = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Seo name="Ce este un sistem POS (Point Of Sale/ Punct de vanzare)?" />
      <HeaderDark />
      <div className="container">
        <div className="article-page">
          <h1>{t('PosArticle.heading')}</h1>
          <img
            src="/assets/images/pos-img.png"
            alt="Ce este un sistem POS (Point Of Sale/ Punct de vanzare)?"
          />
          <h2>{t('PosArticle.subheading1')}</h2>
          <p>
            {t('PosArticle.subheading1para1')}
          </p>
          <p>
            {t('PosArticle.subheading1para2')}
          </p>
          <h2>{t('PosArticle.subheading2')}</h2>
          <p>
            <b>
              {t('PosArticle.subheading2para1bold')}
            </b>
            {t('PosArticle.subheading2para1')}
          </p>
          <h2>
            {t('PosArticle.subheading3')}
          </h2>
          <p>
            <b>{t('PosArticle.subheading3para1bold')}</b>
            {t('PosArticle.subheading3para1')}
          </p>
          <p>

          </p>
          <p>
            {t('PosArticle.subheading3para2')}
          </p>
          <p>
            {t('PosArticle.subheading3para3')}
          </p>
          <p>
            {t('PosArticle.subheading3para4')}
          </p>
          <h2>{t('PosArticle.subheading4')}</h2>
          <p>
            {t('PosArticle.subheading4para1')}
          </p>
          <p>
            {t('PosArticle.subheading4para2')}
          </p>
          <h2>{t('PosArticle.subheading5')}</h2>
          <p>
            {t('PosArticle.subheading5para1')}
          </p>
          <p>
            <b>{t('PosArticle.subheading5para2bold')}</b>
            {t('PosArticle.subheading5para2')}
            {t('PosArticle.subheading5para2')}
          </p>
          <p>
            {t('PosArticle.subheading5para3')}
          </p>
          <p>{t('PosArticle.subheading5para4')}</p>
          <p>
            {t('PosArticle.subheading5para5')}
          </p>
          <p>
            {t('PosArticle.subheading5para6')}
          </p>
          <p>
            <b>{t('PosArticle.subheading5para7bold')}</b>
            {t('PosArticle.subheading5para7')}
          </p>
          <p>
            {t('PosArticle.subheading5para8')}
          </p>
          <h2>{t('PosArticle.subheading6')}</h2>
          <p>
            {t('PosArticle.subheading6para1')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para2bold')}</b>
            {t('PosArticle.subheading6para2')}
          </p>
          <p>
            {t('PosArticle.subheading6para3')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para4bold')}</b>
            {t('PosArticle.subheading6para4')}
          </p>
          <p>
            {t('PosArticle.subheading6para5')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para6bold')}</b>
            <p>
              {t('PosArticle.subheading6para6')}
            </p>
          </p>
          <p>
            {t('PosArticle.subheading6para7')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para8bold')}</b>
            {t('PosArticle.subheading6para8')}
          </p>
          <p>
            {t('PosArticle.subheading6para9')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para10bold')}</b>
            {t('PosArticle.subheading6para10')}
          </p>
          <p>
            {t('PosArticle.subheading6para11')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para12bold')}</b>
            {t('PosArticle.subheading6para12')}
          </p>
          <p>
            <b>
              {t('PosArticle.subheading6para13bold')}
            </b>
            {t('PosArticle.subheading6para13')}
          </p>
          <p>
            <b>{t('PosArticle.subheading6para14bold')}</b>
            <p>
              {t('PosArticle.subheading6para14')}
            </p>
          </p>
          <h2>{t('PosArticle.subheading7')}</h2>
          <p>
            {t('PosArticle.subheading7para1')}
          </p>
          <p>
            <b>
              {t('PosArticle.subheading7para2bold')}
            </b>
            {t('PosArticle.subheading7para2')}
          </p>
          <p>
            {t('PosArticle.subheading7para3')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para4bold')}</b>
            {t('PosArticle.subheading7para4')}
          </p>
          <p>
            {t('PosArticle.subheading7para5')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para6bold')}</b>
            {t('PosArticle.subheading7para6')}
          </p>
          <p>
            {t('PosArticle.subheading7para7')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para8bold')}</b>
            {t('PosArticle.subheading7para8')}
          </p>
          <p>
            {t('PosArticle.subheading7para9')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para10bold')}</b>
            {t('PosArticle.subheading7para10')}
          </p>
          <p>
            {t('PosArticle.subheading7para11')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para12bold')}</b>
            {t('PosArticle.subheading7para12')}
          </p>
          <p>
            {t('PosArticle.subheading7para13')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para14bold')}</b>
            {t('PosArticle.subheading7para14')}
          </p>
          <p>
            {t('PosArticle.subheading7para15')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para16bold')}</b>
            {t('PosArticle.subheading7para16')}
          </p>
          <p>
            {t('PosArticle.subheading7para17')}
          </p>
          <p>
            <b>{t('PosArticle.subheading7para18bold')}</b>
            {t('PosArticle.subheading7para18')}
          </p>
          <h2>{t('PosArticle.subheading8')}</h2>
          <p>
            {t('PosArticle.subheading8para1')}
          </p>
          <p>
            {t('PosArticle.subheading8para2')}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PosArticle
