import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../common'
import { IoMdClose, IoMdArrowBack } from 'react-icons/io'
import { useContext } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import { useTranslation } from 'react-i18next'

const Header03 = ({ title, showBack, colorCode = '#FFB800' }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { setBusket, setTips } = useContext(ProductBusketContext)
  const { uuid, productId, location } = useContext(GlobalContext)
  const { state } = useLocation();
  const { pay_later_status } = state || {};
  const handleGoBack = () => {
    if (title === t('product.thankYou')) {
      if(pay_later_status != 'show_paylater_order'){
        let busket = JSON.parse(window.localStorage.getItem('busket')) || [];
        const filteredBusket = busket.filter(item => item.locationId !== location.LocationId);
        window.localStorage.setItem('busket', JSON.stringify(filteredBusket));
        window.localStorage.removeItem('tips');
        setBusket(filteredBusket)
        setTips(0)
      }
      navigate(`/product/${productId}?${uuid}`)
      
    } else if (title == t('product.payment')) {
      navigate(-1)
    } else {
      navigate(`/product/${productId}?${uuid}`)
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full z-[9]">
      <div className={`relative h-[60px] flex justify-center items-center bg-white`}>
        <Button
          style={{ borderColor: 'transparent' }}
          className={`bg-white ${title !== t('product.payment') ? 'right-[10px]' : 'left-[10px]'} absolute px-[8px] text-[24px]`}
          variant="icon"
          icon={title == t('product.payment') ? <IoMdArrowBack /> : <IoMdClose />}
          onClick={handleGoBack}
        />
        <div className="flex justify-center items-center text-lg font-medium ">
          {title}
        </div>
      </div>
    </div>
  )
}

export default Header03
