import { useTranslation } from 'react-i18next'
import RectangleButton from '../../common/RectangleButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const HomeSection = ({ handleClick }) => {
  const { t } = useTranslation()
  return (
    <div
      className="relative overflow-hidden pt-[200px] pb-[200px] min-h-[850px]"
      id="home"
    >
      <LazyLoadImage
        src="/assets/images/Vector 5.png"
        className="home-section-bg w-full h-full object-cover absolute top-0 left-[50%] translate-x-[-70%] min-w-[1600px] md:translate-x-[-50%]"
        alt=""
        effect="opacity"
      />
      <div className="absolute top-0 left-[50%] w-full h-full bg-gradient-to-b from-[#00000066] translate-x-[-50%]"></div>
      <div className="container z-5 relative">
        <div className="w-full h-full text-white flex items-center">
          <div className="flex flex-col items-start gap-[20px]">
            <div>
              <h1 className="md:text-[90px] sm:text-[47px] text-[37px] font-bold md:leading-[80px] leading-[49px] uppercase">
                {t('homepage.hero_title1')}
              </h1>
              <h2 className="md:text-[65px] sm:text-[45px] text-[30px] font-bold md:leading-[80px] leading-[49px] uppercase">
                {t('homepage.hero_title2')}
              </h2>
              <h3 className="md:text-[60px] sm:text-[37px] text-[27px] font-bold md:leading-[80px] leading-[49px] uppercase">
                {t('homepage.hero_title3')}
              </h3>
            </div>
            <p className="md:max-w-[546px] max-w-[336px]">
              {t('homepage.hero_text')}
            </p>
            <RectangleButton
              className="uppercase"
              size="large"
              text={t('homepage.become')}
              onClick={() => handleClick('Home Section')}
            ></RectangleButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSection
