import { VscError } from 'react-icons/vsc'
import { BsCheck2Circle } from 'react-icons/bs'
import { useState } from 'react'
import RectangleButton from '../../common/RectangleButton'
import { useTranslation } from 'react-i18next'
import { BsArrowRight } from 'react-icons/bs'
import { IoCloseSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Pricing = ({ id, handleClick }) => {
  const { t } = useTranslation()
  const [detailsPopupOpen, setDetailsPopupOpen] = useState(false)
  if (detailsPopupOpen) {
    document.body.classList.add('body-lock')
  } else {
    document.body.classList.remove('body-lock')
  }
  const infoList = [
    {
      title: t('pricing.restaurants'),
      bronze: '1',
      silver: '1-5',
      gold: '∞',
    },
    {
      title: t('pricing.digital_menu'),
      bronze: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.unlimited_products'),
      bronze: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.pos_support'),
      bronze: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.admin_console'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.nutrition_calculation'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.online_payment'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.call_waiter'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.takeaway_order'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.printing'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <VscError color="#E00000" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.internationalization'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <VscError color="#E00000" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.online_reservation'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <VscError color="#E00000" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.personalization'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <VscError color="#E00000" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
    {
      title: t('pricing.menu_personalization'),
      bronze: <VscError color="#E00000" className="mx-auto" />,
      silver: <VscError color="#E00000" className="mx-auto" />,
      gold: <BsCheck2Circle color="#54B20C" className="mx-auto" />,
    },
  ]
  return (
    <>
      <div
        id={id}
        className="relative pt-[120px] pb-[120px] md:pt-[235px] md:pb-[220px] overflow-hidden"
      >
        <LazyLoadImage
          src="/assets/images/price_bg.png"
          className="w-full h-full absolute bottom-0 right-0 md:right-auto md:left-0 -z-5 min-w-[1600px]"
          alt=""
          effect="opacity"
        />
        <div className="container text-white z-2 relative">
          <h2 className="uppercase md:text-[60px] text-[40px] font-bold text-center">
            {t('homepage.pricing_title')}
          </h2>
          <p className="max-w-[750px] text-center mx-auto">
            {t('homepage.pricing_text')}
          </p>
        </div>
        <div className="flex xl:justify-center px-[20px] gap-[20px] md:gap-[30px] mt-[55px] overflow-auto snap-x">
          <div className="w-[260px] md:w-[350px] flex-shrink-0">
            <div className="bg-white rounded-[15px] shadow-lg h-full p-[25px] pb-[35px] md:px-[40px] md:pt-[30px] md:pb-[20px] relative">
              <p className="text-black opacity-50">BRONZE</p>
              <h3 className="text-[20px] md:text-[30px] mb-[20px] font-bold text-black mt-[12px] md:mb-[50px]">
                0 RON
              </h3>
              <div className="absolute top-[10px] right-[10px] w-[97px] h-[97px] xl:w-[130px] xl:h-[130px]">
                <LazyLoadImage
                  src="/assets/images/bronze-img.png"
                  className="w-full h-full"
                  alt=""
                  effect="opacity"
                />
              </div>
              {/* <p className="text-[#000] font-medium text-[14px] md:text-[16px] mb-[22px]  min-h-[150px]">
                {t('homepage.pricing_bronze_text')}
              </p> */}

              <p className="text-[#000] font-medium text-[14px] md:text-[16px] mb-[22px]  min-h-[150px]">
                {t('homepage.pricing_bronze_text')}{' '}
                <Link
                  to={
                    'https://www.ebriza.com/app/public/marketplace/apps/40a256385eb04a4eb40b3611b5cf1d08-com-adhoc-adh'
                  }
                  style={{ textDecoration: 'underline', color: 'blue' }}
                  target="_blank"
                >
                  {t('homepage.pricing_bronze_ebriza')}
                </Link>{' '}
                {t('homepage.pricing_bronze_end')}
              </p>

              <RectangleButton
                icon={<BsArrowRight />}
                text={t('homepage.pricing_details')}
                className="h-[41px] rounded-lg border-[#46464687] border-solid bg-[#FFF] border-[1px] hover:bg-[#808080] hover:text-white transition duration-200"
                variant="icon"
                onClick={() => setDetailsPopupOpen(true)}
              />
            </div>
          </div>
          <div className="w-[260px] md:w-[350px] flex-shrink-0 snap-center">
            <div className="bg-white rounded-[15px] shadow-lg h-full p-[25px] pb-[35px] md:px-[40px] md:pt-[30px] md:pb-[20px] relative">
              <p className="text-black opacity-50">SILVER</p>
              <h3 className="text-[20px] md:text-[30px] mb-[20px] font-bold text-black mt-[12px] md:mb-[50px]">
                200 RON
              </h3>
              <div className="absolute top-[10px] right-[10px] w-[97px] h-[97px] xl:w-[130px] xl:h-[130px]">
                <LazyLoadImage
                  src="/assets/images/silver-img.png"
                  className="w-full h-full"
                  alt=""
                  effect="opacity"
                />
              </div>
              <p className="text-[#000] font-medium text-[14px] md:text-[16px] mb-[22px] min-h-[150px]">
                {t('homepage.pricing_silver_text')}
              </p>
              <RectangleButton
                icon={<BsArrowRight />}
                text={t('homepage.pricing_details')}
                className="h-[41px] rounded-lg border-[#46464687] border-solid bg-[#FFF] border-[1px] hover:bg-[#808080] hover:text-white transition duration-200"
                variant="icon"
                onClick={() => setDetailsPopupOpen(true)}
              />
            </div>
          </div>
          <div className="w-[260px] md:w-[350px] flex-shrink-0">
            <div className="bg-white rounded-[15px] shadow-lg h-full p-[25px] pb-[35px] md:px-[40px] md:pt-[30px] md:pb-[20px] relative">
              <p className="text-black opacity-50">GOLD</p>
              <h3 className="text-[15px] md:text-[25px] mb-[30px] font-bold text-black mt-[12px] md:mb-[50px]">
                {t('homepage.pricing_personal')}
              </h3>
              <div className="absolute top-[10px] right-[10px] w-[97px] h-[97px] xl:w-[130px] xl:h-[130px]">
                <LazyLoadImage
                  src="/assets/images/gold-img.png"
                  className="w-full h-full"
                  alt=""
                  effect="opacity"
                />
              </div>
              <p className="text-[#000] font-medium text-[14px] md:text-[16px] mb-[22px] min-h-[157px]">
                {t('homepage.pricing_gold_text')}
              </p>
              <RectangleButton
                text="Get started"
                onClick={() => handleClick('Pricing - Get Started')}
              ></RectangleButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all flex justify-center ${
          detailsPopupOpen
            ? 'opacity-100 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setDetailsPopupOpen(false)}
      >
        <div
          className="relative overflow-auto my-[30px] w-full h-fit max-w-[860px] bg-white rounded-[10px] border-[1px] border-[#CDCDCD7D] shadow-[3px_5px_40px_3px_rgba(205,205,205,0.45)] px-[25px] py-[45px]"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            type="buttom"
            className="fixed top-[66px] md:absolute md:top-[28px] right-[28px] p-[4px] shadow-[3px_5px_40px_3px_rgba(205,205,205,0.45)] transition-all bg-white hover:opacity-50"
            onClick={() => setDetailsPopupOpen(false)}
          >
            <IoCloseSharp size={38} />
          </button>
          <h2 className="w-full text-[22px] font-bold mb-[25px] pl-[37px]">
            {t('pricing.packages')}
          </h2>
          <table className="w-[600px] md:w-full overflow-auto">
            <thead>
              <tr className="bg-[#FFF3D3] text-[18px] font-semibold border-b-[1px] border-[#D9D9D9]">
                <th
                  width="55%"
                  className="py-[10px] pl-[37px] text-left uppercase"
                >
                  {t('pricing.operations')}
                </th>
                <th width="15% text-center">BRONZE</th>
                <th width="15% text-center">SILVER</th>
                <th width="15% text-center">GOLD</th>
              </tr>
            </thead>
            <tbody>
              {infoList.map((item, index) => (
                <tr
                  key={index}
                  className="even:bg-[#F5F5F5] even:border-b-[1px] even:border-t-[1px] even:border-[#D9D9D9]"
                >
                  <td className="pt-[10px] pb-[10px] pl-[40px]">
                    {item.title}
                  </td>
                  <td className="text-center">{item.bronze}</td>
                  <td className="text-center">{item.silver}</td>
                  <td className="text-center">{item.gold}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Pricing
