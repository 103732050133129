export function scrollToTop(callback) {
    // Function to check if the page is at the top
    const isAtTop = () => window.scrollY === 0;
  
    // Smooth scroll to the top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  
    // Function to monitor scroll position
    function checkIfAtTop() {
      if (isAtTop()) {
        callback(); // Call the callback function when at the top
      } else {
        requestAnimationFrame(checkIfAtTop); // Continue checking until the top is reached
      }
    }
  
    checkIfAtTop(); // Start checking the scroll position
  }
  
  // Example usage
  scrollToTop(() => {
    console.log('Scrolled to the top!');
  });