import { TextInput } from '../../common'
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai'
import { IoCheckmark } from 'react-icons/io5'
import useViewportWidth from '../../hooks/useViewportWidth'
import { useContext, useEffect, useState } from 'react'
import useValidProductURL from '../../hooks/useValidProduct'
import { GlobalContext } from '../../appContext/GlobalContext'
import HelpPopup from '../../components/HelpPopup/HelpPopup'
import { useLocationRating } from '../../hooks/useLocationRating'
import { StarIcon } from '../../components/icon'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { NotWarning } from '../../components/icon/NotWarning'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

const getTodayHours = (location, daysOfWeek) => {
  const currentDateTime = new Date()
  const currentDay = daysOfWeek[currentDateTime.getDay()]
  const currentTime = `${currentDateTime.getHours() < 10 ? '0' : ''}${currentDateTime.getHours().toString()}:${currentDateTime.getMinutes() < 10 ? '0' : ''}${currentDateTime.getMinutes().toString()}`

  return location?.openHours?.find(
    (day) => day?.day?.toLowerCase() === currentDay.toLowerCase() && (day?.openingHour < day?.closingHour ?
      (currentTime >= day?.openingHour && currentTime <= day?.closingHour) : (currentTime >= day?.openingHour || currentTime <= day?.closingHour)) && day?.active
  )
}

const ProductBrand = ({ onReviewsClick, orderAcceptanceStatus }) => {
  const { location, colorCode, setIsOpen, isOpen, autoOrder, setOpenCloseHour } = useContext(GlobalContext)
  const { t } = useTranslation()
  const viewportWidth = useViewportWidth()
  const [productDetails, setProductDetails] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isJpg, setIsJpg] = useState(false)
  const validUrl = useValidProductURL()
  const helpPopupDisplay = JSON.parse(
    JSON.stringify(getCookie('helpPopupDisplay'))
  )
  const daysOfWeek = [
    'Duminică',
    'Luni',
    'Marți',
    'Miercuri',
    'Joi',
    'Vineri',
    'Sâmbătă',
  ]

  const getCurrentDayOpenHours = () => {
    const todayHours = getTodayHours(location, daysOfWeek)
    if (todayHours) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    return todayHours
      ? `${todayHours.openingHour} - ${todayHours.closingHour}`
      : <span className='flex text-lg'><img src='/assets/images/icons/lock.svg' className='mr-3' width={20} height={20} /> {t('restaurant.we_are_closed')}</span>
  }
  //location config api

  const [locationData, setLocationData] = useState(null)

  useEffect(() => {

    let schHours = getTodayHours(location, daysOfWeek)
    if (schHours) {
      let schedule = {
        openHour: schHours.openingHour,
        closeHour: schHours.closingHour
      }
      setOpenCloseHour(schedule)
    }
    fetchLocationData()
  }, [])

  const fetchLocationData = async () => {
    try {
      await fetch(`/partners/locations/configuration`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setLocationData(data)
        })
    } catch (error) {
      console.error('Error fetching location data:', error)
    }
  }
  const orderLimit = location?.orderLimitReached

  useEffect(() => {
    if (location?.LocationId) {
      fetch(
        `${process.env.REACT_APP_API_URL}/partners/locations/rating_reviews?LocationId=${location?.LocationId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setProductDetails(data)
        })
    }
  }, [location?.LocationId])

  useEffect(() => {
    if (!helpPopupDisplay) {
      setIsVisible(true)
      setCookie('helpPopupDisplay', true, 100)
    }
  }, [helpPopupDisplay])
  return (
    <div className="mb-[60px]">
      <div
        style={{
          backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.5), 
              rgba(0, 0, 0, 0.5)
              ), url('${location?.back_image}')`,
        }}
        className="relative bg-cover bg-center sm:h-[250px] h-[150px] w-full rounded-[20px] m-auto flex flex-col gap-[10px] items-center sm:justify-center justify-start mt-4"
      >
        {(orderLimit || !orderAcceptanceStatus) ?
          <div className="absolute xxs:w-[100%] sm:w-[90%] top-[-15px] w-[80%] max-w-[700px] min-h-[73px] bg-white rounded-[20px] shadow-md flex items-center justify-around flex-col">
            <div className="flex xxs:items-start xxs:justify-start xxs:gap-2 sm:gap-0 sm:items-center items-end md:justify-around sm:justify-around w-full p-[15px] py-[5px]">
              <div  >
                <NotWarning />

              </div>
              <div className="text-xs sm:text-sm flex flex-col items-center gap-[5px]">
                <p
                  style={viewportWidth < 350 ? { fontSize: '8.5px', fontWeight: '500' } : {}}
                  className="text-[11px] md:text-[14px]"
                >

                  {!orderAcceptanceStatus ? t('product.error_order_acceptance') + '' : t('product.error_order_limit')}

                </p>
              </div>
            </div>

          </div>
          : ''}
        <h2 className="text-white sm:text-[2rem] text-[1.5rem] font-medium select-none mt-[30px] sm:mt-[0px]">
          {getCurrentDayOpenHours()}
        </h2>
        <TextInput
          className="max-w-[550px] min-w-[300px] w-[50vw] hidden"
          icon={<AiOutlineSearch />}
          placeholder="Search in the menu"
        />
        <div className="absolute xxs:w-[88%] sm:w-[90%] bottom-[-60px] w-[80%] max-w-[700px] min-h-[87px] bg-white rounded-[20px] shadow-md flex items-center justify-around flex-col">
          <div className="flex xxs:items-center xxs:justify-center xxs:gap-[95px] sm:gap-0 sm:items-center items-end md:justify-around sm:justify-around w-full p-[15px] py-[5px]">
            <div className="flex flex-col items-center justify-center text-xs gap-[5px]">
              <span
                className="flex items-center gap-[10px]"
                role="button"
                onClick={onReviewsClick}
              >
                <p className="text-[20px] font-medium">
                  {productDetails?.average_rating
                    ? Number(productDetails?.average_rating)?.toFixed(1)
                    : 0}
                </p>
                <div className="xxs:mr-1 sm:mr-0 lg:mr-0">
                  <StarIcon
                    fillColor="#FFB800"
                    color="#FFB800"
                    strokeWidth="2"
                    size="24"
                  />
                </div>
              </span>
              <p
                style={viewportWidth < 350 ? { fontSize: '8.5px' } : {}}
                className="text-[11px] md:text-[14px]"
              >
                (
                {productDetails?.total_ratings
                  ? productDetails?.total_ratings
                  : 0}
                {/* ) Recenzii */}
                ) {t('restaurant.reviews')}
              </p>
            </div>
            <div className="text-xs sm:text-sm flex flex-col items-center gap-[5px]">
              {(validUrl || location?.pickup || location?.delivery) && location?.autoOrders && isOpen ? (
                <span className="text-[1.75rem]" style={{ color: colorCode }}>
                  <IoCheckmark />
                </span>
              ) : (
                <span className="text-[red]  text-[1.75rem]">
                  <AiOutlineCloseCircle />
                </span>
              )}
              <p
                style={viewportWidth < 350 ? { fontSize: '8.5px' } : {}}
                className="text-[11px] md:text-[14px]"
              >
                {/* Comenzi online */}
                {t('restaurant.online_orders')}

              </p>
            </div>
          </div>
          {autoOrder && (
            <div className="flex gap-[10px] justify-center h-[30px]  w-full mb-[10px] mt-[10px]">
              <img
                src="/assets/images/visa.png"
                className="h-[20px] w-[60px] xxs:ml-[20px] sm:ml-0 mt-1"
              />
              <img
                src="/assets/images/icons/mastercard.png"
                className="h-full xxs:mr-[20px] sm:mr-0"
              />
            </div>
          )}
        </div>
        <div
          className="absolute bottom-0 lg:w-[100px] xxs:w-[83.5px] sm:w-[100px] border-[5px] border-solid rounded-[50%]  block"
          style={{ borderColor: colorCode, background: colorCode }}
        >
          <img
            alt="Logo"
            src={location?.image}
            className={`w-full h-[75px] sm:h-[90px] rounded-full ${isJpg ? 'object-cover' : 'object-contain'
              }`}
            onLoad={(e) => {
              const jpg = e.target.src.endsWith('.jpg') || e.target.src.endsWith('.jpeg')
              setIsJpg(jpg)
            }}
          />
        </div>
      </div>
      {location?.help_instructions !== null &&
        location?.help_instructions !== false && (
          <HelpPopup
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            location={location}
          />
        )}
    </div>
  )
}

export default ProductBrand
