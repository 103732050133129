import { FaCircle } from 'react-icons/fa'

const CircleCheckBox = ({
  className,
  onChange,
  checked = false,
  disabled = false,
  colorCode = '#CBCAC8'
}) => {
  let classNames = `flex justify-center items-center cursor-pointer transition duration-200 ease-in-out focus-visible:shadow-lg border-[2px] border-solid border-[#ffb800] rounded-[50%] w-[25px] h-[25px] ${className} ${
      checked ? 'bg-[#FFFAF5]' : 'bg-white'
  }`

  // Additional styling when disabled
  if (disabled) {
    classNames += ' cursor-not-allowed opacity-50'
  }

  const handleChange = () => {
    if (disabled) {
      return
    }

    if (checked) {
      onChange(false)
    } else {
      onChange(true)
    }
  }

  return (
      <div className={classNames} onClick={handleChange} style={{ borderColor: colorCode }}>
        {checked && <FaCircle color={colorCode === '#CBCAC8' ? '#FFB800' : colorCode} style={{ width: '10px', height: '10px' }} />}
      </div>
  )
}

export default CircleCheckBox
