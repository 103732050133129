import { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCreditCard, AiOutlineWallet } from 'react-icons/ai'
import CircleCheckBox from '../../common/CircleCheckBox'
import axios from 'axios';
import { LiaWalletSolid } from 'react-icons/lia'
import './modal.css'
import { data } from 'autoprefixer';
import { MdAccessTime } from "react-icons/md";
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../appContext/GlobalContext';
const PaymentSelector = ({ method, setMethod, colorCode }) => {

  //const [method, setMethod] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const inputsRef = useRef([]); // Reference for all input boxes
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+40');
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill('')); // Array to hold OTP digits
  const [enteredOtp, setEnteredOtp] = useState('');
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [timer, setTimer] = useState(120); // 2 minutes
  const [resendEnabled, setResendEnabled] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(undefined);
  const countryCodes = [
    { code: '+1', country: 'Canada' },
    { code: '+7', country: 'Russia' },
    { code: '+20', country: 'Egypt' },
    { code: '+27', country: 'South Africa' },
    { code: '+31', country: 'Netherlands' },
    { code: '+33', country: 'France' },
    { code: '+34', country: 'Spain' },
    { code: '+36', country: 'Hungary' },
    { code: '+39', country: 'Italy' },
    { code: '+40', country: 'Romania' },
    { code: '+41', country: 'Switzerland' },
    { code: '+43', country: 'Austria' },
    { code: '+44', country: 'United Kingdom' },
    { code: '+45', country: 'Denmark' },
    { code: '+46', country: 'Sweden' },
    { code: '+47', country: 'Norway' },
    { code: '+48', country: 'Poland' },
    { code: '+49', country: 'Germany' },
    { code: '+54', country: 'Argentina' },
    { code: '+55', country: 'Brazil' },
    { code: '+56', country: 'Chile' },
    { code: '+60', country: 'Malaysia' },
    { code: '+61', country: 'Australia' },
    { code: '+62', country: 'Indonesia' },
    { code: '+63', country: 'Philippines' },
    { code: '+64', country: 'New Zealand' },
    { code: '+66', country: 'Thailand' },
    { code: '+67', country: 'Papua New Guinea' },
    { code: '+81', country: 'Japan' },
    { code: '+82', country: 'South Korea' },
    { code: '+86', country: 'China' },
    { code: '+90', country: 'Turkey' },
    { code: '+91', country: 'India' },
    { code: '+92', country: 'Pakistan' },
    { code: '+95', country: 'Myanmar' },
    { code: '+212', country: 'Morocco' },
    { code: '+225', country: 'Ivory Coast' },
    { code: '+237', country: 'Cameroon' },
    { code: '+238', country: 'Cape Verde' },
    { code: '+249', country: 'Sudan' },
    { code: '+254', country: 'Kenya' },
    { code: '+255', country: 'Tanzania' },
    { code: '+256', country: 'Uganda' },
    { code: '+260', country: 'Zambia' },
    { code: '+263', country: 'Zimbabwe' },
    { code: '+267', country: 'Botswana' },
    { code: '+351', country: 'Portugal' },
    { code: '+355', country: 'Albania' },
    { code: '+358', country: 'Finland' },
    { code: '+359', country: 'Bulgaria' },
    { code: '+370', country: 'Lithuania' },
    { code: '+371', country: 'Latvia' },
    { code: '+372', country: 'Estonia' },
    { code: '+373', country: 'Moldova' },
    { code: '+374', country: 'Armenia' },
    { code: '+375', country: 'Belarus' },
    { code: '+386', country: 'Slovenia' },
    { code: '+420', country: 'Czech Republic' },
    { code: '+421', country: 'Slovakia' },
    { code: '+966', country: 'Saudi Arabia' },
    { code: '+971', country: 'United Arab Emirates' },
    { code: '+993', country: 'Turkmenistan' }
  ];
  let tableId = JSON.parse(localStorage.getItem('uuid'));
  const { state } = useLocation();
  const { pay_later_payment } = state || {};
  const { location } = useContext(GlobalContext);


  useEffect(() => {
    let interval;
    if (otpSent && !resendEnabled) {
      interval = setInterval(() => {
        setTimer(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            setResendEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, resendEnabled]);

  useEffect(() => {
    if ('OTPCredential' in window) {
      // alert('OTPCredential API supported!');
  
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          // Remove the signal to avoid cancellation issues
        })
        .then((otp) => {
          console.log("OTP received:", otp);
          // alert(`OTP received: ${otp.code}`);
  
          if (otp) {
            // Assuming OTP is a 6-digit code
            const otpCode = otp.code.split('');
            // otpCode.forEach((digit, index) => {
            //   handleOtpChange({ value: parseInt(digit) }, index,false);
            // });
            const newOtp = otpCode;
            setOtp(newOtp);
            setEnteredOtp(newOtp.join(''));
          }
        })
        .catch((err) => {
          // alert(`Error retrieving OTP: ${err.message}`);
          console.error('Error retrieving OTP:', err);
        });
    } else {
      // alert('OTPCredential API not supported in this browser.');
    }
  }, []);

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text');
    if (/^\d+$/.test(pastedData) && pastedData.length === 6) {
       // Assuming OTP is a 6-digit code
       const otpCode = pastedData.split('');
      //  otpCode.forEach((digit, index) => {
      //    handleOtpChange({ value: parseInt(digit) }, index,false);
      //  });
       const newOtp = otpCode;
       setOtp(newOtp);
       setEnteredOtp(newOtp.join(''));
      e.preventDefault();
    }
  };

  useEffect(() => {
    inputsRef.current.forEach(input => {
      input.addEventListener('paste', handlePaste);
    });

    // Clean up paste event listener
    return () => {
      inputsRef.current.forEach(input => {
        if(input){
          input.removeEventListener('paste', handlePaste);
        }
      });
    };
  }, []);


  useEffect(() => {
    if (inputsRef.current[0]) {
      inputsRef.current[0].focus(); // Set focus on the first input
    }
  }, [showVerificationModal]); // This runs when the modal is shown

  const { t } = useTranslation()


  const handleChangePaymentCash = (checked) => {
    if (!checked) return
    setMethod('cash')
  }
  const handleChangePaymentOnline = (checked) => {
    if (!checked) return
    setMethod('online')

  }

  const handleLogin = () => {
    // Handle the login process (e.g., OTP verification)
    setShowLoginModal(false);
    setShowOtpModal(true);


    setMethod('pay_later');
  };

  const handleCancel = () => {
    // Close the modals and reset the payment method
    setShowLoginModal(false);
    setShowOtpModal(false);
    setShowVerificationModal(false);
    setMethod('online');
    setCountryCode('+40')
    setPhoneNumber('')
  };
  const handleSendOtp = async () => {
    setOtp(new Array(6).fill('')); // Clear the OTP input boxes
    setEnteredOtp(''); // Clear the enteredOtp state

    if (!phoneNumber) {
      setError('Please enter a valid phone number');
      return;
    }

    const fullNumber = `${countryCode}${phoneNumber}`;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/partners/send-otp`, {
        phoneNumber: fullNumber,
      });

      console.log('OTP sent:', response.data.otp); // For testing, remove in production
      setOtpSent(true);
      setResendEnabled(false); // Disable resend button
      setTimer(120); // Reset timer
      setError('');
      setShowVerificationModal(true);

      toast.success(t('paylater.otp_sent'), {
        position: 'bottom-right',
      })
    } catch (error) {
      console.error('Error sending OTP:', error);
      toast.error(t('paylater.otp_error'), {
        position: 'bottom-right',
      })
    }
  };
  const handleOtpChange = (element, index,move=true) => {
    if (isNaN(element.value)) return; // Only allow numbers

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if(move){

      // Automatically move to the next input box
      if (element.nextSibling && element.value) {
        inputsRef.current[index + 1].focus();
      }
    }

    // Set enteredOtp to be the concatenated OTP digits
    setEnteredOtp(newOtp.join(''));
    // toast.success(t('paylater.otp_resend'), {
    //   position: 'bottom-right',
    // })
  };

  // Handle backspace to move to the previous input
  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  // Handle OTP submission
  const handleVerifyOtp = async () => {
    if (!enteredOtp) {
      setError('Please enter the OTP');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/partners/verify-otp`, { phoneNumber: `${phoneNumber}`, otp: enteredOtp,countryCode:`${countryCode}` });
      if (response.status === 200) {

        setShowVerificationModal(false);
        setShowOtpModal(false);
        setCountryCode('+40')
        setPhoneNumber('')
        localStorage.setItem('logged_in_user', JSON.stringify(response.data.user_data[0]))
        toast.success(t('paylater.otp_verified'), {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast.error(t('paylater.incor_otp'), {
        position: 'bottom-right',
      })
    }
  };



  const handleResendOtp = () => {
    // Logic to resend OTP
    console.log('Resending OTP...');
    handleSendOtp()// Clear the OTP boxes
    setResendEnabled(false); // Disable the resend button
    setTimer(120); // Reset the timer to 2 minutes
  };
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? `0${secs}` : secs}`;
  };
  const handleChangePaymentLater = (checked) => {
    if (!checked) return
    setMethod('pay_later')
    setShowLoginModal(true);

  }


  return (
    <div>
      <div className="flex flex-col mt-[10px] px-[5px] ">
        <div className="text-[18px] font-medium">Payment method</div>
        <div
          className={`cursor-pointer relative pt-[20px] pl-2 pb-1 w-full flex items-center justify-between`}
          onClick={() => handleChangePaymentCash(true)}
        >
          <div className="flex gap-[10px]">
            <CircleCheckBox
              colorCode={colorCode}
              checked={method === 'cash'}
              onChange={(checked) => handleChangePaymentCash(checked)}
            />
            <h4 className="font-normal text-[16px]">{t('product.cash')}</h4>
          </div>
          <span className="text-[2rem]">
            <div className="pr-[4px]">
              <img alt="Cash Icon" src="/assets/images/icons/wallet_icon.svg" />
            </div>
          </span>
        </div>


        <div
          className={`cursor-pointer relative py-[20px] pl-2 pb-1 w-full flex items-center justify-between`}
          onClick={() => handleChangePaymentOnline(true)}
        >
          <div className="flex gap-[10px]">
            <CircleCheckBox
              colorCode={colorCode}
              checked={method === 'online'}
              onChange={(checked) => handleChangePaymentOnline(checked)}
            />
            <h4 className="font-normal text-[16px]">{t('product.online')}</h4>
          </div>
          <span className="text-[2rem]">
            <div className="pr-[4px]">
              <img alt="Cash Icon" src="/assets/images/icons/cash_icon.svg" />
            </div>
          </span>
        </div>


    {
      !pay_later_payment && tableId && location.paylater_supported &&(
        <div
          className={`cursor-pointer relative py-[20px] pl-2 w-full flex items-center justify-between`}
          onClick={() => handleChangePaymentLater(true)}
        >
          <div className="flex gap-[10px]">
            <CircleCheckBox
              colorCode={colorCode}
              checked={method === 'pay_later'}
              onChange={(checked) => handleChangePaymentLater(checked)}
            />
            <h4 className="font-normal text-[16px]">{t('product.pay_later')}</h4>
          </div>
          <span className="text-[2rem]">
            <div className="pr-[2px]">
            <MdAccessTime style={{width:"27px", color: "#3C3A34"}}/>

            </div>
          </span>
        </div>
        
      )
    }
      </div>



      {showLoginModal && (
        <div className="modal">
          <div className="modal-content" style={{ height: '254px' }}>
            <h3>
              <img
                alt="System"
                src="/assets/images/info-orange.svg"
                style={{
                  height: '35px',
                  width: '35px'
                }}
              /></h3>
            <p>{t('paylater.login_proceed')}</p>
            <div className='modal-buttons'>
              <button onClick={handleCancel}>{t('paylater.cancel')}</button>
              <button onClick={handleLogin}>{t('paylater.login')}</button>

            </div>
          </div>
        </div>
      )}

      {showOtpModal && (
        <div className="modal">
          <div className="modal-content" style={{ height: '291px' }}>
            <h3 className='heading_mob_num'>{t('paylater.mob_enter_num')}</h3>
            <h3 className='heading_mob_num_1'>{t('paylater.mob_enter_code')}</h3>
            <h3 className='heading_mob_num_2'>{t('paylater.mob_num')}</h3>
            <div className="phone-input-container">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="country-code-dropdown"
              >
                {countryCodes.map((item) => (
                  <option key={item.code} value={item.code}>
                    {item.code}
                  </option>
                ))}
              </select>

              <input
                type="tel"
                placeholder="Enter mobile number"
                value={phoneNumber}
                onChange={(e) => {
                  // Allow only numbers
                  const numericValue = e.target.value.replace(/\D/g, "");
                  setPhoneNumber(numericValue);
                }}
                className="phone-number-input"
              />
            </div>
            <div className='modal-buttons'>

              <button onClick={handleCancel}>Cancel</button>
              <button onClick={handleSendOtp}>Send OTP</button>
            </div>
          </div>
        </div>
      )}


      {/* OTP Verification Modal (Step 3) */}
      {showVerificationModal && (
        <div className="modal">
          <div className="modal-content">
            <h3 className='heading_otp_num'>{t('paylater.otp_verify')}</h3>
            <h3 className='heading_otp_num_1'>{t('paylater.otp_desc')}</h3>
            <h3 className='heading_otp_num_2'>{`${countryCode}${phoneNumber}`}</h3>
            <h3 className='heading_otp_num_3'>{formatTime(timer)}</h3>


           
            {/* OTP input boxes */}
            <div className="otp-input-container">
              {otp.map((digit, index) => (
                <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOtpChange(e.target, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                ref={(el) => (inputsRef.current[index] = el)}
                className="otp-input"
                onPaste={handlePaste}
                inputMode="numeric" // Ensures numeric keyboard
                autocomplete="one-time-code" // Enables OTP autofill prompt in Google Keyboard
                />
              ))}
            </div>

            <div className='resend-otp-cont'>
              <h3>{t('paylater.otp_not_rec')}</h3>
              <button onClick={handleResendOtp}>Resend</button>
            </div>


            <div className='modal-buttons'>
              <button onClick={handleCancel}>Cancel</button>
              <button onClick={handleVerifyOtp}>Verify OTP</button>
            </div>

          </div>
        </div>
      )}



      {/* Display OTP sent message */}
      {/* {otpSent && <p>OTP has been sent to your mobile number.</p>} */}
      {/* <div className="w-[95%] h-[2px] bg-[#cbcac83c] mx-auto"></div> */}
      {/* <div className="flex justify-center items-center gap-[10px] mt-2">
        <img alt="Share Bill" src="/assets/images/icons/people_icon.png" />
        <p>Share the bill</p> */}
      {/* </div> */}
    </div>
  )
}

export default PaymentSelector
