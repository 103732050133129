import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'

export const Terms = () => {
  const { t, i18n } = useTranslation()
  const [termsAndConditions, setTermsAndConditions] = useState('')

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const lang = i18n.language
        const termsData = await import(
          `../../locales/files/terms-and-conditions-${lang}.json`
        )
        const paragraphs = termsData.default.termsAndConditions.split('\n')
        setTermsAndConditions(paragraphs)
      } catch (error) {
        console.error(error)
      }
    }
    fetchTerms()
  }, [i18n.language])

  return (
    <div>
      <Seo name="Terms And Conditions" />
      <HeaderDark />
      <div className="container mt-[100px]">
        <h2 className="text-[40px] font-extrabold mb-[30px]">
          {t('footer.terms')}
        </h2>
        {Array.isArray(termsAndConditions) && (
          <ul className="mt-[15px]">
            {termsAndConditions.map((paragraph, index) => (
              <React.Fragment key={index}>
                {!/^\d+\.\s/.test(paragraph) ? null : (
                  <li className="mt-3">{paragraph}</li>
                )}
              </React.Fragment>
            ))}
          </ul>
        )}
        {Array.isArray(termsAndConditions) &&
          termsAndConditions.map((paragraph, index) => (
            <React.Fragment key={index}>
              {!/^\d+\.\s/.test(paragraph) ? (
                <p className="mt-[30px] text-[#464646]">{paragraph}</p>
              ) : (
                <h2 className="text-[#464646] mt-[30px] text-[24px] font-bold leading-[30px] md:leading-[50px]">
                  {paragraph}
                </h2>
              )}
            </React.Fragment>
          ))}
      </div>
      <Footer />
    </div>
  )
}

export default Terms
