import { Link } from 'react-router-dom'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import { useTranslation } from 'react-i18next'
import RectangleButton from '../../common/RectangleButton'
import { BsArrowRight } from 'react-icons/bs'

const Blog = () => {
  const { t } = useTranslation()
  const articles = [
    {
      title: t('homepage.blog_menu_title'),
      image: '/assets/images/blog01.png',
      link: '/blog/what-is-a-digital-menu',
    },
    {
      title: t('homepage.blog_anpc_title'),
      image: '/assets/images/blog02.png',
      link: '/blog/explicatii-ordin-anpc-2022',
    },
    {
      title: t('homepage.blog_pos_title'),
      image: '/assets/images/blog03.png',
      link: '/blog/what-is-a-pos-system',
    },
    {
      title: t('homepage.blog_qr_title'),
      image: '/assets/images/blog01.png',
      link: '/blog/what-is-a-qr-code',
    },
  ]
  return (
    <div>
      <HeaderDark />
      <div className="mt-[80px] mb-[80px] md:mb-[160px] py-[85px] relative">
        <img
          src="/assets/images/blog-bg.png"
          className="w-full h-full absolute top-0 left-0 object-cover md:object-center"
          alt=""
        />
        <div className="container z-2 relative">
          <h1 className="uppercase md:text-[55px] text-[35px] font-bold font-[Poppins] leading-[50px] md:leading-[70px]">
            {t('blog_page.title')}
            <br />
            {t('blog_page.title2')}
          </h1>
        </div>
      </div>
      <div className="container">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-x-[30px] gap-y-[50px] md:gap-y-[100px]">
          {articles.map((article, index) => (
            <div className="w-full" key={index}>
              <div className="relative w-full flex-shrink-0">
                <div className="w-full h-[260px] rounded-[15px] overflow-hidden">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-full object-cover"
                  />
                </div>

                <div className="bg-white rounded-[10px] py-[25px] px-[20px] mx-[20px] -mt-[50px] relative z-5 shadow-[0_15px_50px_0_rgba(0,0,0,0.09)]">
                  <h4 className="w-full text-left font-bold min-h-[50px] text-[16px] mb-[23px]">
                    {article.title}
                  </h4>
                  <Link
                    to={article.link}
                    className="flex items-center w-full text-left text-[#FFB800]"
                  >
                    {' '}
                    {t('homepage.learn_more')}{' '}
                    <span className="ml-[5px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                      >
                        <path
                          d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                          fill="#FBBE42"
                        />
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {articles.length > 6 && (
          <div className="flex justify-center items-center mt-[40px]">
            <Link to="/blog">
              <RectangleButton
                icon={<BsArrowRight />}
                text={t('homepage.blog_see')}
                className="h-[41px] rounded-lg border-[#46464687] border-solid bg-[#FFF] border-[1px] hover:bg-[#808080] hover:text-white transition duration-200"
                variant="icon"
              />
            </Link>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default Blog
