import React from 'react'
import { useTranslation } from 'react-i18next'
import HeaderDark from '../Landing/HeaderDark'
import Footer from '../Landing/Footer'
import Dropdown from '../../common/Dropdown'
import Seo from '../../components/SEO/Seo'

export const Support = () => {
  const { t } = useTranslation()

  
  return (
    <div>
      <Seo name="Support Si Ajutor" />
      <HeaderDark />
      <div className="container mt-[80px]">
        <div className="flex flex-col md:flex-row mb-9 justify-center gap-10">
          <div className="flex w-full justify-center items-center">
            <p className="text-[36px] lg:text-[50px] font-extrabold mt-12 xxs:w-full sm:w-full lg:w-1/2 uppercase">
              {t('footer.help')}
            </p>
          </div>
          <img
            src="/assets/images/blog_image.png"
            alt={t('footer.help')}
            className="mx-auto md:mx-0 xxs:w-3/4 sm:w-full lg:w-full"
          />
        </div>
        <div className="w-full mt-2">
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Cum pot contacta departamentul de suport Adhoc?"
              content="Te putem ajuta cu o informație? Hai rapid în 'Chat Support', pe website. Îl găsești ușor în secțiunea de Ajutor si Suport  în intervalul orar 08:00-05:00. Un coleg de-al nostru te va prelua în cel mai scurt timp. În zilele de sărbători legale, e posibil să facem mici modificări în program, dar revenim c-un răspuns fulger cât se poate de repede."
              iconColor="#000000"
              className="w-full"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="De ce este nevoie de adresa mea de email?"
              content="Avem nevoie adresa ta de email pentru că ne dorim să-ți transmitem rapid informații cu privire la comanda ta."
              iconColor="#000000"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Când pot să folosesc Adhoc?"
              content="Pofte urgente sau esti pe fuga? Suntem aici pentru ambele, iar partenerii noștri au un program variat. Daca vezi codul QR specific Adhoc, inseamna ca platforma este disponibila la locatia respectiva."
              iconColor="#000000"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Cum plasez o comandă?"
              content="Ți-am răspunde instant - ce-ți dictează pofta! ? Dar, în funcție de orașul"
              iconColor="#000000"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Care sunt metodele de plată acceptate?"
              content="Adhoc îți pune la dispoziție următoarele metode de plată: numerar, card bancar. Recomandăm mereu livrarea non-contact și plata cu cardul, pentru mai multă siguranță."
              iconColor="#000000"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Cum modific metoda de plată?"
              content="Alege noua opțiune din secțiunea 'Metode de plată', înainte de plasarea comenzii."
              iconColor="#000000"
            />
          </div>
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Cum identific produsele dorite?"
              content={
                <div>
                  <p>
                    Ai 2 variante disponibile, alege-o tu pe ce mai accesibilă
                    pentru tine:
                  </p>
                  <ul>
                    <li>
                      &nbsp; • Cauți orice îți trece prin cap și vrei să-ți
                      aducem în secțiunea de căutare din partea de sus a paginii
                    </li>
                    <li>
                      &nbsp; • Acasă Explorezi categoriile disponibile în
                      aplicație
                    </li>
                  </ul>
                </div>
              }
              iconColor="#000000"
            />
          </div>
          {/* <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Unde adaug codul de voucher?"
              content="Your clients don’t need to install or do anything special. Just scan & browse"
              iconColor="#000000"
            />
          </div> */}
          <div className="help-item flex flex-col border-[1px] bg-[#FFF] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] font-extrabold rounded-[10px] py-[22px] px-[32px] mb-[10px] w-full">
            <Dropdown
              title="Cum iau legatura cu Partenerul? "
              content="Comunicarea cu Parteneraul/Locatia unde regasesti meniurile noastre se face direct la locatia Partenerului sau prin mijlocacele puse la dispozitie de acesta"
              iconColor="#000000"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Support
