import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import RectangleButton from '../../common/RectangleButton'
import { BsArrowRight } from 'react-icons/bs'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Blog = ({ id }) => {
  const { t } = useTranslation()
  return (
    <div className="relative text-center pt-[90px]" id={id}>
      <div className="container">
        <h2 className="uppercase md:text-[55px] text-[40px] font-bold font-[Poppins] leading-[70px] not-italic">
          {t('homepage.blog_title')}
        </h2>
        <div className="flex flex-col md:flex-row mt-[60px] w-full justify-center gap-[50px] md:gap-[30px] mb-[30px]">
          <div className="w-full md:w-1/3">
            <div className="relative w-full flex-shrink-0">
              <div className="w-full h-[260px] rounded-[15px] overflow-hidden">
                <LazyLoadImage
                  src="/assets/images/blog01.png"
                  alt={t('homepage.blog_menu_title')}
                  className="w-full h-full object-cover"
                  effect="opacity"
                />
              </div>
              <div className="bg-white rounded-[10px] py-[25px] px-[20px] mx-[20px] -mt-[50px] relative z-5 shadow-[0_15px_50px_0_rgba(0,0,0,0.09)]">
                <h4 className="w-full text-left font-bold min-h-[50px] text-[16px] mb-[23px]">
                  {t('homepage.blog_menu_title')}
                </h4>
                <Link
                  to="/blog/what-is-a-digital-menu"
                  className="flex items-center w-full text-left text-[#FFB800]"
                >
                  {t('homepage.learn_more')}
                  <span className="ml-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                        fill="#FBBE42"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <div className="relative w-full flex-shrink-0">
              <div className="w-full h-[260px] rounded-[15px] overflow-hidden">
                <LazyLoadImage
                  src="/assets/images/blog02.png"
                  alt={t('homepage.blog_anpc_title')}
                  className="w-full h-full object-cover"
                  effect="opacity"
                />
              </div>
              <div className="bg-white rounded-[10px] py-[25px] px-[20px] mx-[20px] -mt-[50px] relative z-5 shadow-[0_15px_50px_0_rgba(0,0,0,0.09)]">
                <h4 className="w-full text-left font-bold min-h-[50px] text-[16px] mb-[23px]">
                  {t('homepage.blog_anpc_title')}
                </h4>
                <Link
                  to="/blog/explicatii-ordin-anpc-2022"
                  className="flex items-center w-full text-left text-[#FFB800]"
                >
                  {t('homepage.learn_more')}
                  <span className="ml-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                        fill="#FBBE42"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <div className="relative w-full flex-shrink-0 mb-[50px]">
              <div className="w-full h-[260px] rounded-[15px] overflow-hidden">
                <LazyLoadImage
                  src="/assets/images/blog02.png"
                  alt={t('homepage.blog_pos_title')}
                  className="w-full h-full object-cover"
                  effect="opacity"
                />
              </div>
              <div className="bg-white rounded-[10px] py-[25px] px-[20px] mx-[20px] -mt-[50px] relative z-5 shadow-[0_15px_50px_0_rgba(0,0,0,0.09)]">
                <h4 className="w-full text-left font-bold min-h-[50px] text-[16px] mb-[23px]">
                  {t('homepage.blog_pos_title')}
                </h4>
                <Link
                  to="/blog/what-is-a-pos-system"
                  className="flex items-center w-full text-left text-[#FFB800]"
                >
                  {t('homepage.learn_more')}
                  <span className="ml-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                    >
                      <path
                        d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                        fill="#FBBE42"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Link to="/blog">
            <RectangleButton
              icon={<BsArrowRight />}
              text={t('homepage.blog_see')}
              className="h-[41px] rounded-lg border-[#46464687] border-solid bg-[#FFF] border-[1px] hover:bg-[#808080] hover:text-white transition duration-200"
              variant="icon"
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Blog
