import { useEffect } from 'react'
import ReactModal from 'react-modal'
import { CloseIcon } from '../icon'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px 9px',
    maxWidth: '345px',
    borderRadius: '18px',
    width: '100%',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.20)',
    padding: '22px',
  },
}

export const Modal = ({ isOpen, onClose, children, title }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [isOpen])

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="relative">
        <h2 className="flex-1 w-full text-center pr-1 font-semibold text-xl">
          Părerea ta contează
        </h2>
        <button className="absolute right-0 top-0" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="pt-4">{children}</div>
    </ReactModal>
  )
}

ReactModal.setAppElement('#root')
