import React from 'react'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'
import {useTranslation} from "react-i18next";

const QRArticle = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Seo
        name="De ce coduri QR? 
Revoluția meniurilor digitale"
      />
      <HeaderDark />
      <div className="container">
        <div className="article-page">
          <h1>
            {t('QRArticle.heading1part1')} <br /> {t('QRArticle.heading1part2')}
          </h1>
          <img
            src="/assets/images/qr-article-img.png"
            alt="De ce coduri QR? 
            Revoluția meniurilor digitale"
          />

          <h2>{t('QRArticle.heading2')}</h2>
          <p>
            {t('QRArticle.para1')}
          </p>
          <p>
            {t('QRArticle.para2')}
          </p>
          <h2>{t('QRArticle.heading3')}</h2>
          <p>
            {t('QRArticle.para3')}
          </p>
          <p>
            {t('QRArticle.para4')}
          </p>
          <p>
            {t('QRArticle.para5')}
          </p>
          <p>
            {t('QRArticle.para6')}
          </p>
          <h2>{t('QRArticle.heading4')}</h2>
          <p>
            {t('QRArticle.para7')}
          </p>
          <p>
            <b>{t('QRArticle.para8bold')}</b>
            {t('QRArticle.para8')}
          </p>
          <p>
            <b>{t('QRArticle.para9bold')}</b>
            {t('QRArticle.para9')}
          </p>
          <p>
            <b>{t('QRArticle.para10bold')}</b>
            {t('QRArticle.para10')}
          </p>
          <p>
            <b>{t('QRArticle.para11bold')}</b>
            {t('QRArticle.para11')}
          </p>
          <p>
            <b>{t('QRArticle.para12bold')}</b>
            {t('QRArticle.para12')}
          </p>
          <h2>{t('QRArticle.heading5')}</h2>
          <p>
            {t('QRArticle.para13')}
          </p>
          <p>
            <b>{t('QRArticle.para14bold')}</b>
            {t('QRArticle.para14')}
          </p>
          <p>
            <b>{t('QRArticle.para15bold')}</b>
            {t('QRArticle.para15')}
          </p>
          <p>
            <b>{t('QRArticle.para16bold')}</b>
            {t('QRArticle.para16')}
          </p>
          <p>
            <b>{t('QRArticle.para17bold')}</b>
            {t('QRArticle.para17')}
          </p>
          <h2>{t('QRArticle.heading6')}</h2>
          <p>
            {t('QRArticle.para18')}
          </p>
          <p>
            <b>{t('QRArticle.para19bold')}</b>
            {t('QRArticle.para19')}
          </p>
          <p>
            <b>{t('QRArticle.para20bold')}</b>
            <p>
              {t('QRArticle.para20')}
            </p>
          </p>
          <p>
            <b>{t('QRArticle.para21bold')}</b>
            {t('QRArticle.para21')}
          </p>
          <p>
            <b>{t('QRArticle.para22bold')}</b>
            {t('QRArticle.para22')}
          </p>
          <h2>{t('QRArticle.heading7')}</h2>
          <p>
            {t('QRArticle.para23')}
          </p>
          <p>
            {t('QRArticle.para24')}
          </p>
          <p>
            {t('QRArticle.para25')}
          </p>
          <h2>{t('QRArticle.heading8')}</h2>
          <p>
            {t('QRArticle.para26')}
          </p>
          <p>
            {t('QRArticle.para27')}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default QRArticle
