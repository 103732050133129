import { FaCheck } from 'react-icons/fa'

const CheckBoxProductDialog = ({ className, onChange, checked = false, disabled = false, colorCode = '#FFB800' }) => {
    let classNames = `cursor-pointer transition duration-200 ease-in-out focus-visible:shadow-lg border-[2px] border-solid text-[${colorCode}] rounded-lg w-[25px] h-[25px] flex justify-center items-center ${className} ${
        checked ? 'bg-[#FFFAF5]' : 'bg-[#FFF]'
    }`

    if (disabled) {
        classNames += ' cursor-not-allowed opacity-50'
    }

    const handleChange = () => {
        if (disabled) {
            return
        }
        onChange(!checked)
    }

    return (
        <div className={classNames} onClick={handleChange} style={{ borderColor: colorCode }}>
            {checked && <FaCheck color={colorCode} />}
        </div>
    )
}

export default CheckBoxProductDialog
