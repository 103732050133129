import React from 'react'
import { CloseIcon } from '../icon'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'

const PopUpHamburgMenu = ({desc, loading, colorCode, icon, handleClosePopUp = () => {}, onConfirmClearTable = () => {}}) => {
  const { t } = useTranslation()
  return (
    <div className='fixed flex flex-col justify-center items-center z-50 top-0 right-0 w-full h-full bg-[#00000091] transition-all '>
        <div className='bg-white border rounded h-auto w-[90%] px-2 py-2  inset-0 transition-opacity duration-300'>
            {loading ? 
                <div className='px-[115px] h-[164px] py-2 items-center flex'>
                    <ReactLoading  type="spinningBubbles" color={colorCode} />
                </div>
              :
                <>
                <div className="w-full flex justify-end items-center">
                    <div onClick={() => handleClosePopUp(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <div className='flex flex-col  items-center px-16 space-y-3 '>
                    <img
                    alt="clear-table"
                    src={icon}
                    width={40}
                    />
                    <div className='text-base font-light'>
                        {desc}
                    </div>
                </div>
                <div className='flex justify-end w-full mt-10 space-x-8 px-8'>
                    <button onClick={() => handleClosePopUp(false)}>{t('header.cancel')}</button>
                    <button onClick={onConfirmClearTable}>{t('header.yes')}</button>
                </div>
                </>

              
            }
        </div>
    </div>
  )
}

export default PopUpHamburgMenu