import React from 'react'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'
import { useTranslation } from 'react-i18next'

const DigitalMenu = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Seo name="Digital Menu" />
      <HeaderDark />
      <div className="container">
        <div className="article-page">
          <h1>{t('DigitalMenu.title')}</h1>
          <img
            src="/assets/images/digital-menu-article.png"
            alt="Ce este un meniu digital?"
          />

          <p>{t('DigitalMenu.para1')}</p>
          <p>{t('DigitalMenu.para2')}</p>
          <p>{t('DigitalMenu.para3')}</p>
          <p>{t('DigitalMenu.para4')}</p>
          <p>
            <b>{t('DigitalMenu.heading1')}</b>
          </p>
          <p>{t('DigitalMenu.subheading1')}</p>
          <p>
            <b>{t('DigitalMenu.heading2')}</b>
          </p>
          <p>{t('DigitalMenu.subheading2')}</p>
          <ol>
            <li>{t('DigitalMenu.subheading2_point1')}</li>
            <li>{t('DigitalMenu.subheading2_point2')}</li>
            <li>{t('DigitalMenu.subheading2_point3')}</li>
            <li>{t('DigitalMenu.subheading2_point4')}</li>
            <li>{t('DigitalMenu.subheading2_point5')}</li>
            <li> {t('DigitalMenu.subheading2_point6')}</li>
            <li>{t('DigitalMenu.subheading2_point7')}</li>
            <li> {t('DigitalMenu.subheading2_point8')}</li>
            <li> {t('DigitalMenu.subheading2_point9')}</li>
          </ol>
          <p>{t('DigitalMenu.subheading2para1')}</p>
          <p>{t('DigitalMenu.subheading2para2')}</p>
          <p>{t('DigitalMenu.subheading2para3')}</p>
          <p>
            <b>{t('DigitalMenu.subheading3')}</b>
          </p>
          <p>{t('DigitalMenu.subheading3para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading4')}</b>
          </p>
          <p>{t('DigitalMenu.subheading4para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading5')}</b>
          </p>
          <p>{t('DigitalMenu.subheading5para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading6')}</b>
          </p>
          <p>{t('DigitalMenu.subheading6para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading7')}</b>
          </p>
          <p>{t('DigitalMenu.subheading7para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading8')}</b>
          </p>
          <p>{t('DigitalMenu.subheading8para')}</p>
          <p>
            <b>{t('DigitalMenu.subheading9')}</b>
          </p>
          <p>{t('DigitalMenu.subheading9para')}</p>
          <p>
            <b>
              <i>{t('DigitalMenu.subheading10')}</i>
            </b>
          </p>
          <p>{t('DigitalMenu.subheading10para')}</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DigitalMenu
