import React from 'react'

export const NotWarning = ({width=22,height=22}) => {
  return (
    <svg width={width} height= {height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#130D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 16H12.01" stroke="#130D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 8V12" stroke="#130D00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
