import React from 'react'
import RectangleButton from '../../common/RectangleButton'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

const MobileOnlyBlock = ({ children }) => {
  const isDesktop = window.innerWidth > 1024
  const { t } = useTranslation()
  const url = window.location.href
  // console.log({ url })
  if (isDesktop) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-white overflow-auto flex">
        <div className="mx-auto max-w-[330px] flex flex-col items-center justify-center text-center">
          <h3 className="text-[19px] font-medium mb-[50px]">
            {t('mobileOnlyBlock.title')}
          </h3>
          <div className="w-[130px] h-[130px] mb-[12px]">
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${url}`}
              viewBox={`0 0 256 256`}
            />
          </div>
          <h2 className="text-[25px] font-bold mb-[6px] uppercase">
            {t('mobileOnlyBlock.scan_now')}
          </h2>
          <p className="text-[12px] leading-[17px] text-[#464646] mb-[13px]">
            {t('mobileOnlyBlock.text')}
          </p>
        </div>
      </div>
    )
  }
  return children
}

export default MobileOnlyBlock
