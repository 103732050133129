import React from 'react'
import Footer from '../Landing/Footer'
import HeaderDark from '../Landing/HeaderDark'
import Seo from '../../components/SEO/Seo'
import {useTranslation} from "react-i18next";

const AnpcArticle = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Seo name="Explicatie ordin ANPC 201 din 2022" />
      <HeaderDark />
      <div className="container">
        <div className="article-page">
          <h1>Explicatie ordin ANPC 201 din 2022</h1>
          <img
            src="/assets/images/anpc-article-img.png"
            alt="Explicatie ordin ANPC 201 din 2022"
            className="article-logo"
          />
          <p>
            {t('AnpcArticle.para1')}
          </p>
          <p>
            {t('AnpcArticle.para2')}
          </p>
          <p>
            {t('AnpcArticle.para3')}
          </p>
          <p>
            {t('AnpcArticle.para4')}
          </p>
          <p>
            <b>
              {t('AnpcArticle.para5bold')}
            </b>
            {t('AnpcArticle.para5')}
          </p>
          <p>
            {t('AnpcArticle.para6')}
          </p>
          <p>
            <b>
              {t('AnpcArticle.para7bold')}
            </b>
            {t('AnpcArticle.para7')}
          </p>
          <p>
            <b>
              {t('AnpcArticle.para8bold')}
            </b>
            {t('AnpcArticle.para8')}
          </p>
          <p>
            <b>
              {t('AnpcArticle.para9bold')}
            </b>
            {t('AnpcArticle.para9')}
          </p>
          <p>
            {t('AnpcArticle.para10')}
          </p>
          <p>
            <b>{t('AnpcArticle.para11bold')}</b>
            {t('AnpcArticle.para11')}
          </p>
          <p>
            {t('AnpcArticle.para12')}
          </p>
          <p>
            {t('AnpcArticle.para13')}
          </p>
          <p>
            <b>{t('AnpcArticle.para14bold')}</b>
            {t('AnpcArticle.para14')}
          </p>
          <p>
            <b>{t('AnpcArticle.para15bold')}</b>
            {t('AnpcArticle.para15')}
          </p>
          <p>
            <b>{t('AnpcArticle.para16bold')}</b>
            {t('AnpcArticle.para16')}
          </p>
          <p>
            <b>{t('AnpcArticle.para17bold')}</b>
            {t('AnpcArticle.para17')}
          </p>
          <p>
            <b>{t('AnpcArticle.para18bold')}</b>
            {t('AnpcArticle.para18')}
          </p>
          <p>
            <b>{t('AnpcArticle.para19bold')}</b>
            {t('AnpcArticle.para19')}
          </p>
          <p>
            <b>{t('AnpcArticle.para20bold')}</b>
            {t('AnpcArticle.para20')}
          </p>
          <p>
            <b>{t('AnpcArticle.para21bold')}</b>
            {t('AnpcArticle.para21')}
          </p>
          <p>
            <b>{t('AnpcArticle.para22bold')}</b>
            {t('AnpcArticle.para22')}
          </p>
          <p>
            {t('AnpcArticle.para23')}
          </p>
          <p>
            {t('AnpcArticle.para24')}
          </p>
          <p>
            <b>{t('AnpcArticle.para25bold')}</b>
            {t('AnpcArticle.para25')}
          </p>
          <p>
            <b>{t('AnpcArticle.para26bold')}</b>
            {t('AnpcArticle.para26')}
          </p>
          <p>
            <b>{t('AnpcArticle.para27bold')}</b>
            {t('AnpcArticle.para27')}
          </p>
          <p>
            <b>
              {t('AnpcArticle.para28bold')}
            </b>
            {t('AnpcArticle.para28')}
          </p>
          <h2>{t('AnpcArticle.subheading1')}</h2>
          <p>
            {t('AnpcArticle.para29')}
          </p>
          <p>
            <b>{t('AnpcArticle.para30bold')}</b>
            {t('AnpcArticle.para30')}
          </p>
          <p>
            {t('AnpcArticle.para31')}
          </p>
          <p>
            {t('AnpcArticle.para32')}
          </p>
          <p>
            {t('AnpcArticle.para33')}
          </p>
          <p>
            {t('AnpcArticle.para34')}
          </p>
          <p>
            {t('AnpcArticle.para35')}
          </p>
          <h2>{t('AnpcArticle.subheading2')}</h2>
          <p>
            {t('AnpcArticle.para36')}
          </p>
          <p>
            {t('AnpcArticle.para37')}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AnpcArticle
