// src/IntervalCheck.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const IntervalCheck = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('checking if all fine');
            try {
            let uuid = JSON.parse(localStorage.getItem('uuid'))
                if(uuid === 'null' || uuid === undefined || uuid === "undefined"){
                    navigate('/wentWrong');
                }
            } catch (error) {
                navigate('/wentWrong');
                
            }
        }, 500);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [navigate]);

    return null; // This component doesn't render anything
};

export default IntervalCheck;
