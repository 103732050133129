import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const About = ({ id }) => {
  const { t } = useTranslation()
  return (
    <div id={id} className="relative">
      <div className="container flex items-center flex-col-reverse md:flex-row justify-center gap-[50px] w-full p-2 overflow-x-clip mt-[50px]">
        <div className="max-w-[350px] md:max-w-none md:w-1/2 h-full">
          <div className="h-full">
            <LazyLoadImage
              src="/assets/images/about.png"
              alt="about"
              effect="opacity"
            />
          </div>
        </div>
        <div className="md:w-1/2 w-full flex md:justify-start justify-center">
          <div className="xxs:mt-5">
            <h2 className="uppercase xxs:-mt-6 xxs:text-[35px] md:text-[60px] text-[40px] font-bold text-center md:text-left mb-[30px]">
              {t('homepage.about_title')}
            </h2>
            <div className="flex gap-[20px] xxs:mt-6 sm:mt-0">
              <div className="p-[12px] lg:p-[23px] bg-primary rounded-md w-[95px] h-[95px] xxs:w-[53px] xxs:h-[53px] lg:w-[95px] lg:h-[95px]">
                <LazyLoadImage
                  className="w-full h-full"
                  src="/assets/images/target.png"
                  alt={t('homepage.about_mission')}
                  effect="opacity"
                />
              </div>
              <div className="flex-1">
                <h4 className="text-[#000] font-[Poppins] text-xl not-italic font-semibold leading-[27px]">
                  {t('homepage.about_mission')}
                </h4>
                <p className="max-w-[385px] text-[#464646] font-[Poppins] text-sm not-italic font-normal leading-[27px] xxs:mt-1 sm:mt-0">
                  {t('homepage.about_mission_text')}
                </p>
              </div>
            </div>
            <div className="flex gap-[20px] mt-[20px] xxs:mt-6">
              <div className="p-[12px] lg:p-[23px] bg-primary rounded-md xxs:w-[53px] xxs:h-[53px] lg:w-[95px] lg:h-[95px]">
                <LazyLoadImage
                  className="w-full h-full"
                  src="/assets/images/vision.png"
                  alt={t('homepage.about_vision')}
                  effect="opacity"
                />
              </div>
              <div className="flex-1">
                <h4 className="text-[#000] font-[Poppins] text-xl not-italic font-semibold leading-[27px]">
                  {t('homepage.about_vision')}
                </h4>
                <p className="max-w-[385px] text-[#464646] font-[Poppins] text-sm not-italic font-normal leading-[27px] xxs:mt-1">
                  {t('homepage.about_vision_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute -top-[90px] md:top-[auto] md:-bottom-[350px] right-0 landing-bg">
        <LazyLoadImage
          className="xxs:hidden xs:block sm:block h-[250px] md:h-[540px]"
          src="/assets/images/about_extra.png"
          alt=""
          effect="opacity"
        />
        <LazyLoadImage
          className="xs:hidden sm:hidden md:hidden lg:hidden xl:hidden h-full"
          src="/assets/images/about_extra_small.png"
          alt=""
          effect="opacity"
        />
      </div>
    </div>
  )
}

export default About
