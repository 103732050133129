import { RxCross2 } from 'react-icons/rx'
import allergenContent from '../../utils/allergenContent'
import allergenImages from '../../utils/allergenImages'

const AllergensPopup = ({
  setIsVisible,
  isVisible,
  allergens,
  currentProduct,
}) => {
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full overflow-auto transition-all ${
        isVisible
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      }`}
      onClick={() => setIsVisible(false)}
    >
      <div
        className="absolute sm:w-[630px] w-[90%] top-[50%] left-[50%] bg-white shadow-lg border flex flex-col rounded-[10px] px-[16px] py-[20px] "
        onClick={(e) => e.stopPropagation()}
        style={{ transform: 'translate(-50%,-35%)', zIndex: '999' }}
      >
        <div className="text-[18px] font-medium w-[90%]">
          Alergeni conținuți de produsul "{currentProduct?.Name}"{' '}
          <button
            className="absolute cursor-pointer sm:hidden top-[15px] right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
            onClick={() => setIsVisible(false)}
          >
            <RxCross2 style={{ color: '#000000' }} />
          </button>
        </div>
        <div
          className={`${
            allergens && allergens.length > 0
              ? 'flex flex-col mt-5 mb-2 gap-3 flex-wrap'
              : ''
          }`}
        >
          {allergens?.map((allergen, index) => (
            <div className="flex items-center gap-2" key={index}>
              <img
                src={allergenImages[allergen]}
                alt={allergen}
                key={allergen}
                className="w-[18px] h-[18px]"
              />
              <div>{allergenContent[allergen]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AllergensPopup
