import { useNavigate } from 'react-router-dom'
import { Button } from '../../common'
import {useTranslation} from "react-i18next";
import { useContext } from 'react'
import { GlobalContext } from '../../appContext/GlobalContext'

const PaymentFailedIcon = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon">
        <path
          id="Vector"
          d="M23.4679 20.9993L30.9929 13.4918C31.3224 13.1623 31.5075 12.7154 31.5075 12.2493C31.5075 11.7833 31.3224 11.3364 30.9929 11.0068C30.6633 10.6773 30.2164 10.4922 29.7504 10.4922C29.2844 10.4922 28.8374 10.6773 28.5079 11.0068L21.0004 18.5318L13.4929 11.0068C13.1634 10.6773 12.7164 10.4922 12.2504 10.4922C11.7844 10.4922 11.3374 10.6773 11.0079 11.0068C10.6783 11.3364 10.4932 11.7833 10.4932 12.2493C10.4932 12.7154 10.6783 13.1623 11.0079 13.4918L18.5329 20.9993L11.0079 28.5068C10.8439 28.6695 10.7137 28.8631 10.6248 29.0763C10.536 29.2896 10.4902 29.5183 10.4902 29.7493C10.4902 29.9804 10.536 30.2091 10.6248 30.4224C10.7137 30.6356 10.8439 30.8292 11.0079 30.9918C11.1706 31.1559 11.3641 31.2861 11.5774 31.3749C11.7906 31.4638 12.0194 31.5095 12.2504 31.5095C12.4814 31.5095 12.7101 31.4638 12.9234 31.3749C13.1366 31.2861 13.3302 31.1559 13.4929 30.9918L21.0004 23.4668L28.5079 30.9918C28.6706 31.1559 28.8641 31.2861 29.0774 31.3749C29.2906 31.4638 29.5194 31.5095 29.7504 31.5095C29.9814 31.5095 30.2101 31.4638 30.4234 31.3749C30.6366 31.2861 30.8302 31.1559 30.9929 30.9918C31.1569 30.8292 31.2871 30.6356 31.3759 30.4224C31.4648 30.2091 31.5105 29.9804 31.5105 29.7493C31.5105 29.5183 31.4648 29.2896 31.3759 29.0763C31.2871 28.8631 31.1569 28.6695 30.9929 28.5068L23.4679 20.9993Z"
          fill="#875454"
        />
      </g>
    </svg>
  )
}

const PaymentFailed = () => {
  const navigate = useNavigate()
  const { location } = useContext(GlobalContext)
  const colorCode = JSON.parse(window.localStorage.getItem('colorCode'))
  const { t } = useTranslation()
  return (
    <div className="flex flex-col justify-center items-center p-[10px]">
      <div className="mt-[10%] flex justify-center items-center w-[50px] h-[50px] rounded-full bg-[#F5D8D8]">
        <PaymentFailedIcon />
      </div>
      <h1 className="font-semibold text-[20px] mt-[10px]">
          {t('PaymentFailed.heading1')}
      </h1>
      <p className="text-[16px] font-normal text-center">
          {t('PaymentFailed.para1')}
      </p>
      <div className="mt-[50%] flex justify-center items-center flex-col space-x-4 w-full gap-2">
        <Button
          variant="outline"
          colorCode={colorCode}
          text="Reîncearcă"
          className="font-medium w-full"
          // onClick={() => navigate(-1)}
          onClick={() => navigate(-1, {
            state: {
              location: `${location?.LocationId}`
            }
          })}
        />
        <Button
          text="Contactează-ne"
          className="font-medium"
          style={{ backgroundColor: '#FFF', borderColor: '#FFF' }}
          onClick={() => navigate('/help-and-support')}
        />
      </div>
    </div>
  )
}

export default PaymentFailed
