import { LazyLoadImage } from 'react-lazy-load-image-component'
import Dropdown from '../../common/Dropdown'
import { useTranslation } from 'react-i18next'

const ProductFeatures = () => {
  const { t } = useTranslation()
  return (
    <div
      className="relative min-h-[700px] xl:min-h-[1050px] pt-[100px] pb-[200px] xl:pt-[130px] xl:pb-[130px] overflow-hidden"
      id="product-features"
    >
      <LazyLoadImage
        src="/assets/images/landing_menu_bg.png"
        className="w-full absolute min-w-[1600px] h-full top-0 -left-[850px] md:left-0"
        alt=""
        effect="opacity"
      />
      <div className="container relative z-5 flex gap-[50px] w-full h-[fit-content] text-white flex-col md:flex-row items-center justify-between">
        <div className="md:flex-grow md:flex-shrink md:w-1/2 min-w-[270px]">
          <h2 className="max-w-[250px] md:max-w-none uppercase text-[30px] leading-[1.3em] md:text-[55px] font-bold leading-[60px] font-[Poppins]">
            {t('homepage.landing_menu_title')}
          </h2>
          <div className="flex items-center pt-[25px] md:pt-[46px]">
            <div className="mr-[15px] md:mr-[30px]">
              <div className="bg-primary rounded-full w-[50px] h-[50px] p-[12px] md:w-[66px] md:h-[66px] md:p-4">
                <LazyLoadImage
                  src="/assets/images/loadingMenu1.png"
                  alt={t('homepage.landing_menu_card1_title')}
                  effect="opacity"
                />
              </div>
            </div>
            <Dropdown
              title={t('homepage.landing_menu_card1_title')}
              content={t('homepage.landing_menu_card1_text')}
            />
          </div>
          <div className="flex items-center pt-[25px] md:pt-[33px]">
            <div className="mr-[15px] md:mr-[30px]">
              <div className="bg-primary rounded-full w-[50px] h-[50px] p-[12px] md:w-[66px] md:h-[66px] md:p-4">
                <LazyLoadImage
                  src="/assets/images/loadingMenu2.png"
                  alt={t('homepage.landing_menu_card2_title')}
                  effect="opacity"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <Dropdown
                title={t('homepage.landing_menu_card2_title')}
                content={t('homepage.landing_menu_card2_text')}
              />
            </div>
          </div>
          <div className="flex items-center pt-[25px] md:pt-[33px]">
            <div className="mr-[15px] md:mr-[30px]">
              <div className="bg-primary rounded-full w-[50px] h-[50px] p-[12px] md:w-[66px] md:h-[66px] md:p-4">
                <LazyLoadImage
                  src="/assets/images/loadingMenu3.png"
                  alt={t('homepage.landing_menu_card3_title')}
                  effect="opacity"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <Dropdown
                title={t('homepage.landing_menu_card3_title')}
                content={t('homepage.landing_menu_card3_text')}
              />
            </div>
          </div>
          <div className="flex items-center pt-[25px] md:pt-[33px]">
            <div className="mr-[15px] md:mr-[30px]">
              <div className="bg-primary rounded-full w-[50px] h-[50px] p-[12px] md:w-[66px] md:h-[66px] md:p-4">
                <LazyLoadImage
                  src="/assets/images/loadingMenu4.png"
                  alt={t('homepage.landing_menu_card4_title')}
                  effect="opacity"
                />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <Dropdown
                title={t('homepage.landing_menu_card4_title')}
                content={t('homepage.landing_menu_card4_text')}
              />
            </div>
          </div>
        </div>
        <div className="max-w-[300px] md:max-w-none md:flex-grow md:flex-shrink md:w-1/2">
          <LazyLoadImage
            src="assets/images/Group 202.png"
            alt=""
            effect="opacity"
          />
        </div>
      </div>
    </div>
  )
}

export default ProductFeatures
