import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './global.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import IntervalCheck from './IntervalCheck'

const supportedLanguages = ['ro', 'en', 'hu']
const fallbackLanguage = 'ro'

const customLanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    let userLang =
      localStorage.getItem('i18nextLng') ||
      navigator.language ||
      navigator.userLanguage
    userLang = userLang.split('-')[0]

    if (supportedLanguages.includes(userLang)) {
      // callback(userLang)
      callback(fallbackLanguage)
    } else {
      callback(fallbackLanguage)
    }
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem('i18nextLng', lng)
  },
}

i18next
  .use(customLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ro: { translation: require('./locales/ro.json') },
      en: { translation: require('./locales/en.json') },
      hu: { translation: require('./locales/hu.json') },
    },
    fallbackLng: fallbackLanguage,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

try {
  const savedExpirationDateString = window.localStorage.getItem('expirationDate')
  const now = (new Date()).getTime()
  let expirationDate = now
  if (
    typeof savedExpirationDateString === 'string'
  ) {
    expirationDate = JSON.parse(savedExpirationDateString)
    if (expirationDate < now) {
      window.localStorage.removeItem('productId')
      window.localStorage.removeItem('uuid')
      window.localStorage.removeItem('colorCode')
      window.localStorage.removeItem('busket')
      window.localStorage.removeItem('tips')
      window.localStorage.removeItem('expirationDate')
      window.localStorage.removeItem('thankyou_code')
    }
  } else {
    expirationDate += 60 * 60 * 1000  // 1 hour
    window.localStorage.setItem('expirationDate', JSON.stringify(expirationDate))
  }
} catch (e) {
  console.log(e)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Router>
      <IntervalCheck />
      <App />
    </Router>
  </QueryClientProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
