import { useTranslation } from 'react-i18next'
import RectangleButton from '../../common/RectangleButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MadeMind = ({ handleClick }) => {
  const { t } = useTranslation()
  return (
    <div className="relative bg-[#3D3D3D] pt-[52px] pb-[40px] md:pt-[120px] md:pb-[85px]">
      <LazyLoadImage
        src="/assets/images/Group 239.png"
        className="w-full h-full absolute top-0 left-0 object-cover md:object-center"
        alt=""
        effect="opacity"
      />
      <div className="container relative z-5 w-full h-full text-black font-[Poppins]">
        <div className="flex flex-col items-start gap-[20px] md:gap-[70px]">
          <h3 className="md:text-[55px] text-[30px] font-bold md:leading-[80px] leading-[40px] uppercase">
            {t('homepage.mademind_title')}
          </h3>
          <RectangleButton
            className="px-[60px] py-[15px]"
            size="large"
            text={t('homepage.get_started')}
            onClick={() => handleClick('Get Started')}
          ></RectangleButton>
        </div>
      </div>
    </div>
  )
}

export default MadeMind
