import React, { useContext, useEffect, useState } from 'react'
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { TextInput } from '../../common';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../appContext/GlobalContext';
import { ProductBusketContext } from '../../layouts/ProductLayout';
import SearchLocation from '../../components/Modal/SearchLocation';

const DelivPickupDetails = ({title = "pickup", className, address, changeAddress, isPaymentCash}) => {
    const [openAddressDropdown, setOpenAddressDropdown] = useState(false)
    const { t } = useTranslation()
    const { listPickupAddress, location } = useContext(GlobalContext)
    const { deliveryData, setDeliveryData, setPickupData , pickupData } = useContext(ProductBusketContext)
    const [placeName, setPlaceName] = useState()
  
    const handleChange = (e) => {
        const {name , value} = e.target

        setDeliveryData(prev => ({...prev, [name]: value}))
    }

    useEffect(() => {
        if(title == 'pickup') {
            changeAddress(location?.address)
        }
    }, [])


    

  return (
    <>
     {openAddressDropdown && title == 'delivery' && <SearchLocation closeModal={setOpenAddressDropdown} changeAddress={changeAddress} changePlaceName={setPlaceName} />}
    <div className={className}>
        <div className="text-lg font-semibold">{`${title !== 'delivery' ? t('product.pickup_details') : t('product.delivery_details')} `}</div>
        <div
            id='address-options'
            className="my-4 flex items-center justify-between text-left duration-300 w-full"
            style={{ borderColor: '#FFB800' }}
            onClick={() => setOpenAddressDropdown(prev => !prev)}
            >
                <div className='flex'>
                    <img
                    alt="Location"
                    src="/assets/images/icons/location.svg"
                    width={24}
                    />
                    <span className='text-base ml-2 text-clip' >
                        {title == 'delivery' ? address ?? t('product.select_location') : location?.address}
                    </span>
                </div>
                
                {openAddressDropdown && title == 'delivery' ? <IoIosArrowDown color={ '#3C3A34'} size={18} className='min-w-[35px]' /> : <IoIosArrowForward color={ '#3C3A34'} size={18} className='min-w-[35px]' /> }
            </div>
            {/* {openAddressDropdown && title == 'pickup' && (
                    <div className='absolute bg-white border w-full left-0 rounded-lg drop-shadow-sm '>
                        {listPickupAddress.map((val, i) => {
                           return (
                            <div 
                                onClick={() => {
                                    changeAddress(val)
                                    setOpenAddressDropdown(false)
                                    }} 
                                className='px-7 py-3' 
                                key={i}
                            >
                                {val}
                            </div>
                           ) 
                        })}
                    </div>
                )} */}
            
            {title == 'delivery' && (
                <div className='my-5 space-y-5'>
                    <div>
                        <h4 className="ml-1 mb-2 text-base">{t('product.address_details')}</h4>
                        <TextInput
                            name="placeName"
                            value={placeName}
                            disabled={placeName ? false : true}
                        />
                    </div>
                    <div>
                        <h4 className="ml-1 mb-2 text-base">{t('product.note_courier')}</h4>
                        <TextInput
                            name="noteForCourier"
                            value={deliveryData.noteForCourier}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                </div>
            )}
            {title == 'pickup' && (
                <div className='my-5 space-y-5'>
                    {isPaymentCash && (
                        <div>
                            <h4 className="ml-1 mb-2 text-base">{t('register.email')}</h4>
                            <TextInput
                                name="email-pickup"
                                value={pickupData.email}
                                onChange={(e) => setPickupData(prev => ({...prev, email: e.target.value}))}
                            />
                        </div>
                    )}
                    <div>
                        <h4 className="ml-1 mb-2 text-base">{t('register.phone_number')}</h4>
                        <TextInput
                            name="phone-pickup"
                            value={pickupData.phone}
                            onChange={(e) => setPickupData(prev => ({...prev, phone: e.target.value}))}
                        />
                    </div>                    
                </div>
            )}
    </div>
    </>
  )
}

export default DelivPickupDetails