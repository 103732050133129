import { useContext, useState } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import BusketBox from '../FoodOrder/BusketBox'
import { SummaryCost } from '../FoodOrder/OrderSummary'
import { getProcessingCost, getTotalCost, getTotalCostLocationBased } from '../../utils/price'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Feedback from '../../components/Feedback/Feedback'
import { formatPrice } from '../../utils/number'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../common'
import axios from 'axios'

const ThankyouIcon = ({stroke="#45B64A",fill="#D7FFCD"}) => {
  return (
      <svg width="120" height="120" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="60" height="60" rx="30" fill={fill}/>
        <path d="M43.3332 20.8333L24.9998 39.1667L16.6665 30.8333" stroke={stroke} stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  )
}

const ThankYou = () => {
  const { t } = useTranslation()
  const { busket, setBusket, tips, setTips, delivPickupType, delivPickupPrice } = useContext(ProductBusketContext)
  const { method, colorCode ,location,uuid, productId} = useContext(GlobalContext)
  let old_busket = localStorage.getItem('old_busket');
  if(old_busket){
    old_busket = JSON.parse(old_busket)
  }
  let merged_busket = localStorage.getItem('merged_busket');
  if(merged_busket){
    merged_busket = JSON.parse(merged_busket)
  }
  // const productTotal = getTotalCost(busket)
  const productTotal = getTotalCostLocationBased(merged_busket?merged_busket:busket,location?.LocationId)
  const processingCost = method === 'online' ? getProcessingCost(busket) : 0
  const thankYouCode = window.localStorage.getItem("thankyou_code")

  
  const isInitialMount = useRef(true)
  const [changeStatus, setChangeStatus] = useState('')
  const [inPrepTime, setInPreTime] = useState('')

  const { state } = useLocation();
  const { discountAmount, promoCodeDiscount,pay_method,pay_later_status } = state || {};
  const navigate = useNavigate()
  
  console.log("method...thankyou",pay_method,pay_later_status,old_busket);


  useEffect(() => {
    return () => {
      if (isInitialMount.current) {
        isInitialMount.current = false
      } else {
        window.localStorage.removeItem('busket')
        window.localStorage.removeItem('tips')
        //sessionStorage.setItem('method', 'cash')
        setBusket([])
        setTips(0)
      }
    }
  }, [setBusket, setTips])

  useEffect(()=>{
    if(pay_later_status == 'show_paylater_order'){
      fetchPaylaterOrderChangeStatus()
    }
  },[])

  const fetchPaylaterOrderChangeStatus = async () =>{
    try {
      let currentPaylaterOrder = JSON.parse(localStorage.getItem('currentPaylaterOrder'))
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/changeStatus/order/${currentPaylaterOrder['order_id']}`);
      console.log('changeStatus order:', response.data); // For testing, remove in production
   
      let latestStatus = response.data.data[response.data.data.length - 1]
      let statusMapper = {
        0: t('paylater.status_received'),
        10: t('paylater.status_received'),
        20:t('paylater.status_in_prep'),
        30:t('paylater.status_com'),
      }
      let statusDat = response.data.data
      if(statusDat.length>0){{
        let statusKey = statusMapper[statusDat[statusDat.length-1]['ToStatus']]
        if(statusKey){
          setChangeStatus(statusKey)
        }
        if(statusDat[statusDat.length-1]['ToStatus'] == 20){
          let time = secondsToMinutes(statusDat[statusDat.length-1]['DeliveryTimeInSeconds'])
          setInPreTime(time)

        }else{
          setInPreTime('')
        }
      }}
    } catch (error) {
      console.error('Error changeStatus order:', error);
    }
  
  }

  function secondsToMinutes(seconds) {
    if(typeof seconds == 'string'){
        seconds = parseInt(seconds);
    }
    if(seconds < 0){
        seconds= -1*seconds
    }
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
    return minutes + " Min " + remainingSeconds + " Sec";
  }

  const handleAddMoreItems = ()=>{
    console.log("handleAddMoreItems");
      if(pay_later_status != 'show_paylater_order'){
        let busket = [];
        localStorage.setItem('busket', JSON.stringify(busket));
        setBusket(busket)
      }
      navigate(`/product/${productId}?${uuid}`)
    
  }
  const handleCloseAndPay = ()=>{
    console.log("handleCloseAndPay");
    navigate('/food-ordering/finish', {
      state: {
        location: `${location?.LocationId}`,
        pay_later_payment:true
      },
    })
  }

  return (
    <div className="flex flex-col justify-center items-center p-[10px]">

      {
        pay_method == 'pay_later' && pay_later_status != 'payment_completed'?(
          <>
           
                <>
                  <div>{t('paylater.order_status_ty')}</div>
                  <div className='font-semibold'>
                    {pay_later_status == 'created'?t('paylater.order_status_c'):(
                      
                      pay_later_status == 'show_paylater_order'?
                      (
                        <>
                          {changeStatus && (
                            <div>
                              {changeStatus}
                            </div>
                          )}
                          {inPrepTime && (
                            <div>
                              {inPrepTime}
                            </div>
                          )}
                        </>
                      )
                      
                      
                      :t('paylater.order_status_u')
                      
                      
                      )}
                    
                  </div>
                </>
              
              <div className="p-[50px]">
                {
                  (pay_later_status == 'created')?(
                    <ThankyouIcon/>
                  ):(
                    <ThankyouIcon stroke='#EEEEEE' fill='#FFB800'/>
                  )
                }
              </div>
                <h2 className="text-[20px] font-semibold mb-[20px] text-center" style={{marginTop: '-15px'}}>
                  {t('paylater.not_payed')}
                </h2>
          </>

        ):(

          <>
              {thankYouCode && (
                <>
                  <div>{t('product.thankyou_code')}</div>
                  <div className='font-semibold'>{thankYouCode}</div>
                </>
              )}
              <div className="p-[50px]">
                <ThankyouIcon/>
              </div>
              {
                 pay_later_status != 'payment_completed' && (
                  <h2 className="text-[20px] font-semibold mb-[20px] text-center">
                    {thankYouCode ? t('product.thank_notes_pickup') : t('product.thankyou_notes')}
                  </h2>
                 )
              }
              {
                pay_method == 'pay_later' && pay_later_status == 'payment_completed' && (
                <h2 className="text-[20px] font-semibold mb-[20px] text-center" style={{marginTop: '-15px'}}>
                  {t('paylater.order_paid')}
                </h2>

                )
              }
          </>
        )
      }

        {
        pay_method == 'pay_later' && (pay_later_status != 'created' && pay_later_status!='payment_completed' && pay_later_status != 'show_paylater_order' )?(
        <div className="flex justify-start w-full">
          {/* <h3 className="text-[18px] font-medium">Detaliile comenzii</h3> */}
          <h3 className="text-[18px] font-medium" style={{color:'#FFB800'}}>{t('paylater.order_added_new')}</h3>
        </div>

        ):(
        <div className="flex justify-start w-full">
          {/* <h3 className="text-[18px] font-medium">Detaliile comenzii</h3> */}
          <h3 className="text-[18px] font-medium">{t('product.order_details')}</h3>
        </div>

        )}
      {(pay_later_status!='payment_completed' && pay_later_status != 'show_paylater_order') && busket.map((x) => (
        <BusketBox
          key={x.id}
          item={x.item}
          qty={x.qty}
          dynamic={false}
          modifiers={x.modifiers}
        />
      ))}

      {
        (pay_later_status=='payment_completed' || pay_later_status == 'show_paylater_order') && merged_busket && merged_busket.map((x) => (
          <BusketBox
            key={x.id}
            item={x.item}
            qty={x.qty}
            dynamic={false}
            modifiers={x.modifiers}
          />
        ))
      }

      {
        pay_method == 'pay_later' && (pay_later_status != 'created'&& pay_later_status!='payment_completed' && pay_later_status != 'show_paylater_order') && (
          <>   
            <div className="flex justify-start w-full">
              <h3 className="text-[18px] font-medium">{t('product.order_details')}</h3>
            </div>

            {old_busket && old_busket.map((x) => (
              <BusketBox
                key={x.id}
                item={x.item}
                qty={x.qty}
                dynamic={false}
                modifiers={x.modifiers}
              />
            ))}
          </>

        )
      }

      <div className="relative p-[10px] mt-[20px] rounded-br-[20px] rounded-bl-[20px] w-full">
      {
          discountAmount?(
            <>
              <SummaryCost
              title={t('checkout.subtotal')}
              price={formatPrice((productTotal['discountedTotalPrice']?productTotal['discountedTotalPrice']:productTotal['result']))}
              
            />
            <SummaryCost title={`${t('checkout.dis_applied')} (${promoCodeDiscount.discount}%)`} price={`-${discountAmount}`} textColor={colorCode}/>
            {delivPickupType && (
                <SummaryCost
                  title={t(`product.${delivPickupType}`)}
                  price={delivPickupPrice}
                  border={false}
                />
              )}
            
            <SummaryCost
              title={t('product.tips')}
              price={tips ? tips : 0.0}
              border={true}
            />

            <SummaryCost
              title={t('product.total')}
              price={
                {'result':productTotal['result'] + processingCost - discountAmount + tips  + (delivPickupType ? delivPickupPrice : 0),
                  'discountedTotalPrice':productTotal['discountedTotalPrice']+ processingCost + tips - discountAmount  + (delivPickupType ? delivPickupPrice : 0)}
              }

              bold
            />
            </>
          
          ):(

            <>
            
            <SummaryCost
              title={t('product.prod_cost')}
              price={{'result':productTotal['result'],'discountedTotalPrice':productTotal['discountedTotalPrice']}}
              border={false}
            />
            {delivPickupType && (
              <SummaryCost
                title={t(`product.${delivPickupType}`)}
                price={delivPickupPrice}
                border={false}
              />
            )}
            <SummaryCost title={t('product.tips')} price={tips} border={false} />
            {/* <SummaryCost
              title={t('product.proc_cost')}
              price={processingCost}                       NO PROCESSING COST FOR THE MOMENT
              border={false}
            /> */}
    
              
    
            {/* <div
              className="w-[95%] h-[2px] mx-auto"
              style={{ backgroundColor: colorCode }}
            ></div>
    
                {
                  productTotal['discountedTotalPrice']<productTotal['result'] &&(
                    <SummaryCost
                      title={t('product.totalDiscounts')}
                      price={formatPrice(productTotal['result'] - productTotal['discountedTotalPrice'])}                                        
                      // icon={<AiOutlineExclamationCircle />}
                      border={true}
                    />
                  )
                } */}
            <SummaryCost
              title={t('product.total')}
              price={
                {'result':productTotal['result'] + processingCost + tips + (delivPickupType ? delivPickupPrice : 0),
                  'discountedTotalPrice':productTotal['discountedTotalPrice']+ processingCost + tips + (delivPickupType ? delivPickupPrice : 0)}
              }
              bold
              border={false}
            />
            </>
          )
      }
      
      {
        pay_method == 'pay_later' && pay_later_status!='payment_completed' && (
        <div className="flex" style={{marginTop: '18px'}}>
            <Button
              colorCode={'#DADADA'}
              text={t('paylater.add_more')}
              variant="outline"
              onClick={handleAddMoreItems}
              className="text-[16px] w-full font-medium"
              style={{
                backgroundColor: 'rgb(218, 218, 218)',
                borderColor: 'rgb(218, 218, 218)',
                marginRight: '15px',
                borderRadius: '10px',
                color: 'black',
                height: '40px',
                padding:'0'
              }}    
            />
            <Button
              colorCode={colorCode}
              text={t('paylater.close_pay')}
              variant="outline"
              onClick={handleCloseAndPay}
              className="text-[16px] w-full font-medium"
              style={{
                backgroundColor: colorCode,
                borderColor: colorCode,
                color: 'rgb(255, 255, 255)',
                borderRadius: '10px',
                height: '40px',
                padding:'0'
              }}
            />
          </div>

        )
      }

      </div>
      <Feedback setBusket={setBusket} setTips={setTips} colorCode={colorCode} />
    </div>
  )
}

export default ThankYou
