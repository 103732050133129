import { useTranslation } from 'react-i18next'
import { Button } from '../../common'
import { useState } from 'react'

const Tips = ({ selectedTip, colorCode, buttonTextColorCode }) => {
    const { t } = useTranslation()
    const [percent, setPercent] = useState(0)
    const [customTip, setCustomTip] = useState('')

    const digitsPattern = /^\d+$/

    const handleSelect = (v) => {
        setPercent(v)
        selectedTip(v)
    }

    const handleTipEntered = (event) => {
        const value = event.target.value

        if (value === '' || digitsPattern.test(value)) {
            if (0 <= value && value <= 100) {
                setCustomTip(value)
                selectedTip(value ? value : null)
                setPercent(value ? value : 0)
            }
        }
    }

    return (
        <div>
            <div className="mt-[50px] mb-[50px] px-[5px]">
                <h3 className="text-xl font-medium mb-[10px]">
                    {t('product.tips')}
                </h3>
                <h4 className="text-[15px] font-normal">{t('product.tips_description')}</h4>
                <div className="flex justify-between">
                    <Button
                        className={`mt-[10px] text-lg border-[#ebebeb91] shadow-lg font-medium `}
                        style={
                            percent === 0
                                ? { backgroundColor: colorCode, color: buttonTextColorCode }
                                : { backgroundColor: '#FFFF', color: '#000000' }
                        }
                        text="0%"
                        onClick={() => handleSelect(0)}
                    />
                    <Button
                        className={`mt-[10px] text-lg border-[#ebebeb91] shadow-lg font-medium`}
                        style={
                            percent === 10
                                ? { backgroundColor: colorCode, color: buttonTextColorCode }
                                : { backgroundColor: '#FFFF', color: '#000000' }
                        }
                        text="10%"
                        onClick={() => handleSelect(10)}
                    />
                    <Button
                        className={`mt-[10px] text-lg border-[#ebebeb91] shadow-lg font-medium`}
                        style={
                            percent === 15
                                ? { backgroundColor: colorCode, color: buttonTextColorCode }
                                : { backgroundColor: '#FFFF', color: '#000000' }
                        }
                        text="15%"
                        onClick={() => handleSelect(15)}
                    />
                    <div className="relative flex items-center">
                        <input
                            type="text"
                            style={
                                customTip !== '' && customTip === percent
                                    ? { width: '80px', backgroundColor: colorCode, color: buttonTextColorCode }
                                    : { width: '80px' }
                            }
                            className={`border-[2px] text-[18px] cursor-pointer text-black font-extrabold py-2 px-4 rounded-[50vh] transition duration-300 ease-in-out mt-[10px] hover:bg-[${colorCode}}] bg-[#ffb800] border-[#ebebeb91] shadow-lg
                  ${
                                customTip !== '' && customTip === percent
                                    ? `bg-[${buttonTextColorCode}]`
                                    : 'bg-white'
                            } border-[#ebebeb91] shadow-lg p-[5px]`}
                            value={customTip}
                            placeholder="_"
                            onChange={handleTipEntered}
                            onClick={handleTipEntered}
                        />
                        <span className="custom-input font-extrabold" style={ customTip !== '' && customTip === percent ? {color: buttonTextColorCode} : {} }></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tips
