const allergenContent = {
  Gluten:
    'Cereale care conțin gluten obținute din grâu, secară, orz, grâu spelt, grâu dur sau hibrizi ai acestora și produse derivate',
  Crustacee: 'Crustacee și produse derivate',
  Ouă: 'Ouă și produse derivate',
  Peste: 'Pește și produse derivate',
  Arahide: 'Fructe cu coaja tare (nuc, alun, cocotier, fistic, macadamia, stejar, fag, tei) si produse derivate',
  Soia: 'Soia (pd) și produse derivate',
  Lapte: 'Lapte și produse derivate (incl. lactoză)',
  Țelină: 'Țelină și produse derivate',
  Muștar: 'Muștar și produse derivate',
  Susan: 'Semințe de susan și produse derivate',
  Sulfiti: 'Dioxid de sulf si sulfiti',
  Lupin: 'Lupin si produse derivate',
  Moluște: 'Crustacee și produse derivate',
}

export default allergenContent
