import { useContext, useEffect } from 'react'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../common'
import Seo from '../../components/SEO/Seo'

const EmptyCart = () => {
  const { setShowHeader } = useContext(ProductBusketContext)
  const { productId, uuid, colorCode } = useContext(GlobalContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleGotoShopping = () => {
    navigate(`/product/${productId}?${uuid}`)
  }

  useEffect(() => {
    setShowHeader(true)
  }, [setShowHeader])

  return (
    <div className="flex flex-col justify-center items-center mt-[100px]">
      <Seo name="Empty Cart" />
      <img src="/assets/images/emptycart.png" alt="Empty Cart Image" />
      <p className="text-xl font-semibold mb-[2px] p-[15px] text-center mt-2">
        {t('product.empty_cart')}
      </p>
      <Button
      style={{color: "#FFB800",border:'none'}}
        // colorCode={colorCode}
        className=" font-medium mb-[50px] text-base"
        onClick={handleGotoShopping}
        // variant="outline"
        text={t('product.start_shopping')}
      />
    </div>
  )
}

export default EmptyCart
