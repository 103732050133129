import { useState } from 'react'
import { Star } from '../../../components/Star'

const STARS_LIST = Array.from({ length: 5 }, (_, index) => index + 1)

export const Stars = ({
  onChange,
  isReadOnly = false,
  value,
  isLargeSize,
  className = '',
}) => {
  const [internalValue, setInternalValue] = useState(value)
  const handleChange = (key) => {
    onChange?.(key)
  }

  return (
    <div
      className={`${isReadOnly ? 'pointer-events-none' : ''} flex ${className}`}
      onMouseLeave={() => setInternalValue(value)}
    >
      {STARS_LIST.map((key) => (
        <button
          key={key}
          data-key={key}
          onClick={() => handleChange(key)}
          onMouseEnter={() => setInternalValue(key)}
          disabled={isReadOnly}
        >
          <Star isLargeSize={isLargeSize} isSelected={key <= internalValue} />
        </button>
      ))}
    </div>
  )
}
