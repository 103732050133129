import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import Show404 from './pages/404/Show404'
import WentWrong from './pages/WentWrong/WentWrong'
import WentWrongRescan from './pages/WentWrong/WentWrongRescan'
import Product from './pages/Product/Product'
import { Transition } from '@headlessui/react'
import { Toaster, ToastIcon, resolveValue } from 'react-hot-toast'
import FoodOrder from './pages/FoodOrder/FoodOrder'
import ProductLayout from './layouts/ProductLayout'
import GlobalLayout from './appContext/GlobalContext'
import EmptyCart from './pages/EmptyCart/EmptyCart'
import FinishOrder from './pages/FinishOrder/FinishOrder'
import OrderLayout from './layouts/OrderLayout'
import ThankYou from './pages/ThankYou/ThankYou'
import Terms from './pages/Terms/Terms'
import Privacy from './pages/Privacy/Privacy'
import Support from './pages/Support/Support'
import Landing from './pages/Landing/Landing'
import DigitalMenu from './pages/DigitalMenu/DigitalMenu'
import HomeLayout from './pages/Home/Home'
import Blog from './pages/Blog/Blog'
import AnpcArticle from './pages/AnpcArticle/AnpcArticle'
import PosArticle from './pages/PosArticle/PosArticle'
import QRArticle from './pages/QRArticle/QRArticle'
import ReactGA from 'react-ga4'
import PaymentFailed from './pages/FinishOrder/PaymentFailed'

const TailwindToaster = () => {
  return (
    <Toaster position="top-right">
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="transform p-4 flex bg-white rounded shadow-lg"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <ToastIcon toast={t} />
          <p className="px-2">{resolveValue(t.message)}</p>
        </Transition>
      )}
    </Toaster>
  )
}

function App() {
  const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS
  if (measurementId) {
    ReactGA.initialize(measurementId)
  } else {
    console.log('Missing Google Analytics Measurement ID.')
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    })
  }, [])

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
            element={
              <GlobalLayout>
                <Outlet />
              </GlobalLayout>
            }
          > 
            <Route
            element={
              <ProductLayout>
                <Outlet />
              </ProductLayout>
            }
          >
            <Route path="/product/:productId" element={<Product />} />
            <Route
              path="/food-ordering"
              element={
                <OrderLayout>
                  <Outlet />
                </OrderLayout>
              }
            >
              <Route index element={<FoodOrder />} />
              <Route path="payment-failed" element={<PaymentFailed />} />
              <Route path="finish" element={<FinishOrder />} />
              <Route path="thankYou" element={<ThankYou />} />
            </Route>
            <Route path="/empty-cart" element={<EmptyCart />} />
            <Route path="/wrong" element={<WentWrong />} />
          </Route>
        </Route>
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/help-and-support" element={<Support />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/what-is-a-digital-menu" element={<DigitalMenu />} />
        <Route
          path="/blog/explicatii-ordin-anpc-2022"
          element={<AnpcArticle />}
        />
        <Route path="/blog/what-is-a-pos-system" element={<PosArticle />} />
        <Route path="/blog/what-is-a-qr-code" element={<QRArticle />} />
        <Route path="/home" element={<HomeLayout />} />
        <Route path="/wrongGeneral" element={<WentWrongRescan />} />
        <Route path="/*" element={<WentWrongRescan />} />
      </Routes>
      <TailwindToaster />
    </ScrollToTop>
  )
}

export default App
