import { useEffect, useState, useContext, useRef } from 'react'
import { formatPrice } from '../../utils/number'
import { ProductContext } from './ProductCategory'
import { ProductImage } from '../../common'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import allergenImages from '../../utils/allergenImages'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { BiInfoCircle } from 'react-icons/bi'
import AllergensPopup from '../../components/AllergensPopup/AllergensPopup'
import { GlobalContext } from '../../appContext/GlobalContext'
import parse from 'html-react-parser'
import { ProductBusketContext } from '../../layouts/ProductLayout'

const MAX_LENGTH = 150

export const filterDescription = (description = '') => {
  const splitter1 = '<br></p></br></br>'
  const splitter2 = '<br></br></a></p>'
  const parts = description.split(description.includes(splitter2) ? splitter2 : splitter1)

  if (parts.length === 2) {
    const longDescription = parts[0]
    const shortDescription = parts[1]

    return { longDescription, shortDescription }
  } else {
    return { longDescription: description, shortDescription: null }
  }
}

export const decodeHtmlEntity = (str) => {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = str
  return textArea.value
}

const BucketsBox = ({ product, unit }) => {
  const elementRef = useRef(null)
  const { t } = useTranslation()
  const { setProduct, setShowDialog, isItemUnavailableData, setAutoOrder } =
    useContext(ProductContext)
  const [isVisible, setIsVisible] = useState(false)
  const [allergens, setAllergens] = useState()
  const [currentProduct, setCurrentProduct] = useState(product)
  const [isBoolean, setisBoolean] = useState(false)
  const { selectedLanguage } = useContext(GlobalContext)
  const { productDiscount,completeMenuData } = useContext(ProductBusketContext)
  const [cardHeight, setCardHeight] = useState()
  const [prodDesc, setProdDesc] = useState()
  const [systemPrice,setSystemPrice] = useState(0)

  useEffect(() => {
    if (!product?.ID) return

    fetch(
      `${process.env.REACT_APP_API_URL}/partners/locations/menu/nutr_values_calc?ProductId=${product?.ID}`
    )
      .then((res) => res.json())
      .then((data) => {
        let potentialAllergens = data?.ingredients[0]?.potential_allergens
        if (
          (data?.ingredients[0]?.allergens &&
            data.ingredients[0].allergens.length > 0) ||
          (potentialAllergens && potentialAllergens.length > 0)
        ) {
          const uniqueAllergens = Array.from(
            new Set(
              data?.ingredients.flatMap((ingredient) => ingredient.allergens)
            )
          )
          // const allergensToShow = uniqueAllergens.filter(allergen => !potentialAllergens.includes(allergen))
          setAllergens(uniqueAllergens)
        } else setAllergens([])
      })
      .catch((error) => {
        console.log(error)
        toast.error('An error has occurred while loading the ingredients')
      })

       if(product['SGRProductID']){
        let sysEle = completeMenuData['SystemItems'].find((eleSI)=>eleSI.ID == product['SGRProductID'])
        if(sysEle){
          setSystemPrice(sysEle['Price'])
        }
       }
       
  }, [product?.ID])
  useEffect(() => {
    if (product && isItemUnavailableData) {
      const matchItem = isItemUnavailableData.find(
        (item) =>
          item.ItemID === product.ID ||
          (item.Available === false && item.ItemID === product.ParentID)
      )

      if (matchItem) {
        product.Available = matchItem.Available
      }

      setisBoolean(!isBoolean)
    }
  }, [product, isItemUnavailableData])

  useEffect(() => {
    if (product.Available !== undefined && !product.Available) {
      setCardHeight(elementRef.current?.clientHeight)
    }
    setCurrentProduct(product)
  }, [product, isBoolean])

  const getProductName = (product, language) => {
    const key = `Name${language}`;
    return product[key] || product.Name;
  };

  const productName = getProductName(product, selectedLanguage);

  const getDesc = (product, language) => {
    const key = `Description${language}`;
    return product[key] || product.Description;
  }

  const finalDesc = getDesc(product, selectedLanguage);

  useEffect(() => {
    if (selectedLanguage) {
      setProdDesc(`${product?.Description}${selectedLanguage}`)
    } else {
      setProdDesc(`${product?.Description}`);
    }
  }, [selectedLanguage])


  const handleSelect = () => {
    setProduct(product)
    setAutoOrder(false)
    setProduct(product)
    setShowDialog(true)
  }

  const { longDescription, shortDescription } = filterDescription(
    // languages.en ? product?.DescriptionEN : languages.de ? product?.DescriptionDE : product?.Description
    finalDesc
  )

  const handleAllergensOpen = (e) => {
    e.stopPropagation()
    setIsVisible(true)
  }

  const totalPrice = () => {
    let sumModifier = 0;
    if (currentProduct.Modifiers.length) {
      let items = currentProduct.Modifiers[0].Items
      if(currentProduct.Modifiers[0]){
        let modiLen = Math.min(items.length,currentProduct.Modifiers[0].Min)
        for (let i = 0; i < modiLen; i++) {
          sumModifier = sumModifier + items[i].Price
        }
      }
    }
    return formatPrice(currentProduct.Price + sumModifier - systemPrice)
  }
  const totalDisPrice = () => {
    let sumModifier = 0;
    if (currentProduct.Modifiers.length) {
      let items = currentProduct.Modifiers[0].Items
      if(currentProduct.Modifiers[0]){
        let modiLen = Math.min(items.length,currentProduct.Modifiers[0].Min)
        for (let i = 0; i < modiLen; i++) {
          sumModifier = sumModifier + items[i].Price
        }
      }
    }
    return formatPrice(currentProduct.discountedProductPrice + sumModifier - systemPrice)
  }

  return (
    <div className={'relative'}>
      <div
        className={`w-full cursor-pointer border border-gray-200 bg-white rounded-[10px] shadow p-[10px] flex flex-col justify-center`}
        onClick={handleSelect}
        id={currentProduct.id}
      >
        <div
          className={`flex gap-3 items-center ${currentProduct.Available !== undefined &&
            !currentProduct.Available &&
            'blur-[3px]'
            }`}
        >
          <div className="relative w-[80px] h-[80px]">
            <ProductImage
              alt="Product Image"
              product={currentProduct}
              className="w-full h-[80px] object-cover rounded-[10px] flex items-center justify-center"
              effect="opacity"
            />
          </div>
          <div className="flex-1 flex flex-col h-full gap-1">
            <h3
              className="font-semibold text-[14px] sm:text-[18px] pr-[10px]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: currentProduct.Description ? '1' : '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: '24px',
              }}
            >
              {(currentProduct?.Available || currentProduct?.Available === undefined) &&
                selectedLanguage &&
                productName
              }
            </h3>
            <div
              className="text-xs pr-[10px] text-ellipsis sm:text-[14px] font-normal"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '3',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {shortDescription
                ? parse(shortDescription)
                : parse(longDescription)}
            </div>
            <div
              className={`${allergens && allergens.length > 0
                ? 'flex mt-2 mb-2 gap-3 flex-wrap items-center'
                : ''
                }`}
            >
              {allergens?.map((allergen) => (
                <img
                  src={allergenImages[allergen]}
                  alt={allergen}
                  key={allergen}
                  className="w-[14px] h-[14px] "
                />
              ))}
              {allergens && allergens.length > 0 && (
                <BiInfoCircle
                  className="text-[#9C9C9C] text-[14px] cursor-pointer"
                  onClick={handleAllergensOpen}
                />
              )}
            </div>
            {/* <h4 className="font-bold text-[16px] sm:text-[24px]">
              {totalPrice()} <small>{unit}</small>
            </h4> */}
             <h4 className="font-bold text-[16px] sm:text-[24px]" style={{position:'relative'}}>
                {currentProduct.discountedProductPrice ? (
                  <>
                    <span style={{marginRight: '3px'}}>{totalDisPrice()} <small>{unit}</small></span>
                    <span style={{ textDecoration: 'line-through',position:'absolute',
                      fontSize: '13px',
                      color: '#9C9C9C',
                      fontWeight: 400 }}>
                      {totalPrice()} {unit}
                    </span>
                  </>
                ) : (
                  <>
                    {totalPrice()} <small>{unit}</small>
                  </>
                )}
              </h4>
              {
                systemPrice && systemPrice != 0 ? (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #E37C03',
                    width: 'fit-content',
                    borderRadius: '100px',
                    paddingLeft: '8px',
                    paddingRight: '8px'
                  }}>
                    <span style={{
                      fontSize: '14px',
                      color: '#E37C03',
                      paddingRight: '2px'
                    }}>
                      +{formatPrice(systemPrice)} {unit}
                    </span>
                    <img
                      alt="System"
                      src="/assets/images/info-orange.svg"
                      style={{
                        height: '14px',
                        width: '14px'
                      }}
                    />
                  </div>
                ):''
              }
          </div>
        </div>
      </div>
      {currentProduct.Available !== undefined && !currentProduct.Available && (
        <div
          ref={elementRef}
          className="absolute top-[0px] h-full w-full flex"
          onClick={handleSelect}
        >
          <div className="min-w-[100px]"></div>
          <div className="flex flex-col h-full w-52">
            <span
              className={`pt-[10px] font-bold text-[16px] sm:text-[18px] ${cardHeight > 115 && 'absolute'
                }`}
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: 1,
              }}
            >
              {currentProduct.Name}
            </span>
            <div
              className={`flex flex-col items-center ${cardHeight > 115 && 'my-auto'
                }`}
            >
              <img
                src="/assets/images/icons/lock_icon.svg"
                alt="lock_icon"
                className="h-[24px]"
              />
              <span className="text-[18px] font-medium">
                {t('product.unavailable_text')}
              </span>
            </div>
          </div>
        </div>
      )}
      <AllergensPopup
        setIsVisible={setIsVisible}
        isVisible={isVisible}
        allergens={allergens}
        currentProduct={currentProduct}
      />
    </div>
  )
}

export default BucketsBox
