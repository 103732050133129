import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const WhyAdHoc = ({ id }) => {
  const { t } = useTranslation()
  return (
    <div id={id} className="relative">
      <div className="text-center md:mt-[100px]">
        <div className="container">
          <h2 className="uppercase md:text-[60px] text-[30px] font-bold">
            {t('homepage.why_title')}
          </h2>
          <div className="flex mt-[60px] flex-wrap">
            <div className="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-3 py-0 pb-[30px] md:py-10 border-r-[0.5px] border-b-[0.5px] md:border-none border-[#0000001a]">
              <div className="relative h-full">
                <div className="flex justify-center md:justify-start w-full">
                  <div className="rounded-full relative w-[44px] h-[50px] md:w-[60px] md:h-[60px]">
                    <div className="bg-primary rounded-full absolute bottom-0 right-0 w-[32px] h-[32px] md:w-[40px] md:h-[40px] -z-[10]"></div>
                    <LazyLoadImage
                      src="/assets/images/why01.png"
                      alt={t('homepage.why_card1_title')}
                      className="w-[44px] h-[44px] md:w-[60px] md:h-[60px]"
                      effect="opacity"
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <h3 className="font-bold mt-[20px] mb-[10px]">
                    {t('homepage.why_card1_title')}
                  </h3>
                  <p className="text-[13px] leading-[22px] text-[#464646]">
                    {t('homepage.why_card1_text')}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-3 py-0 md:py-10 border-l-[0.5px] border-b-[0.5px] md:border-none border-[#0000001a]">
              <div className="relative h-full">
                <div className="flex justify-center md:justify-start w-full">
                  <div className="rounded-full relative w-[44px] h-[50px] md:w-[60px] md:h-[60px]">
                    <div className="bg-primary rounded-full absolute bottom-0 right-0 w-[32px] h-[32px] md:w-[40px] md:h-[40px] -z-[10]"></div>
                    <LazyLoadImage
                      src="/assets/images/why02.png"
                      alt={t('homepage.why_card2_title')}
                      className="w-[44px] h-[44px] md:w-[60px] md:h-[60px]"
                      effect="opacity"
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <h3 className="font-bold mt-[20px] mb-[10px]">
                    {t('homepage.why_card2_title')}
                  </h3>
                  <p className="text-[13px] leading-[22px] text-[#464646]">
                    {t('homepage.why_card2_text')}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-3 py-0 pt-[30px] pb-[30px] md:py-10 border-r-[0.5px] border-t-[0.5px] md:border-none border-[#0000001a]">
              <div className="relative h-full">
                <div className="flex justify-center md:justify-start w-full">
                  <div className="rounded-full relative w-[44px] h-[50px] md:w-[60px] md:h-[60px]">
                    <div className="bg-primary rounded-full absolute bottom-0 right-0 w-[32px] h-[32px] md:w-[40px] md:h-[40px] -z-[10]"></div>
                    <LazyLoadImage
                      src="/assets/images/why03.png"
                      alt={t('homepage.why_card3_title')}
                      className="w-[44px] h-[44px] md:w-[60px] md:h-[60px]"
                      effect="opacity"
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <h3 className="font-bold mt-[20px] mb-[10px]">
                    {t('homepage.why_card3_title')}
                  </h3>
                  <p className="text-[13px] leading-[22px] text-[#464646]">
                    {t('homepage.why_card3_text')}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 sm:w-1/2 w-1/2 p-3 py-0 pt-[30px] pb-[30px] md:py-10 border-l-[0.5px] border-t-[0.5px] md:border-none border-[#0000001a]">
              <div className="relative h-full">
                <div className="flex justify-center md:justify-start w-full">
                  <div className="rounded-full relative w-[44px] h-[50px] md:w-[60px] md:h-[60px]">
                    <div className="bg-primary rounded-full absolute bottom-0 right-0 w-[32px] h-[32px] md:w-[40px] md:h-[40px] -z-[10]"></div>
                    <LazyLoadImage
                      src="/assets/images/why04.png"
                      alt={t('homepage.why_card4_title')}
                      className="w-[44px] h-[44px] md:w-[60px] md:h-[60px]"
                      effect="opacity"
                    />
                  </div>
                </div>
                <div className="text-center md:text-left">
                  <h3 className="font-bold mt-[20px] mb-[10px]">
                    {t('homepage.why_card4_title')}
                  </h3>
                  <p className="text-[13px] leading-[22px] text-[#464646]">
                    {t('homepage.why_card4_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyAdHoc
