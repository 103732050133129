import { forwardRef, useMemo } from 'react'
import { getProductFallbackImage } from '../pages/Product/ProductCategory'
import Image from './Image'

// TODO move to product module
const ProductImage = forwardRef(({ product, isLazy = true, ...props }, ref) => {
  const fallbackImage = useMemo(() => {
    return getProductFallbackImage(product?.Name, product?.Category)
  }, [product?.Name, product?.Category])

  return (
    <Image
      src={product?.ImageUrl || ''}
      fallback={fallbackImage}
      isLazy={isLazy}
      {...props}
      ref={ref}
    />
  )
})

export default ProductImage
