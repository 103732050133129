import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'

const Counter = ({
   className,
   onChange,
   value,
   size = 40,
   fontSize = 1.5,
   min = 1,
   max = 99,
   colorCode = '#FFB800',
   disabled = false,
   mxSize = 10
 }) => {
    const handleMinus = () => {
        if(disabled) return
        if (value > min) onChange(value - 1, 'minus')
    }
    const handlePlus = () => {
        if(disabled) return
        if (value < max) onChange(value + 1, 'plus')
    }

    return (
        <div className={`flex items-center justify-center ${className}`}>
            <button disabled={value === min} className={`cursor-pointer top-[15px] right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[${mxSize}px]`}
                    onClick={handleMinus}>
                <AiOutlineMinus />
            </button>

            <h3 className={`font-normal text-[${fontSize}rem] mx-[${mxSize}px] select-none`}>
                {value}
            </h3>
            <button disabled={value === max || disabled} style={{ color: colorCode }}
                    className={`cursor-pointer top-[15px] right-[10px] bg-[#FFF] transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[${mxSize}px]`}
                    onClick={handlePlus}>
                <AiOutlinePlus/>
            </button>
        </div>
    )
}

export default Counter
