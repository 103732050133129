import { useEffect, useState } from 'react'
import About from './About'
import Blog from './Blog'
import Calculate from './Calculate'
import Choose from './ChooseProducts'
import ContactUs from './ContactUs'
import Footer from './Footer'
import Header from './Header'
import HowItWorks from './HowItWorks'
import LandingMenu from './ProductFeatures'
import LandingPopupMenu from './LandingPopupMenu'
import MadeMind from './MadeMind'
import Pricing from './Pricing'
import TryMe from './TryMe'
import Values from './Values'
import WhyAdHoc from './WhyAdHoc'
import HomeSection from './HomeSection'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const Landing = () => {
  const [showPopup, setShowPopup] = useState(false)
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)

        if (element) {
          element.scrollIntoView()
        }
      }, 850)
    }
  }, [pathname, hash, key])

  const handleBecomeAPartner = (actionName) => {
    ReactGA.event({
      category: 'Landing Page - User Interaction',
      action: 'Landing Page - Button Clicked',
      label: `${actionName} | Meniu QR Code | Meniu Digital | Adhocorder.ro`,
    })
    setShowPopup(true)
  }

  useEffect(() => {
    if (showPopup) {
      document.body.classList.add('body-lock')
    } else {
      document.body.classList.remove('body-lock')
    }
  }, [showPopup])

  return (
    <div>
      <HomeSection handleClick={handleBecomeAPartner} />
      <Header handleClick={handleBecomeAPartner} />
      <About id="about" />
      <Values />
      {/*<div>*/}
      {/*  <img src="/assets/images/landing_ad.png" className="w-full" />*/}
      {/*</div>*/}
      <LandingMenu />
      <WhyAdHoc id="features" />
      <MadeMind handleClick={handleBecomeAPartner} />
      <HowItWorks />
      <Pricing id="prices" handleClick={handleBecomeAPartner} />
      <Choose />
      <TryMe />
      <Calculate handleClick={handleBecomeAPartner} />
      <Blog id="articles" />
      <ContactUs id="contact" />
      <Footer showSalad={true} />
      <LandingPopupMenu setShowPopup={setShowPopup} active={showPopup} />
    </div>
  )
}

export default Landing
