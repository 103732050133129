import React, { useContext, useEffect, useState } from 'react'
import { Button, TextInput } from '../../common'
import { IoMdArrowBack } from 'react-icons/io'
import { AiOutlineSearch } from 'react-icons/ai'
import { RxCross1 } from 'react-icons/rx'
import { GlobalContext } from '../../appContext/GlobalContext'
import debounce from 'lodash.debounce'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import ReactLoading from 'react-loading'



const SearchLocation = ({closeModal, changeAddress, changePlaceName}) => {
    const { colorCode } = useContext(GlobalContext)
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('');
    const [listLocation, setListLocation] = useState([])
    const [selectedLoc, setSelectedLoc] = useState()
    const [loadingLoc, setLoadingLoc] = useState(false)
    const { setDeliveryData } = useContext(ProductBusketContext)

    useEffect(() => {
        document.body.classList.add('no-scroll')
    
        return () => {
          document.body.classList.remove('no-scroll')
        }
      }, [])
      

      const handleSearch = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        if(newSearchTerm.length >= 3 ) {
            setLoadingLoc(true)
            debouncedSearch(newSearchTerm)
        }
     };

     const debouncedSearch = debounce((searchQuery) => {
        fetchLocations(searchQuery);
      }, 500);
    
  const fetchLocations = (searchQuery) => {
    const tokenAccess = 'pk.bb72024f7c80ea899402846c3e13305c'
    fetch(`https://api.locationiq.com/v1/autocomplete?key=${tokenAccess}&q=${searchQuery}&limit=10&dedupe=0&`)
      .then(response => response.json())
      .then(data => {
        if(!data.error){
            setListLocation(data)
        } else {
            toast.error(data.error)
            console.log(data.error)
        }
      })
      .catch(error => {
        console.error('Error fetching data locations:', error);
        toast.error(`Error fetching data locations: ${error}`)
      })
      .finally(() => {
        setLoadingLoc(false)
      })
  };

const handleSelectAddress = (loc) => {
    setSelectedLoc(loc)
    setDeliveryData(prev => ({
        ...prev,
        postalCode: loc?.address?.postcode,
        street: loc?.address?.road ?? loc?.display_address,
        city: loc?.address?.city,
        county: loc?.address?.county,
        country: loc?.address?.country,
        long: loc?.lon,
        lat: loc?.lat
    }))
    setSearchTerm(loc.display_name)
    changeAddress(loc.display_name)
    changePlaceName(`${loc.display_place}${loc.address?.house_number ? '/'+loc.address?.house_number : ""}${loc?.address?.postcode ? '/'+loc?.address?.postcode : ""}`)
    closeModal(false)
}
    
  return (
    <div className='fixed z-20 top-0 left-0 w-full h-full bg-gray-50 box-border p-3'>
        <Button
          style={{ borderColor: 'transparent' }}
          className={`bg-gray-50 justify-start px-[8px] text-[20px]`}
          variant="icon"
          icon={<IoMdArrowBack />}
          onClick={() => closeModal(false)}
          text={'Back'}
        />
        <TextInput
            value={searchTerm}
            onChange={(e) => handleSearch(e)}
            className="flex shadow-lg border mt-5 rounded-lg"
            icon={<AiOutlineSearch color={colorCode} />}
            placeholder={t('product.search_location')}
            action={<RxCross1 color={colorCode}/>}
            handleAction={() => {
                setListLocation([])
                setSearchTerm('')
            }}
            
          />
          <div className='h-4/5 overflow-scroll py-3'>
            {loadingLoc ? (
                <div className='flex justify-center mt-8'>
                    <ReactLoading type="spinningBubbles" color={colorCode} />
                </div>
            ) : (
                <>
                {listLocation.map((loc, i) => {
                    return (
                    <div onClick={() => handleSelectAddress(loc)} key={i} className={`flex space-x-2 py-2 ${selectedLoc?.osm_id == loc.osm_id && 'bg-gray-300'}`}>
                        <img
                            alt="Location"
                            src="/assets/images/icons/location.svg"
                            width={22}
                        />
                        <div className='text-base'>{loc.display_name}</div>
                    </div>
                    )
                })}
                </>
            )}
          </div>
        
    </div>
  )
}

export default SearchLocation