export const mergeArrayOfObject =(arr1, arr2)=> {
  const map = new Map();

  arr1.concat(arr2).forEach(item => {
    if (!map.has(item.id)) {
      map.set(item.id, item);
    }
  });

  return Array.from(map.values());
}

export function UpdateItems(oldItems, newItems) {
  const mergedItems = [...oldItems];

  newItems.forEach(newItem => {
    const index = mergedItems.findIndex(item => item.id === newItem.id);
    if (index !== -1) {
      // Update existing item
      mergedItems[index] = newItem;
    } else {
      // Add new item
      mergedItems.push(newItem);
    }
  });

  return mergedItems;
}