import React, { useContext, useEffect, useState } from 'react'
import Footer01 from '../components/Footer/Footer01'
import Header03 from '../components/Header/Header03'
import { ProductBusketContext } from './ProductLayout'
import { GlobalContext } from '../appContext/GlobalContext'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Seo from '../components/SEO/Seo'

const OrderLayout = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [title, setTitle] = useState(t('product.my_order'))
  const [showBack, setShowBack] = useState(true)
  const { busket, setShowHeader, loading } = useContext(ProductBusketContext)
  const { colorCode, locationName,location } = useContext(GlobalContext)
  const { state } = useLocation();
  const { pay_later_payment } = state || {};
  useEffect(() => {
    setShowHeader(false)
  }, [setShowHeader])

  useEffect(() => {
    let currentLocationBusketLength = busket.filter(item=>item.locationId === location.LocationId).length
    if (
      !loading &&
      currentLocationBusketLength === 0 &&
      window.location.pathname !== '/food-ordering/thankYou' &&
      !pay_later_payment
    ) {
      navigate('/empty-cart')
    }
  }, [busket, loading, navigate])

  useEffect(() => {
    if (window.location.pathname === '/food-ordering/finish') {
      setShowBack(true)
      setTitle(t('product.payment'))
    } else if (window.location.pathname === '/food-ordering/checkout') {
      setShowBack(true)
      setTitle(t('product.checkout'))
    } else if (window.location.pathname === '/food-ordering/thankYou') {
      setShowBack(false)
      setTitle(t('product.thankYou'))
    } else if (window.location.pathname === '/food-ordering') {
      setTitle(locationName)
    } else {
      setShowBack(true)
      setTitle(t('product.my_order'))
    }
  }, [locationName, t])

  return (
    <div className="flex flex-col">
      <Seo name="Order" />
      <Header03 title={title} showBack={showBack} colorCode={colorCode} />
      <main>{children}</main>
    </div>
  )
}

export default OrderLayout
