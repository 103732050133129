import { StarIcon } from '../icon'

export const Star = ({ isSelected, isLargeSize }) => {
  const styles = isSelected
    ? { fillColor: '#FFB800', color: '#FFB800', strokeWidth: '2' }
    : { fillColor: 'transparent', color: '#9C9C9C', strokeWidth: '3' }

  const size = isLargeSize ? 40 : 24
  const strokeWidth = isLargeSize ? 1 : styles.strokeWidth

  return (
    <StarIcon
      fillColor={styles.fillColor}
      color={styles.color}
      strokeWidth={strokeWidth}
      size={size}
    />
  )
}
