import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductBusketContext } from '../../layouts/ProductLayout'
import { GlobalContext } from '../../appContext/GlobalContext'
import BusketBox from './BusketBox'
import { useNavigate } from 'react-router-dom'
import { Button, TextArea, ProductImage } from '../../common'
import { settings_bucket } from '../Product/ProductCategory'
import ProductDialog from '../../components/ProductDialog/ProductDialog'
import Slider from 'react-slick'
import ReactLoading from 'react-loading'
import { formatPrice } from '../../utils/number'
import { toast } from 'react-hot-toast'
import uuid from 'react-uuid'
import { comparer } from '../../utils/filter'
import OrderSummary from './OrderSummary'
import Seo from '../../components/SEO/Seo'
import { GoPlus } from 'react-icons/go'
import useProductsSync from '../../hooks/useProductsSync'
import { getTotalCostLocationBased } from '../../utils/price'

const RandomAddItem = ({ className = '', product, unit = 'RON' }) => {
  const { busket, setBusket } = useContext(ProductBusketContext)
  const { colorCode, selectedLanguage, location } = useContext(GlobalContext)
  // const { colorCode } = useContext(GlobalContext)
  const [isHovering, setIsHovering] = useState(false)
  const { t } = useTranslation()
  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const getProductName = (item, language) => {
    const key = `Name${language}`
    return item[key] || item.Name
  }

  const productName = getProductName(product, selectedLanguage)

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const setDefaultModifiers = (modifier, currentModifiers) => {
    let results = []
    const sortedByPriceItems = [...modifier?.Items]?.sort((a, b) => {
      // return a?.Price > b?.Price ? 1 : -1
      return b.Price - a.Price
    })

    for (let i = 0; i < modifier?.Min; i++) {
      results?.push({ ...modifier?.Items[i], quantity: 1 })
    }

    currentModifiers.push(results)
  }

  const handleAddToBusket = () => {
    let updatedModifiers = []

    product.Modifiers.forEach((item) => {
      setDefaultModifiers(item, updatedModifiers)
    })
    const allSelectedModifiers = Object.values(updatedModifiers).flat()
    console.log('updatedModifiers', updatedModifiers)
    const existItem = busket.find(
      (x) =>
        x.item.ID === product.ID && comparer(x.modifiers, product.Modifiers)
    )
    if (existItem !== undefined) {
      setBusket((prev) =>
        (prev ?? []).map((x) => {
          if (x.id === existItem.id) {
            return {
              ...x,
              locationId: location.LocationId,
              qty: x.qty + 1,
            }
          }
          return x
        })
      )
    } else {
      const id = uuid()
      setBusket((prev) => [
        ...(prev ?? []),
        {
          item: product,
          qty: 1,
          comment: '',
          locationId: location.LocationId,
          modifiers: allSelectedModifiers,
          id,
          tags: [],
        },
      ])
    }
    setIsHovering(false)
    toast.success('Successfully Added new product to your cart.', {
      position: 'bottom-right',
    })
  }

  const totalPrice = () => {
    let sumModifier = 0
    if (product.Modifiers.length) {
      let items = product.Modifiers[0].Items
      for (let i = 0; i < product.Modifiers[0].Min; i++) {
        sumModifier = sumModifier + items[i].Price
      }
    }
    return formatPrice(product.Price + sumModifier)
  }

  return (
    <div
      className={`bg-white rounded-[5px] border border-gray-100 shadow-md my-[15px] mx-[10px] relative ${
        className ? className : 'aspect-w-1 aspect-h-1'
      }`}
      style={{ width: '90%', height: '208px' }}
    >
      <div className="absolute inset-0 px-[5px] pt-[5px]">
        <div className="relative w-full h-[63%]">
          <ProductImage
            product={product}
            alt=""
            className="absolute top-0 left-0 w-full h-full object-cover rounded-[5px]"
          />
        </div>
        <div className="flex flex-col justify-between">
          <span className="flex justify-center items-center">
            <h3
              className="font-normal text-sm w-full px-[5px]"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '1',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedLanguage && productName}
              {/* {product.Name} */}
            </h3>
          </span>
          <div className="flex justify-center items-center">
            <h3 className="font-medium text-[12px] mb-2">
              {totalPrice()}
              <small>{unit}</small>
            </h3>
          </div>
          <Button
            variant="icon"
            icon={<GoPlus />}
            text={t('product.add')}
            // text="Adaugă"
            className="bg-white border-gray-200 flex items-center justify-evenly text-[10px] !px-1 !py-0 mb-[10px] h-[25px] font-normal"
            onClick={handleAddToBusket}
            style={{ backgroundColor: isHovering ? colorCode : '#FFF' }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
    </div>
  )
}

const getItemsWithTag = (items, tag, basket) => {
  let itemsWithTag = []

  const searchItems = (items) => {
    items.forEach((item) => {
      if (!item?.IsCategory) {
        if (item?.Tags?.includes(tag)) {
          if (
            !itemsWithTag?.find((a) => a?.ID === item?.ID) &&
            !basket?.some((a) => a?.item?.Name === item?.Name)
          )
            itemsWithTag = [...itemsWithTag, item]
        }
      } else {
        searchItems(item?.Items)
      }
    })
  }

  searchItems(items)

  return itemsWithTag.filter((item) => item?.Tags.includes(tag))
}

const getItemsFromMenu = (items) => {
  let ans = []
  items?.forEach((x) => {
    if (!x?.IsCategory) {
      if (!ans?.find((a) => a?.ID === x?.ID)) ans = [...ans, x]
    } else {
      ans = [...ans, ...getItemsFromMenu(x?.Items)]
    }
  })
  return ans
}

const FoodOrder = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [comment, setComment] = useState('')
  const [clientId, setClientId] = useState('')
  const [randomItems, setRandomItems] = useState([])
  const [selectedModifiers, setSelectedModifiers] = useState()
  const [addableItemsData, setAddableItemsData] = useState([])
  const {
    busket,
    product,
    setProduct,
    showDialog,
    setShowDialog,
    setIsItemUnavailableData,
    isItemUnavailableData,
    getCompleteInvoice
  } = useContext(ProductBusketContext)
  const { productId, locationName, colorCode, isEN, location,currentPaylaterOrder,selectedLanguage } =
    useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState()
  const [isUpdatingBasket, setIsUpdatingBasket] = useState(false)
  const productTotal = getTotalCostLocationBased(busket, location?.LocationId)
  const onProductComplete = useCallback(
    (data) => {
      let items = []
      if (busket) {
        const taggedItems = getItemsWithTag(data?.Items, location?.tags, busket)
        items =
          taggedItems.length > 0 ? taggedItems : getItemsFromMenu(data?.Items)
        if (items.length > 0 && items.length < 3) {
          const additionalItems = getItemsFromMenu(data?.Items)
          const shuffledAdditionalItems = additionalItems.sort(
            () => 0.5 - Math.random()
          )
          while (items.length < 3 && shuffledAdditionalItems.length > 0) {
            const itemToAdd = shuffledAdditionalItems.pop()
            if (!taggedItems.includes(itemToAdd)) {
              items.push(itemToAdd)
            }
          }
          items = [...taggedItems, ...items.slice(0, 3 - taggedItems.length)]
        }
      }
      setRandomItems(items)
    },
    [busket, location?.LocationId]
  )

  const { isLoading: isProductSyncing } = useProductsSync(
    productId,
    onProductComplete
  )

  const handleCommentChange = (e) => {
    setComment(e.target.value)
  }

  const displayComments = (basket) => {
    const comments = basket
      .map((item) => item?.comment)
      .filter((comment) => comment.trim() !== '')
      .join('\n')
    return comments || ''
  }

  useEffect(() => {
    setComments(displayComments(busket))
  }, [busket])

  const mergeBaskets = ()=> {
    // Get current and old busket from localStorage
    let currentBusket = JSON.parse(localStorage.getItem('busket')) || [];
    let oldBusket = JSON.parse(localStorage.getItem('old_busket')) || [];

    // Helper function to check if two arrays of modifiers are the same
    const areModifiersSame = (modifiersA, modifiersB) => {
        if (modifiersA.length !== modifiersB.length) return false;
        return modifiersA.every((modA) => {
            return modifiersB.some((modB) => 
                modA.ID === modB.ID && modA.quantity === modB.quantity
            );
        });
    };

    // Resulting merged array
    let mergedBusket = [];

    // Combine the two buskets
    let combinedBusket = [...currentBusket, ...oldBusket];

    // Process the combined busket
    combinedBusket.forEach(item => {
        // Check if the item with the same ID already exists in the mergedBusket
        let existingItem = mergedBusket.find(mergedItem => mergedItem.item.ID === item.item.ID);

        if (existingItem) {
            // Check if the modifiers match exactly
            if (areModifiersSame(existingItem.modifiers, item.modifiers)) {
                // If they match, increase the qty by 1
                existingItem.qty += item.qty;
            } else {
                // If modifiers don't match, add the item as a separate entry
                mergedBusket.push(item);
            }
        } else {
            // If the item with the same ID doesn't exist, add it to the mergedBusket
            mergedBusket.push(item);
        }
    });

    // Save the merged busket back to localStorage
    localStorage.setItem('merged_busket', JSON.stringify(mergedBusket));

    return mergedBusket;
}


  const handleOrderNextClick = () => {
    if(currentPaylaterOrder){
      let mergedBuskets = mergeBaskets()
      let currentPaylaterOrder = JSON.parse(localStorage.getItem('currentPaylaterOrder'))
      let tips = JSON.parse(localStorage.getItem('tips'))
      let pay_later_busket = {
        'busket':mergedBuskets,
        'tips':tips
      }
      const dataBody ={
        orderId: currentPaylaterOrder['order_id'],
        ...getCompleteInvoice(),
        tips: formatPrice(tips),
        discounts_products: productTotal['discountedTotalPrice']>0?(productTotal['result'] - productTotal['discountedTotalPrice']):0,
        // discount_using_promo: discountAmount,
        // promo_code_applied:promoCodeDiscount,
        pay_later_busket:JSON.stringify(pay_later_busket),
        req_mode:'update'
      }
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/order/create/paylater_order`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataBody),
      })
        .then((res) => res.json())
        .then((data) => {
          if(data['orderId']){

            navigate('/food-ordering/thankYou', {
              state: {
                location: `${location?.LocationId}`,
                pay_method:'pay_later',
                pay_later_status:'updated'
              },
            })

          }else{
            throw Error(`Some error occured`)
          }
          //need changes
        })
        .catch((err) => {
          navigate('/wrong')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }else{
      navigate('/food-ordering/finish', {
        state: {
          location: `${location?.LocationId}`,
        },
      })
    }
  }

  useEffect(() => {
    if (clientId) {
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}/setItemUnavailable/${clientId}`)
        .then((res) => res.json())
        .then((data) => {
          const sortFalseFirst = data?.locations
            ? data?.locations?.sort(
                (a, b) => Number(a.Available) - Number(b.Available)
              )
            : []
          setIsItemUnavailableData(sortFalseFirst)
          setIsLoading(false)
        })
    }
  }, [clientId])

  useEffect(() => {
    if (randomItems && isItemUnavailableData) {
      const updatedItems = randomItems.map((item) => {
        const matchItem = isItemUnavailableData.find(
          (unavailableItem) =>
            unavailableItem.ItemID === item.ID ||
            (unavailableItem.Available === false &&
              unavailableItem.ItemID === item.ParentID)
        )
        if (matchItem) {
          setIsLoading(false)
          return {
            ...item,
            Available: matchItem.Available,
          }
        }
        setIsLoading(false)
        return item
      })
      setAddableItemsData(updatedItems)
    }
  }, [isItemUnavailableData, randomItems])

  const addableItems = addableItemsData.filter((x) => {
    return (
      x.Available !== false &&
      !busket.some((product) => product.item.ID === x.ID)
    )
  })

  const handleBasketItemClick = (e, baskedId, item, selectedMdfrs) => {
    e.stopPropagation()
    setSelectedModifiers(selectedMdfrs)
    setProduct({ baskedId, item })
    setIsUpdatingBasket(true)
  }

  const handleEditModalClose = () => {
    setProduct(null)
    setIsUpdatingBasket(true)
    setTimeout(() => {
      setIsUpdatingBasket(false)
    }, 200)
  }

  const getProductName = (product, language) => {
    const key = `Name${language}`;
    return product[key] || product.Name;
  };


  const getDesc = (product, language) => {
    const key = `Description${language}`;
    return product[key] || product.Description;
  }


  const [customerAvailabilityStatus, setCustomerAvailabilityStatus] =
    useState(true)

  return (
    <>
      <Seo name={locationName} />
      <div className="p-[10px]">
        {!isUpdatingBasket
          ? busket.map((x) => {
              if (x.locationId === location.LocationId) {
                return (
                  <BusketBox
                    key={x.id}
                    item={x.item}
                    qty={x.qty}
                    onClick={(e) =>
                      handleBasketItemClick(e, x.id, x.item, x.modifiers)
                    }
                    modifiers={x.modifiers}
                  />
                )
              }
            })
          : null}
        <div className="flex justify-center flex-col">
          <Button
            variant="outline"
            className="w-full mx-auto mt-[20px] mb-[30px] font-medium"
            colorCode={colorCode}
            text={t('product.next')}
            disabled={isLoading || isProductSyncing}
            onClick={handleOrderNextClick}
          />
          <p className="text-[18px] font-medium">
            {/* Completează comanda cu: */}
            {t('product.complete_order_with')}
          </p>
          {addableItems.length > 2 ? (
            <Slider {...settings_bucket} className="mb-[20px]">
              {addableItems.map((item, idx) => (
                <RandomAddItem
                  key={`${idx}_main`}
                  // categoryName={item.Name}
                  categoryName={getProductName(item, selectedLanguage)}
                  product={item}
                  modifiers={item.Modifiers}
                />
              ))}
            </Slider>
          ) : (
            <div className="flex items-center justify-center">
              {addableItems.map((item, idx) => (
                <RandomAddItem
                  className="w-[150px] h-[150px]"
                  key={`${idx}_main`}
                  categoryName={getProductName(item, selectedLanguage)}
                  product={item}
                  modifiers={item.Modifiers}
                />
              ))}
            </div>
          )}
          {comments ? (
            <>
              <p className="text-[18px] font-normal">
                {t('product.mention_placeholder')}
              </p>
              <TextArea
                className="w-full mt-[20px] min-h-[100px]"
                value={comments}
                disabled
              />
            </>
          ) : null}
        </div>
        <OrderSummary />
      </div>
      {product?.item && (
        <ProductDialog
          show={!!product}
          setShow={handleEditModalClose}
          product={product.item}
          basketId={product.baskedId}
          selectedModifiers={selectedModifiers}
          fromFoodOrdering
          qty={product}
          customerAvailabilityStatus={customerAvailabilityStatus}
        />
      )}
    </>
  )
}

export default FoodOrder
