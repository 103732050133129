import { Link } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import Expander from '../../common/Expander'
import { useTranslation } from 'react-i18next'
import FooterSVG from '../../common/FooterSVG'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = ({ showSalad, colorCode, buttonTextColorCode }) => {
  const { t } = useTranslation()

  return (
    <div
      className="relative z-0 w-full pb-[100px] pt-[115px] md:pt-[90px] lg:pt-[190px] mt-[65px] md:mt-[90px]"
      style={{
        backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(
          ReactDOMServer.renderToString(<FooterSVG fill={colorCode} />)
        )}")`,
        backgroundSize: 'cover',
      }}
    >
      <LazyLoadImage
        src="/assets/images/bgmask.png"
        alt="bg mask"
        className="w-full h-full absolute top-0 left-0 object-cover opacity-[0.12] z-0 select-none pointer-events-none"
        effect="opacity"
      />
      {showSalad && (
        <LazyLoadImage
          src="/assets/images/footer_img01.png"
          alt="footer img"
          className="absolute xl:-top-[150px] -top-[100px] xl:-left-[300px] -left-[150px] xl:w-[600px] w-[300px] pointer-events-none"
          effect="opacity"
        />
      )}
      <div className="container" style={{ backgroundColor: colorCode, color: buttonTextColorCode }}>
        <div className="relative z-10 flex flex-col items-center lg:items-start lg:flex-row w-full justify-between pb-[60px] md:pb-[40px]">
          <div className="w-full flex-shrink-0 lg:w-1/4 flex flex-col items-center lg:items-start gap-[30px] lg:gap-[25px] max-w-[375px] lg:max-w-[165px]">
            <div className="flex justify-center max-w-[160px] md:max-w-auto">
              <a
                href="https://www.adhocorder.ro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  src="/assets/images/logo_dark.png"
                  className=""
                  alt="adhoc dark text"
                  effect="opacity"
                />
              </a>
            </div>
            <div className="flex items-center md:items-start md:flex-wrap justify-center gap-[15px] mb-[50px] md:mb-0">
              <div className="flex justify-center h-[45px] lg:h-auto">
                <Link to="https://anpc.ro/ce-este-sal/" target="_blank">
                  <LazyLoadImage
                    src="/assets/images/anpc_alter.png"
                    className="w-full h-auto"
                    alt=""
                    effect="opacity"
                  />
                </Link>
              </div>
              <div className="flex justify-center h-[55px] lg:h-auto">
                <Link
                  target="_blank"
                  to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                >
                  <LazyLoadImage
                    src="/assets/images/anpc_online.png"
                    className="w-full h-auto"
                    alt=""
                    effect="opacity"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex gap-[25px] md:gap-[35px] justify-end">
            <div className="w-full md:w-1/4 lg:flex hidden justify-center">
              <div className="w-[fit-content] flex flex-col gap-[10px]">
                <h4 className="text-[18px] font-semibold">
                  {t('footer.info')}
                </h4>
                <Link>{t('footer.become')}</Link>
                <Link>{t('footer.career')}</Link>
              </div>
            </div>
            <div className="w-full md:w-1/4 lg:flex hidden justify-center">
              <div className="w-[fit-content] flex flex-col gap-[10px]">
                <h4 className="text-[18px] font-semibold">
                  {t('footer.general')}
                </h4>
                <Link to="/terms-and-conditions">{t('footer.terms')}</Link>
                <Link to="/privacy-policy">{t('footer.privacy')}</Link>
              </div>
            </div>
            <div className="footer-links w-full md:w-1/4 lg:flex hidden justify-center">
              <div className="w-[fit-content] flex flex-col gap-[10px]">
                <Expander title={t('footer.links')}>
                  <Link
                    target="_blank"
                    to="https://www.calculator-nutritional.ro/"
                  >
                    {t('footer.link_nutrition')}
                  </Link>
                  <Link target="_blank" to="https://www.pyn.ro/digital-menu">
                    {t('footer.link_menu_digital')}
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.gloriafood.com/ro/cod-qr-meniu-digital"
                  >
                    {t('footer.link_menu_restaurant')}
                  </Link>
                  <Link target="_blank" to="https://savor.land/">
                    {t('footer.link_menu_hotel')}
                  </Link>
                  <Link target="_blank" to="https://www.eazy.plus/">
                    {t('footer.link_menu_digital_qr')}
                  </Link>
                  <Link target="_blank" to="https://qr.q-web.ro/">
                    {t('footer.link_menu_qr')}
                  </Link>
                  <Link target="_blank" to="https://www.qr-code-generator.com/">
                    {t('footer.link_menu_qr_code')}
                  </Link>
                  <Link
                    target="_blank"
                    to="https://www.gloriafood.com/ro/cod-qr-meniu-digital"
                  >
                    {t('footer.link_menu_restaurant_qr')}
                  </Link>
                </Expander>
              </div>
            </div>
            <div className="w-full md:w-1/4 lg:flex hidden justify-center">
              <div className="w-[fit-content] flex flex-col gap-[10px]">
                <h4 className="text-[18px] font-semibold">
                  {t('footer.contact')}
                </h4>
                <Link to="/help-and-support">{t('footer.help')}</Link>
                <Link to="/help-and-support">{t('footer.support')}</Link>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col w-full gap-[20px]">
            <Expander title={t('footer.info')}>
              <Link>{t('footer.become')}</Link>
              <Link>{t('footer.career')}</Link>
            </Expander>
            <Expander title={t('footer.general')}>
              <Link to="/terms-and-conditions">{t('footer.terms')}</Link>
              <Link to="/privacy-policy">{t('footer.privacy')}</Link>
            </Expander>
            <Expander title={t('footer.links')}>
              <Link target="_blank" to="https://www.calculator-nutritional.ro/">
                {t('footer.link_nutrition')}
              </Link>
              <Link target="_blank" to="https://www.pyn.ro/digital-menu">
                {t('footer.link_menu_digital')}
              </Link>
              <Link
                target="_blank"
                to="https://www.gloriafood.com/ro/cod-qr-meniu-digital"
              >
                {t('footer.link_menu_restaurant')}
              </Link>
              <Link target="_blank" to="https://savor.land/">
                {t('footer.link_menu_hotel')}
              </Link>
              <Link target="_blank" to="https://www.eazy.plus/">
                {t('footer.link_menu_digital_qr')}
              </Link>
              <Link target="_blank" to="https://qr.q-web.ro/">
                {t('footer.link_menu_qr')}
              </Link>
              <Link target="_blank" to="https://www.qr-code-generator.com/">
                {t('footer.link_menu_qr_code')}
              </Link>
              <Link
                target="_blank"
                to="https://www.gloriafood.com/ro/cod-qr-meniu-digital"
              >
                {t('footer.link_menu_restaurant_qr')}
              </Link>
            </Expander>
            <Expander title={t('footer.contact')}>
              <Link to="/help-and-support">{t('footer.help')}</Link>
              <Link to="/help-and-support">{t('footer.support')}</Link>
            </Expander>
          </div>
        </div>
        <div className="border-black lg:border-t-[1px] border-t-[0px] m-auto text-center lg:pt-[25px]">
          {t('footer.rights')}
        </div>
      </div>
    </div>
  )
}

export default Footer
