import { useEffect, useId, useState } from 'react'
import { Modal } from '../../../components/Modal/Modal'
import Button from '../../../common/Button'
import { Stars } from './Stars'
import { CommentBox } from './CommentBox'
import { TextInput } from '../../../common'

export const AddReviewModal = ({
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  colorCode,
}) => {
  const [rating, setRationg] = useState(0)
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')
  const emailInputId = useId()

  const handleSubmit = () => {
    onSubmit({ rating, comment, email })
  }

  useEffect(() => {
    if (!isOpen) {
      setRationg(0)
      setComment('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="reviews">
      <div className="flex flex-col gap-6">
        <div>
          <h3 className="text-lg font-medium"></h3>
          <Stars
            value={rating}
            onChange={setRationg}
            isLargeSize
            className="mx-auto pt-2 justify-center"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor={emailInputId}>Email</label>
          <TextInput type="email" onChange={(e) => setEmail(e.target.value)} />
        </div>
        <CommentBox value={comment} onChange={setComment} />
      </div>
      <Button
        disabled={!rating || isSubmitting}
        text="Trimite"
        colorCode={colorCode}
        className="w-full mt-4"
        onClick={handleSubmit}
      />
    </Modal>
  )
}
