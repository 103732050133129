import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BiChevronUp } from 'react-icons/bi'

const ScrollToTop = (props) => {
  const { pathname } = useLocation()
  const [visible, setVisible] = useState(false)
  const colorCode = JSON.parse(window.localStorage.getItem("colorCode")) ?? '#FFB800'
  const buttonTextColorCode = JSON.parse(window.localStorage.getItem("buttonTextColorCode")) ?? '#FFFFFF'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY
      const visible = currentScrollPos > 150
      setVisible(visible)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {props.children}
      {visible && (
        <span
          className="shadow-lg fixed cursor-pointer bottom-5 right-5 w-10 h-10 rounded-md flex items-center justify-center"
          style={{ backgroundColor: colorCode, color: buttonTextColorCode }}
          onClick={handleClick}
        >
          <BiChevronUp className={`w-[25px] h-[25px] ${buttonTextColorCode !== "#FFFFFF" ? "opacity-25" : ""}`} />
        </span>
      )}
    </>
  )
}

export default ScrollToTop
