import React, { useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { CSSTransition } from 'react-transition-group'
import '../styles/Dropdown.css'

const Dropdown = ({ title, content, iconColor }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={`flex flex-col border-b-[1px] border-solid border-[#FFB800] p-4 pb-0 w-full ${
        isOpen ? 'border-[1px] rounded-[5px]' : ''
      }`}
    >
      <div
        className={`flex justify-between items-center cursor-pointer w-full pb-[10px] md:pb-[15px] ${
          isOpen ? 'border-b-2 border-white' : ''
        }`}
        onClick={toggleDropdown}
      >
        <h3 className="text-[12px] md:text-[18px] font-semibold">{title}</h3>
        {isOpen ? (
          <RiArrowDropUpLine color={!iconColor ? '#FFB800' : ''} size={35} />
        ) : (
          <RiArrowDropDownLine color={!iconColor ? '#FFB800' : ''} size={35} />
        )}
      </div>
      <CSSTransition classNames="slide" timeout={200} in={isOpen} unmountOnExit>
        <div className="font-normal pt-[15px] pb-[15px]">{content}</div>
      </CSSTransition>
    </div>
  )
}

export default Dropdown
