import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Values = () => {
  const { t } = useTranslation()
  return (
    <div className="relative">
      <div className="text-center my-[75px] md:my-[100px]">
        <div className="container">
          <h2 className="uppercase md:text-[60px] text-[40px] font-bold">
            {t('homepage.values_title')}
          </h2>
          <div className="grid grid-cols-2 gap-x-[20px] gap-y-[70px] xl:grid-cols-4 xl:gap-[30px] mt-[100px]">
            <div className="relative bg-white rounded-[10px] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] h-full">
              <div className="absolute -top-[35px] flex justify-center w-full">
                <div className="bg-primary rounded-full w-[65px] h-[65px] p-[15px] md:w-[100px] md:h-[100px] md:p-[25px]">
                  <LazyLoadImage
                    src="/assets/images/value01.png"
                    alt={t('homepage.values_card1_title')}
                    effect="opacity"
                  />
                </div>
              </div>
              <div className="p-[20px] pb-[40px] pt-[50px] md:p-[30px] md:pt-[80px] h-full">
                <h3 className="text-[15px] mb-[11px] md:text-[20px] font-semibold md:mb-[35px] mx-auto">
                  {t('homepage.values_card1_title')}
                </h3>
                <p className="text-[13px] md:text-[15px]">
                  {t('homepage.values_card1_text')}
                </p>
              </div>
            </div>
            <div className="relative bg-white rounded-[10px] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] h-full">
              <div className="absolute -top-[35px] flex justify-center w-full">
                <div className="bg-primary rounded-full w-[65px] h-[65px] p-[15px] md:w-[100px] md:h-[100px] md:p-[25px]">
                  <LazyLoadImage
                    src="/assets/images/value02.png"
                    alt={t('homepage.values_card2_title')}
                    effect="opacity"
                  />
                </div>
              </div>
              <div className="p-[20px] pb-[40px] pt-[50px] md:p-[30px] md:pt-[80px] h-full">
                <h3 className="text-[15px] mb-[11px] md:text-[20px] font-semibold md:mb-[35px] mx-auto">
                  {t('homepage.values_card2_title')}
                </h3>
                <p className="text-[13px] md:text-[15px]">
                  {t('homepage.values_card2_text')}
                </p>
              </div>
            </div>
            <div className="relative bg-white rounded-[10px] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] h-full">
              <div className="absolute -top-[35px] flex justify-center w-full">
                <div className="bg-primary rounded-full w-[65px] h-[65px] p-[15px] md:w-[100px] md:h-[100px] md:p-[25px]">
                  <LazyLoadImage
                    src="/assets/images/value03.png"
                    alt={t('homepage.values_card3_title')}
                    effect="opacity"
                  />
                </div>
              </div>
              <div className="p-[20px] pb-[40px] pt-[50px] md:p-[30px] md:pt-[80px] h-full">
                <h3 className="text-[15px] mb-[11px] md:text-[20px] font-semibold md:mb-[35px] mx-auto">
                  {t('homepage.values_card3_title')}
                </h3>
                <p className="text-[13px] md:text-[15px]">
                  {t('homepage.values_card3_text')}
                </p>
              </div>
            </div>
            <div className="relative bg-white rounded-[10px] shadow-[0_15px_50px_0_rgba(0,0,0,0.09)] h-full">
              <div className="absolute -top-[35px] flex justify-center w-full">
                <div className="bg-primary rounded-full w-[65px] h-[65px] p-[15px] md:w-[100px] md:h-[100px] md:p-[25px]">
                  <LazyLoadImage
                    src="/assets/images/value04.png"
                    alt={t('homepage.values_card4_title')}
                    effect="opacity"
                  />
                </div>
              </div>
              <div className="p-[20px] pb-[40px] pt-[50px] md:p-[30px] md:pt-[80px] h-full">
                <h3 className="text-[15px] mb-[11px] md:text-[20px] font-semibold md:mb-[35px] mx-auto">
                  {t('homepage.values_card4_title')}
                </h3>
                <p className="text-[13px] md:text-[15px]">
                  {t('homepage.values_card4_text')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-bg absolute md:bottom-[150px] bottom-auto md:top-auto -top-[100px] md:-left-[450px] -left-[200px] md:w-[650px] md:h-[450px] w-[300px] h-[200px] flex justify-end">
        <LazyLoadImage
          className="h-full"
          src="/assets/images/value_extra.png"
          alt=""
          effect="opacity"
        />
      </div>
    </div>
  )
}

export default Values
