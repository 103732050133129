import { RxCross2 } from 'react-icons/rx'
import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import RectangleButton from '../../common/RectangleButton'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'

const initialFormState = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  role: '',
  company_name: '',
  unique_code: '',
  no_regist: '',
  location: '',
  restaurant: '',
  partner_type: '',
  file: null,
}

const LandingPopupMenu = ({ setShowPopup, active }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(initialFormState)
  const [errors, setErrors] = useState({})
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [fileInclude, setFileInclude] = useState(false)
  const acceptedFormats = {
    'application/zip': ['.zip'],
    'application/x-rar-compressed': ['.rar'],
    'application/gzip': ['.gz'],
    'application/x-7z-compressed': ['.7z'],
    'application/x-tar': ['.tar'],
  }

  const emailPattern = /^[^ ]+@[^]+\.[a-z]{2,3}$/
  const phonePattern = /^\+?[0-9]{10,13}$/

  const onDrop = useCallback((acceptedFiles) => {
    const droppedFile = acceptedFiles[0]
    setState((prev) => ({ ...prev, file: droppedFile }))
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFormats,
    noClick: true,
    noKeyboard: true,
  })

  const handleChangeAcceptTerms = (event) => {
    setAcceptTerms((current) => !current)
  }

  const validateForm = () => {
    const validationErrors = {}

    if (!state.first_name)
      validationErrors.first_name = t('errors.firstNameMandatory')

    if (!state.last_name)
      validationErrors.last_name = t('errors.lastNameMandatory')

    if (!state.phone) {
      validationErrors.phone = t('errors.phoneMandatory')
    } else if (!phonePattern.test(state.phone)) {
      validationErrors.phone = t('errors.phoneInvalid')
    }

    if (!state.email) {
      validationErrors.email = t('errors.emailMandatory')
    } else if (!emailPattern.test(state.email)) {
      validationErrors.email = t('errors.emailInvalid')
    }

    if (!state.role) validationErrors.role = t('errors.roleMandatory')

    if (!state.company_name)
      validationErrors.company_name = t('errors.companyMandatory')

    if (!state.unique_code)
      validationErrors.unique_code = t('errors.uniqueCodeMandatory')

    if (!state.no_regist)
      validationErrors.no_regist = t('errors.registrationMandatory')

    if (!state.location)
      validationErrors.location = t('errors.locationMandatory')

    if (!state.restaurant)
      validationErrors.restaurant = t('errors.restaurantMandatory')

    if (!state.partner_type)
      validationErrors.partner_type = t('errors.partnerTypeMandatory')

    if (!state.file && fileInclude) {
      validationErrors.file = t('errors.fileMandatory')
    } else if (state.file && fileInclude && state.file.size > 5 * 1024 * 1024) {
      validationErrors.file = t('errors.fileSizeMandatory')
    }

    if (!acceptTerms) validationErrors.acceptTerms = t('errors.termsMandatory')

    setErrors(validationErrors)

    return Object.keys(validationErrors).length === 0
  }

  const handleUpdate = (e) => {
    if (e.target.name === 'file') {
      setState((prv) => ({
        ...prv,
        [e.target.name]: e.target.files[0],
      }))
    } else {
      setState((prv) => ({
        ...prv,
        [e.target.name]: e.target.value,
      }))
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: undefined,
    }))
  }

  const handleSubmit = () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: t('errors.mustAllMandatory'),
      }))
    } else {
      const successMessage = 'Successfully sent'

      const handleSuccess = () => {
        toast.success(successMessage, {
          position: 'bottom-right',
        })

        setState(initialFormState)
        setFileInclude(false)
        setAcceptTerms(false)
        setShowPopup(false)
        setErrors({})
      }

      const handleError = () => {
        toast.error('Error occurred!', {
          position: 'bottom-right',
        })
      }

      const formData = new FormData()
      Object.entries(state).forEach(([key, value]) => {
        formData.append(key, value)
      })

      fetch(`${process.env.REACT_APP_API_URL}/become-partner`, {
        method: 'POST',
        body: formData,
      })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${
        active
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      }`}
      onClick={() => setShowPopup(false)}
    >
      <div
        className="absolute sm:w-[630px] w-full top-0 right-0 bg-white shadow-lg border p-[30px] md:p-[65px] md:pr-[95px] flex flex-col "
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute sm:hidden top-[20px] right-[20px] shadow transition duration-300 ease-in-out hover:bg-gray-100 hover:shadow-lg rounded-[50%] p-[10px]"
          onClick={() => setShowPopup(false)}
        >
          <RxCross2 />
        </button>
        <h3 className="uppercase text-[40px] not-italic font-bold leading-[45px] mb-[25px]">
          {t('register.title')}
        </h3>
        <h5 className="uppercase font-bold text-[1.2rem]">
          {t('register.contact_info')}
        </h5>
        <div
          className="border-b text-start"
          style={{ borderColor: errors.first_name ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.first_name')}
            className="outline-none py-3 w-full"
            name="first_name"
            value={state.first_name}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.last_name ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.last_name')}
            className="outline-none py-3 w-full"
            name="last_name"
            value={state.last_name}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.phone ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.phone_number')}
            className="outline-none py-3 w-full"
            name="phone"
            value={state.phone}
            onChange={handleUpdate}
          />
        </div>
        {errors.phone === t('errors.phoneInvalid') && (
          <p className="text-red-500 font-bold mt-1">{errors.phone}</p>
        )}
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.role ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.role')}
            className="outline-none py-3 w-full"
            name="role"
            value={state.role}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.email ? '#DC143C' : '#FFB800' }}
        >
          <input
            type="email"
            placeholder={t('register.email')}
            className="outline-none py-3 w-full"
            name="email"
            value={state.email}
            onChange={handleUpdate}
          />
        </div>
        {errors.email === t('errors.emailInvalid') && (
          <p className="text-red-500 font-bold mt-1">{errors.email}</p>
        )}
        <h5 className="uppercase font-bold text-[1.2rem] mt-[2rem]">
          {t('register.company_info')}
        </h5>
        <div className="flex gap-3">
          <div
            className="border-b text-start mt-[10px]"
            style={{ borderColor: errors.company_name ? '#DC143C' : '#FFB800' }}
          >
            <input
              placeholder={t('register.company_name')}
              className="outline-none py-3 w-full"
              name="company_name"
              value={state.company_name}
              onChange={handleUpdate}
            />
          </div>
          <div
            className="border-b text-start mt-[10px]"
            style={{ borderColor: errors.unique_code ? '#DC143C' : '#FFB800' }}
          >
            <input
              placeholder={t('register.unique_code')}
              className="outline-none py-3 w-full"
              name="unique_code"
              value={state.unique_code}
              onChange={handleUpdate}
            />
          </div>
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.no_regist ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.reg_number')}
            className="outline-none py-3 w-full"
            name="no_regist"
            value={state.no_regist}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.restaurant ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.res_name')}
            className="outline-none py-3 w-full"
            name="restaurant"
            value={state.restaurant}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.location ? '#DC143C' : '#FFB800' }}
        >
          <input
            placeholder={t('register.res_location')}
            className="outline-none py-3 w-full"
            name="location"
            value={state.location}
            onChange={handleUpdate}
          />
        </div>
        <div
          className="border-b text-start mt-[10px]"
          style={{ borderColor: errors.partner_type ? '#DC143C' : '#FFB800' }}
        >
          <select
            type="select"
            name="partner_type"
            defaultValue={state.partner_type}
            onChange={handleUpdate}
            className="outline-none py-3 w-full custom-select"
          >
            <option value="" disabled>
              {t('register.partner_type')}
            </option>
            <option value="Bronze">Bronze</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
          </select>
        </div>
        <div className="flex gap-[35px] mt-[25px] mb-[20px]">
          <p>{t('register.partner_menu')}</p>
          <div className="flex items-center gap-3">
            <div className="form-radio">
              <input
                type="radio"
                name="file_include"
                id="yes-file"
                value="yes"
                checked={fileInclude}
                onChange={() => setFileInclude(true)}
              />
              <label htmlFor="yes-file">Da</label>
            </div>
            <div className="form-radio">
              <input
                type="radio"
                name="file_include"
                id="no-file"
                value="no"
                checked={!fileInclude}
                onChange={() => setFileInclude(false)}
              />
              <label htmlFor="no-file">Nu</label>
            </div>
          </div>
        </div>
        {fileInclude && (
          <div
            {...getRootProps()}
            className="border-[1px] border-[#D9D9D9] rounded-[10px] py-[37px] justify-center flex-col items-center gap-[8px] flex"
          >
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>{state.file ? state.file.name : t('register.upload_file')}</p>
            )}
            <span className="block text-[#26323885]">sau</span>
            <div className="choose-file">
              <label htmlFor="file">Choose a file</label>
              <input
                id="file"
                type="file"
                name="file"
                onChange={handleUpdate}
              />
            </div>
          </div>
        )}
        {(errors.file === t('errors.fileMandatory') ||
          errors.file === t('errors.fileSizeMandatory')) && (
          <p className="text-red-500 font-bold mt-1">{errors.file}</p>
        )}
        <div className="w-full py-3 text-start flex items-center gap-3 mt-[30px]">
          <input
            checked={acceptTerms}
            onChange={handleChangeAcceptTerms}
            type="checkbox"
            className="w-[30px] h-[20px] shrink-0"
            id="reg-terms"
          />
          <label
            htmlFor="reg-terms"
            className="text-base not-italic font-normal"
          >
            {t('homepage.contactus_agree')}{' '}
            <Link
              target="_blank"
              to="/terms-and-conditions"
              className="text-[#487ADA] underline cursor-pointer"
            >
              {t('footer.terms')}
            </Link>{' '}
            precum și{' '}
            <Link
              target="_blank"
              to="/privacy-policy"
              className="text-[#487ADA] underline cursor-pointer"
            >
              {t('footer.privacy')}
            </Link>
          </label>
        </div>
        {errors.acceptTerms && (
          <p className="text-red-500 font-bold mt-1">{errors.acceptTerms}</p>
        )}
        <div className="mt-[20px]">
          <RectangleButton text="Submit" onClick={handleSubmit} />
          {errors.general && (
            <p className="text-red-600 font-bold uppercase mt-4">
              {errors.general}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default LandingPopupMenu
