import { useTranslation } from 'react-i18next'
import useViewportWidth from '../../hooks/useViewportWidth'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const TryMe = () => {
  const { t } = useTranslation()
  const viewportWidth = useViewportWidth()

  return (
    <div className="relative overflow-x-clip mt-[120px]">
      <LazyLoadImage
        src="/assets/images/choose_img1.png"
        alt=""
        className="absolute md:-top-[200px] -top-[175px] md:-right-[250px] -right-[150px] lg:w-[600px] w-[300px] md:w-[350px]"
        effect="opacity"
      />
      <div className="container">
        <div className="flex flex-col md:flex-row">
          <div
            className={`${
              viewportWidth < 640 ? 'hidden' : 'flex'
            } w-[240px] md:w-1/2 lg:flex-row justify-center md:order-1 order-2 mt-[45px] md:mt-0 mx-auto md:mx-0`}
          >
            <LazyLoadImage
              src="/assets/images/iPhone_12_Pro.svg"
              alt="trymePhone"
              effect="opacity"
            />
          </div>
          <div className="md:w-1/2 w-full flex flex-col items-center justify-center gap-[28px] md:order-2 order-1">
            <div className="max-w-[460px] text-center">
              <h2 className="uppercase md:text-[60px] text-[40px] font-bold font-[Poppins] leading-[75px] not-italic">
                {t('homepage.try_title')}
              </h2>
              <p className="text-[#464646] text-center font-[Poppins] md:text-base not-italic font-normal md:leading-[27px] text-sm  leading-[24px]">
                {t('homepage.try_text')}
              </p>
              <div className="w-[273px] h-[268px] flex-shrink-0 mx-auto">
                <LazyLoadImage
                  src="/assets/images/qr-code_prod.png"
                  alt="QR code"
                  className="w-full h-full"
                  effect="opacity"
                />
              </div>
              <div className="font-[Poppins] md:text-base not-italic font-normal md:leading-[27px] text-sm  leading-[24px]">
                <p className="text-[#464646] md:pr-1">
                  {t('homepage.try_subtext')}
                  <a
                    href="https://adhocorder.ro/product/3e4e2875-73e8-4729-ae9d-799222024b30?9d4c293d-0d90-4c7e-a13c-5cb6222832fc"
                    className="text-[#FFB800] ml-[5px] block md:inline-block"
                  >
                    {t('homepage.try_link')}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TryMe
