import React from 'react'

const Button = ({
  className,
  text,
  variant = 'solid',
  icon,
  gapBtwnTextAndIcon = "gap-[10px]",
  onClick,
  disabled = false,
  colorCode = '#FFB800',
  style,
  textColor = '#FFFFFF',
  onMouseEnter,
  onMouseLeave
}) => {
  let classNames = ''
  let disabledStyles = disabled ? 'opacity-50 pointer-events-none' : ''

  switch (variant) {
    case 'solid':
      classNames = `border-[2px] border-[#FFB800] font-extrabold py-2 px-6 rounded-[50vh] transition duration-300 ease-in-out hover:bg-yellow-600 ${className} ${disabledStyles}`
      break

    case 'outline':
      classNames = `border border-[2px] border-[#FFB800] font-extrabold py-2 px-6 rounded-[50vh] bg-transparent hover:bg-[#FFB800] hover:text-white transition duration-200 ${className} ${disabledStyles}`
      break

    case 'icon':
      classNames = `border-[2px] border-[#FFB800] font-extrabold py-2 px-6 rounded-[50vh] transition duration-300 ease-in-out ${className} ${disabledStyles}`
      break

    default:

      classNames = `font-extrabold py-2 px-6 rounded-[50vh] transition duration-300 ease-in-out hover:bg-yellow-600 ${className} ${disabledStyles}`

  }

  return (
    <button
      className={classNames}
      onClick={disabled ? null : onClick}
      disabled={disabled}
      style={style ? style : { backgroundColor: colorCode, borderColor: colorCode, color: textColor }}
      onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
    >
      {variant === 'icon' && icon ? (
        <span className={"flex items-center justify-center " + gapBtwnTextAndIcon}>
          {icon} {text}
        </span>
      ) : (
        text
      )}
    </button>
  )
}

export default Button
