import { useTranslation } from 'react-i18next'
import RectangleButton from '../../common/RectangleButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Calculate = ({ handleClick }) => {
  const { t } = useTranslation()
  return (
    <div className="relative mt-[80px] md:mt-[90px] bg-[#3D3D3D] pt-[100px] pb-[60px] md:py-[115px]">
      <LazyLoadImage
        src="/assets/images/calculate_bg.png"
        className="w-full h-full absolute top-0 left-0 opacity-5 object-cover pointer-events-none"
        alt=""
        effect="opacity"
      />
      <LazyLoadImage
        src="/assets/images/calculate_img01.png"
        alt="calculate img"
        className="absolute md:-top-[180px] -top-[70px] -left-[250px] md:-left-[400px] md:w-[620px] w-[350px] "
        effect="opacity"
      />
      <div className="container text-white font-[Poppins]">
        <div className="flex flex-col text-center items-center gap-[30px] md:max-w-[947px] mx-auto">
          <h2 className="md:text-[55px] text-[30px] font-bold md:leading-[80px] not-italic leading-[40px] uppercase w-full">
            {t('homepage.calculate_title')}
          </h2>
          <p className="text-[25px] leading-[30px]">
            {t('homepage.calculate_text')}
          </p>
          <RectangleButton
            size="large"
            text={t('homepage.calculate_btn')}
            onClick={() => handleClick('Calculate')}
          ></RectangleButton>
        </div>
      </div>
    </div>
  )
}

export default Calculate
