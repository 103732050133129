import React, { useContext, useState } from 'react'
import { Button } from '../../common'
import { GlobalContext } from '../../appContext/GlobalContext'
import { useTranslation } from 'react-i18next'
import { FaInstagram } from 'react-icons/fa'
import { FacebookIcon, LinkedinIcon, NotificationIcon, QRIcon } from '../icon'
import { HelpStep2Icon } from '../icon/HelpStep2Icon'
import { HelpStep3Icon } from '../icon/HelpStep3Icon'
import { HelpStep4Icon } from '../icon/HelpStep4Icon'
import { HelpStep7Icon } from '../icon/HelpStep7Icon'
import { HelpStep8Icon } from '../icon/HelpStep8Icon'

const HelpPopup = ({ setIsVisible, isVisible, location }) => {
  const totalPages = (location?.help_instructions_pages).split(',').map(Number)
  const totalPagesLength = totalPages.length
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPage, setCurrentPage] = useState(totalPages[currentIndex])
  const { colorCode } = useContext(GlobalContext)
  const { t } = useTranslation()

  const handleNext = () => {
    const nextIndex = currentIndex + 1
    if (nextIndex < totalPagesLength) {
      setCurrentIndex(nextIndex)
      setCurrentPage(totalPages[nextIndex])
    } else {
      setIsVisible(false)
    }
  }
  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-[#00000091] overflow-auto transition-all ${
        isVisible
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      }`}
      onClick={() => setIsVisible(false)}
    >
      <div
        className="absolute sm:w-[630px] w-[95%] top-[50%] left-[50%] bg-white shadow-lg border flex flex-col rounded-[10px] px-[16px] pb-[32px] "
        onClick={(e) => e.stopPropagation()}
        style={{ transform: 'translate(-50%,-35%)', zIndex: '999' }}
      >
        <span className="text-[14px] pl-[6px] pt-[8px]">
          {currentIndex + 1}/{totalPagesLength}
        </span>
        {currentPage === 1 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <QRIcon fill={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_1_title')}
            </p>
            <p>{t('helpPopUp.step_1_text')}</p>
          </div>
        )}
        {currentPage === 2 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <HelpStep2Icon stroke={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_2_title')}
            </p>
            <p>{t('helpPopUp.step_2_text')}</p>
          </div>
        )}
        {currentPage === 3 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <HelpStep3Icon fill={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_3_title')}
            </p>
            <p>{t('helpPopUp.step_3_text')}</p>
          </div>
        )}
        {currentPage === 4 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <HelpStep4Icon fill={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_4_title')}
            </p>
            <p>{t('helpPopUp.step_4_text')}</p>
          </div>
        )}
        {currentPage === 5 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <NotificationIcon />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_5_title')}
            </p>
            <p>{t('helpPopUp.step_5_text')}</p>
          </div>
        )}
        {currentPage === 6 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <HelpStep8Icon fill={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_6_title')}
            </p>
            <p>{t('helpPopUp.step_6_text')}</p>
          </div>
        )}
        {currentPage === 7 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <div className=" flex justify-center ">
              <HelpStep7Icon stroke={colorCode} />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_7_title')}
            </p>
            <p>{t('helpPopUp.step_7_text')}</p>
          </div>
        )}
        {currentPage === 8 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            {/* <div className=" flex justify-center ">
              <HelpStep8Icon fill={colorCode} />
            </div> */}

            <div className=" flex justify-center">
              <img
                alt="Stripe Logo"
                src="/assets/images/Google_Pay_Logo.svg"
                className="w-[66px] h-[32px]"
              />
              <img
                alt="Stripe Logo"
                src="/assets/images/apple-pay-logo.svg"
                className="w-[66px] h-[32px]"
              />
            </div>
            <p className="text-[18px] font-semibold">
              {t('helpPopUp.step_8_title')}
            </p>
            <p>{t('helpPopUp.step_8_text')}</p>
          </div>
        )}
        {currentPage === 9 && (
          <div className="text-center flex gap-2 flex-col mt-[8px]">
            <p>{t('helpPopUp.step_9_text')}</p>
            <div className="flex justify-center gap-2 py-3">
              <a
                href="https://www.instagram.com/adhocfasteasy/"
                target="_blank"
              >
                <FaInstagram className={`text-[24px]`} />
              </a>
              <a
                href="https://www.linkedin.com/company/adhocfasteasy/"
                target="_blank"
              >
                <LinkedinIcon />
              </a>
              <a href="https://www.facebook.com/adhocfasteasy/" target="_blank">
                <FacebookIcon />
              </a>
            </div>
            <div className="flex flex-col gap-1">
              <p>{t('helpPopUp.step_9_address_text')}</p>
              <p>
                <a
                  href="https://www.adhocorder.ro/"
                  style={{ color: `${colorCode}` }}
                  target="_blank"
                >
                  {t('helpPopUp.step_9_website_text')}
                </a>
              </p>
            </div>
          </div>
        )}

        {currentIndex + 1 !== totalPagesLength ? (
          <div className="flex justify-around mt-[24px]">
            <Button
              variant="icon"
              text={'Skip'}
              className="font-semibold w-[130px]"
              onClick={() => setIsVisible(false)}
              style={{ backgroundColor: '#ffffff', border: 'none' }}
            />
            <Button
              variant="solid"
              text={'Next'}
              className="font-semibold w-[130px]"
              onClick={handleNext}
              colorCode={colorCode}
            />
          </div>
        ) : (
          <div className="flex justify-center mt-[24px]">
            <Button
              variant="solid"
              text={t('helpPopUp.step_9_enjoy_btn_text')}
              onClick={() => setIsVisible(false)}
              className="font-semibold"
              colorCode={colorCode}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default HelpPopup
