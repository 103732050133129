import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const HowItWorks = () => {
  const { t } = useTranslation()
  return (
    <div className="relative text-center pt-[75px] md:pt-[100px] pb-[50px] md:pb-[80px] overflow-x-hidden px-[40px] md:px-0">
      <div className="absolute -right-[50px] lg:-right-[300px] -top-[20px] lg:-top-[100px] w-[220px] h-[220px] lg:w-[1000px] lg:h-[1000px] pointer-events-none">
        <img
          className="md:w-full md:h-auto"
          src="/assets/images/how01.png"
          alt=""
        />
      </div>
      <div className="container">
        <h2 className="uppercase md:text-[60px] text-[33px] font-bold">
          {t('homepage.how_title')}
        </h2>
        <p className="text-[14px] text-[#464646] leading-[24px] md:text-[16px]">
          {t('homepage.how_text')}
        </p>
        <div className="flex flex-col-reverse md:flex-row items-center mt-[50px]">
          <div className="md:w-1/2 text-left flex-col md:-mb-[150px] md:px-[65px]">
            <h2 className="uppercase md:text-[40px] text-[20px] leading-[30px] not-italic font-semibold md:leading-[50px]">
              {t('homepage.how_card1_title')}
            </h2>
            <p className="text-[12px] leading-[22px] md:text-[16px] md:leading-[25px] text-[#464646] mt-[15px]">
              {t('homepage.how_card1_text')}
            </p>
          </div>
          <div className="md:w-1/2 w-[225px] flex justify-center mb-[30px] md:mb-0 md:pb-[150px]">
            <LazyLoadImage
              src="/assets/images/Group 201.png"
              alt=""
              effect="opacity"
            />
          </div>
        </div>
        <div className="flex justify-center mt-[20px] md:mt-0">
          <picture>
            <source
              srcSet="/assets/images/how-it-arrow-1.png"
              media="(min-width: 767px)"
            />
            <LazyLoadImage
              src="/assets/images/how-it-arrow-1-mob.svg"
              alt=""
              effect="opacity"
            />
          </picture>
        </div>
        <div className="relative flex md:flex-row flex-col items-center mt-[50px]">
          <div className="w-[225px] md:w-1/2 flex justify-center md:pb-[150px] mb-[35px] md:mb-0">
            <LazyLoadImage
              src="/assets/images/Group 145.png"
              alt=""
              effect="opacity"
            />
          </div>
          <div className="w-full md:w-1/2 text-left flex-col flex md:px-[65px] md:-mb-[180px]">
            <h2 className="uppercase md:text-[40px] text-[20px] not-italic font-semibold md:leading-[50px] leading-[30px]">
              {t('homepage.how_card3_title')}
            </h2>
            <p className="mt-[15px] text-[12px] md:text-[16px]">
              {t('homepage.how_card3_text')}
            </p>
          </div>
          <div className="absolute -left-[150px] -top-[50px] md:-left-[550px] xl:-left-[650px] md:-top-[0px] w-[170px] h-[170px] md:w-[600px] md:h-[600px]">
            <LazyLoadImage
              src="/assets/images/value_extra.png"
              alt=""
              effect="opacity"
            />
          </div>
        </div>
        <div className="flex justify-center mt-[20px] md:mt-0">
          <picture>
            <source
              srcSet="/assets/images/how-it-arrow-2.png"
              media="(min-width: 767px)"
            />
            <LazyLoadImage
              src="/assets/images/how-it-arrow-2-mob.svg"
              alt=""
              effect="opacity"
            />
          </picture>
        </div>
        <div className="flex flex-col-reverse md:flex-row items-center mt-[50px]">
          <div className="w-full md:w-1/2 text-left flex-col md:px-[65px]">
            <h2 className="uppercase md:text-[40px] text-[20px] not-italic font-semibold md:leading-[50px] leading-[30px]">
              {t('homepage.how_card3_title')}
            </h2>
            <p className="mt-[15px] text-[12px] md:text-[16px]">
              {t('homepage.how_card3_text')}
            </p>
          </div>
          <div className="w-[225px] md:w-1/2 flex justify-center mb-[35px] md:mb-0">
            <LazyLoadImage
              src="/assets/images/Group 47.png"
              alt=""
              effect="opacity"
            />
          </div>
        </div>
        <div className="flex justify-center mt-[20px] md:mt-0">
          <picture>
            <source
              srcSet="/assets/images/how-it-arrow-1.png"
              media="(min-width: 767px)"
            />
            <LazyLoadImage
              src="/assets/images/how-it-arrow-2-mob.svg"
              alt=""
              effect="opacity"
            />
          </picture>
        </div>
        <div className="relative flex md:flex-row flex-col items-center mt-[50px]">
          <div className="w-[225px] md:w-1/2 flex justify-center mb-[35px] md:mb-0">
            <LazyLoadImage
              src="/assets/images/Group 45.png"
              alt=""
              effect="opacity"
            />
          </div>
          <div className="w-full md:w-1/2 text-left flex-col flex md:px-[65px]">
            <h2 className="uppercase md:text-[40px] text-[20px] not-italic font-semibold md:leading-[50px] leading-[30px]">
              {t('homepage.how_card4_title')}
            </h2>
            <p className="mt-[15px] text-[12px] md:text-[16px]">
              {t('homepage.how_card4_text')}
            </p>
          </div>
          <div className="absolute -right-[150px] md:-right-[550px] -top-[150px] md:top-auto md:bottom-[0px] bottom-auto w-[300px] h-[150px] md:w-[850px] md:h-[450px]">
            <LazyLoadImage
              src="/assets/images/how02.png"
              className="w-full h-full"
              alt=""
              effect="opacity"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
