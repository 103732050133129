import { useTranslation } from "react-i18next"

// hide email for now, if you need to show it just add 'email' key in the array list
const CONTACT_KEYS = ['address', 'phone']
export const LocationDetails = ({ location, className = '' }) => {
  const { t } = useTranslation()
  console.log(location, "lo");
  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <img
          alt="Logo"
          src="/assets/images/icons/general_location.svg"
          className=" flex justify-center "
        />
        <h2 className="font-semibold text-lg pt-2">{location.name}</h2>
      </div>
      <dl className="pt-4 text-base font-normal">
        {Array.isArray(formatOpenHours(location?.openHours)) && (
          <>
            <dt className="font-semibold">{t('categories_and_menu.program')}</dt>
            {formatOpenHours(location?.openHours).map(
              ({ day, openingHour, closingHour, active }) => {
                return (
                  <dd key={day}>
                    {day}: {active ? `${openingHour} - ${closingHour}` : t('restaurant.closed')}
                  </dd>
                )
              }
            )}
          </>
        )}
        <dt className="mt-4 font-semibold">{t('categories_and_menu.contact')}</dt>
        {CONTACT_KEYS.map((key) => {
          return location[key] ? <dd key={key}>{location[key]}</dd> : null
        })}
      </dl>
    </div>
  )
}

const formatOpenHours = (openHours) => {
  if (!Array.isArray(openHours) || openHours.length === 0) return []

  let currentOpenHours = {
    openingHour: openHours[0].openingHour,
    closingHour: openHours[0].closingHour,
    startDay: openHours[0].day,
    endDay: openHours[0].day,
    active: openHours[0].active
  }

  const result = []

  openHours.forEach(({ openingHour, closingHour, day, active }, index) => {
    if (
      openingHour === currentOpenHours.openingHour &&
      closingHour === currentOpenHours.closingHour
    ) {
      currentOpenHours.endDay = day
      currentOpenHours.active = active
    } else {
      result.push({
        day:
          currentOpenHours.startDay === currentOpenHours.endDay
            ? currentOpenHours.startDay
            : `${currentOpenHours.startDay} - ${currentOpenHours.endDay}`,
        openingHour: currentOpenHours.openingHour,
        closingHour: currentOpenHours.closingHour,
        active: currentOpenHours.active
      })

      // Start a new group with the current day
      currentOpenHours = {
        openingHour,
        closingHour,
        startDay: day,
        endDay: day,
        active: active
      }
    }

    // Ensure the last group is added to the result
    if (index === openHours.length - 1) {
      result.push({
        day:
          currentOpenHours.startDay === currentOpenHours.endDay
            ? currentOpenHours.startDay
            : `${currentOpenHours.startDay} - ${currentOpenHours.endDay}`,
        openingHour: currentOpenHours.openingHour,
        closingHour: currentOpenHours.closingHour,
        active: active
      })
    }
  })

  return result
}
