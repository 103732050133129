import { formatReviewDate } from '../../../utils/date'
import { Stars } from './Stars'

export const ReviewItem = ({ item }) => {
  const date = formatReviewDate(item.reviewDate)
  return (
    <div className="p-2.5 border border-[#CBCAC8] rounded-lg">
      {date ? <h3 className="pb-2 text-xs">{date}</h3> : null}
      <Stars value={item.reviewRating} isReadOnly />
      <p className="pt-1 text-base">{item.reviewText}</p>
    </div>
  )
}
