import { Helmet } from 'react-helmet'

const Seo = ({ name }) => {
    const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS
    return (
        <Helmet>
            <title>{`${name} | Meniu QR Code | Meniu Digital | Adhocorder.ro`}</title>
            <meta
                name="description"
                content={`${name} - Deții un restaurant, terasă, mic pub? Du-ți afacerea la un nou nivel utilizând noul meniu digital prin coduri QR. Este rapid și ușor de utilizat!`}
            />
            <meta
                name="keywords"
                content={`${name}, restaurant nou meniu, restaurant digital, exemplu meniu restaurant, qr code restaurant, qr meniu restaurant, cod qr meniu, comanda meniu, scaneaza meniu"`}
            />
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}></script>
            <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${measurementId}');
                `}
            </script>
        </Helmet>
    )
}

export default Seo
