import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { GlobalContext } from '../../appContext/GlobalContext'
import { CloseIcon } from '../icon'
import PopUpHamburgMenu from './PopUpHamburgMenu'
// import { IoCheckmark } from 'react-icons/io5'

const HamburgerInside = ({
  setShowMenu,
  active,
  locationId,
  tableId,
  address,
  colorCode,
}) => {
  const { t, i18n } = useTranslation()

  const [showLang, setShowLang] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)
  const [showAskBill, setShowAskBill] = useState(false)
  const [showCallWaiter, setShowCallWaiter] = useState(false)
  const [langOpen, setLangOpen] = useState(false)
  const [loadingClearTable, setLoadingClearTable] = useState(false)
  // const { setIsEN, isEN, location } = useContext(GlobalContext)
  const { setLanguages, location, selectedLanguage, setSelectedLanguage } = useContext(GlobalContext)

  const availableTranslation = location?.available_translation;

  const availableTranslationArray = availableTranslation?.split(',');
  const languagesData = [
    {
      code: 'AF',
      flag: '/assets/images/af.png',
      i18nCode: 'af',
      label: 'Afrikaans',
    },
    {
      code: 'SQ',
      flag: '/assets/images/sq.png',
      i18nCode: 'sq',
      label: 'Albanian',
    },
    {
      code: 'AM',
      flag: '/assets/images/am.png',
      i18nCode: 'am',
      label: 'Amharic',
    },
    {
      code: 'AR',
      flag: '/assets/images/ar.png',
      i18nCode: 'ar',
      label: 'Arabic',
    },
    {
      code: 'HY',
      flag: '/assets/images/hy.png',
      i18nCode: 'hy',
      label: 'Armenian',
    },
    {
      code: 'AZ',
      flag: '/assets/images/az.png',
      i18nCode: 'az',
      label: 'Azerbaijani',
    },
    {
      code: 'BN',
      flag: '/assets/images/bn.png',
      i18nCode: 'bn',
      label: 'Bengali',
    },
    {
      code: 'BS',
      flag: '/assets/images/bs.png',
      i18nCode: 'bs',
      label: 'Bosnian',
    },
    {
      code: 'BG',
      flag: '/assets/images/bg.png',
      i18nCode: 'bg',
      label: 'Bulgarian',
    },
    {
      code: 'CA',
      flag: '/assets/images/ca.png',
      i18nCode: 'ca',
      label: 'Catalan',
    },
    {
      code: 'ZH',
      flag: '/assets/images/zh.png',
      i18nCode: 'zh',
      label: 'Chinese (Simplified)',
    },
    {
      code: "ZH-TW",
      flag: '/assets/images/zh.png',
      i18nCode: 'zh-TW',
      label: 'Chinese (Traditional)',
    },
    {
      code: 'HR',
      flag: '/assets/images/hr.png',
      i18nCode: 'hr',
      label: 'Croatian',
    },
    {
      code: 'CS',
      flag: '/assets/images/cs.png',
      i18nCode: 'cs',
      label: 'Czech',
    },
    {
      code: 'DA',
      flag: '/assets/images/da.png',
      i18nCode: 'da',
      label: 'Danish',
    },
    {
      code: 'FA-AF',
      flag: '/assets/images/fa-AF.png',
      i18nCode: 'fa-AF',
      label: 'Dari',
    },
    {
      code: 'NL',
      flag: '/assets/images/nl.png',
      i18nCode: 'nl',
      label: 'Dutch',
    },
    {
      code: 'EN',
      flag: '/assets/images/en.png',
      i18nCode: 'en',
      label: 'English',
    },
    {
      code: 'DE',
      flag: '/assets/images/de.png',
      i18nCode: 'de',
      label: 'Deutsch',
    },
    {
      code: 'ET',
      flag: '/assets/images/et.png',
      i18nCode: 'et',
      label: 'Estonian',
    },
    {
      code: 'FA',
      flag: '/assets/images/fa.png',
      i18nCode: 'fa',
      label: "Farsi (Persian)"
    },
    {
      code: 'TL',
      flag: '/assets/images/tl.png',
      i18nCode: 'tl',
      label: 'Filipino, Tagalog',
    },
    {
      code: 'FI',
      flag: '/assets/images/fi.png',
      i18nCode: 'fi',
      label: 'Finnish',
    },
    {
      code: 'FR',
      flag: '/assets/images/fr.png',
      i18nCode: 'fr',
      label: 'Français',
    },
    {
      code: 'FR-CA',
      flag: '/assets/images/fr.png',
      i18nCode: 'fr-CA',
      label: 'French (Canada)',
    },
    {
      code: 'KA',
      flag: '/assets/images/ka.png',
      i18nCode: 'ka',
      label: 'Georgian',
    },
    {
      code: 'EL',
      flag: '/assets/images/el.png',
      i18nCode: 'el',
      label: 'Greek',
    },
    {
      code: "GU",
      flag: '/assets/images/india.png',
      i18nCode: 'gu',
      label: 'Gujarati',
    },
    {
      code: 'HT',
      flag: '/assets/images/ht.png',
      i18nCode: 'ht',
      label: 'Haitian Creole',
    },
    {
      code: 'HA',
      flag: '/assets/images/ha.png',
      i18nCode: 'ha',
      label: 'Hausa',
    },
    {
      code: 'HE',
      flag: '/assets/images/he.png',
      i18nCode: 'he',
      label: 'Hebrew',
    },
    {
      code: 'HI',
      flag: '/assets/images/india.png',
      i18nCode: 'hi',
      label: 'Hindi',
    },
    {
      code: 'HU',
      flag: '/assets/images/hu.png',
      i18nCode: 'hu',
      label: 'Magyar',
    },
    {
      code: 'IS',
      flag: '/assets/images/is.png',
      i18nCode: 'is',
      label: 'Icelandic',
    },
    {
      code: 'ID',
      flag: '/assets/images/id.png',
      i18nCode: 'id',
      label: 'Indonesian',
    },
    {
      code: 'GA',
      flag: '/assets/images/ga.png',
      i18nCode: 'ga',
      label: 'Irish',
    },
    {
      code: 'IT',
      flag: '/assets/images/it.png',
      i18nCode: 'it',
      label: 'Italian',
    },
    {
      code: 'JA',
      flag: '/assets/images/ja.png',
      i18nCode: 'ja',
      label: 'Japanese',
    },
    {
      code: 'KN',
      flag: '/assets/images/india.png',
      i18nCode: 'kn',
      label: 'Kannada',
    },
    {
      code: 'KK',
      flag: '/assets/images/kk.png',
      i18nCode: 'kk',
      label: 'Kazakh',
    },
    {
      code: 'KO',
      flag: '/assets/images/ko.png',
      i18nCode: 'ko',
      label: 'Korean',
    },
    {
      code: 'LV',
      flag: '/assets/images/lv.png',
      i18nCode: 'lv',
      label: 'Latvian',
    },
    {
      code: 'LT',
      flag: '/assets/images/lt.png',
      i18nCode: 'lt',
      label: 'Lithuanian',
    },
    {
      code: 'MK',
      flag: '/assets/images/mk.png',
      i18nCode: 'mk',
      label: 'Macedonian',
    },
    {
      code: 'MS',
      flag: '/assets/images/ms.png',
      i18nCode: 'ms',
      label: 'Malay',
    },
    {
      code: 'ML',
      flag: '/assets/images/india.png',
      i18nCode: 'ml',
      label: 'Malayalam',
    },
    {
      code: 'MT',
      flag: '/assets/images/mt.png',
      i18nCode: 'mt',
      label: 'Maltese',
    },
    {
      code: 'MR',
      flag: '/assets/images/india.png',
      i18nCode: 'mr',
      label: 'Marathi',
    },
    {
      code: 'MN',
      flag: '/assets/images/mn.png',
      i18nCode: 'mn',
      label: 'Mongolian',
    },
    {
      code: 'NO',
      flag: '/assets/images/no.png',
      i18nCode: 'no',
      label: 'Norwegian (Bokmål)',
    },
    {
      code: 'PS',
      flag: '/assets/images/af.png',
      i18nCode: 'ps',
      label: 'Pashto',
    },
    {
      code: 'PL',
      flag: '/assets/images/pl.png',
      i18nCode: 'pl',
      label: 'Polish',
    },
    {
      code: 'PT',
      flag: '/assets/images/pt.png',
      i18nCode: 'pt',
      label: 'Portuguese (Brazil)',
    },
    {
      code: 'PT-PT',
      flag: '/assets/images/pt.png',
      i18nCode: 'pt-PT',
      label: 'Portuguese (Portugal)',
    },
    {
      code: 'PA',
      flag: '/assets/images/india.png',
      i18nCode: 'pa',
      label: 'Punjabi',
    },
    {
      code: 'RO',
      flag: '/assets/images/ro.png',
      i18nCode: 'ro',
      label: 'Română',
    },
    {
      code: 'RU',
      flag: '/assets/images/ru.png',
      i18nCode: 'ru',
      label: 'Russian',
    },
    {
      code: 'SR',
      flag: '/assets/images/sr.png',
      i18nCode: 'sr',
      label: 'Serbian',
    },
    {
      code: 'SI',
      flag: '/assets/images/si.png',
      i18nCode: 'si',
      label: 'Sinhala',
    },
    {
      code: 'SK',
      flag: '/assets/images/sk.png',
      i18nCode: 'sk',
      label: 'Slovak',
    },
    {
      code: 'SL',
      flag: '/assets/images/sl.png',
      i18nCode: 'sl',
      label: 'Slovenian',
    },
    {
      code: 'SO',
      flag: '/assets/images/so.png',
      i18nCode: 'so',
      label: 'Somali',
    },
    {
      code: 'SO',
      flag: '/assets/images/so.png',
      i18nCode: 'so',
      label: 'Somali',
    },
    {
      code: 'ES',
      flag: '/assets/images/es.png',
      i18nCode: 'es',
      label: 'Spanish',
    },
    {
      code: 'ES-MX',
      flag: '/assets/images/es.png',
      i18nCode: 'es-MX',
      label: 'Spanish',
    },
    {
      code: 'SW',
      flag: '/assets/images/sw.png',
      i18nCode: 'sw',
      label: 'Swahili',
    },
    {
      code: 'SV',
      flag: '/assets/images/sv.png',
      i18nCode: 'sv',
      label: 'Swedish',
    },
    {
      code: 'TA',
      flag: '/assets/images/india.png',
      i18nCode: 'ta',
      label: 'Tamil',
    },
    {
      code: 'TE',
      flag: '/assets/images/india.png',
      i18nCode: 'te',
      label: 'Telugu',
    },
    {
      code: 'TH',
      flag: '/assets/images/th.png',
      i18nCode: 'th',
      label: 'Thai',
    },
    {
      code: 'TR',
      flag: '/assets/images/tr.png',
      i18nCode: 'tr',
      label: 'Turkish',
    },
    {
      code: 'UK',
      flag: '/assets/images/uk.png',
      i18nCode: 'uk',
      label: 'Ukrainian',
    },
    {
      code: 'UR',
      flag: '/assets/images/ur.png',
      i18nCode: 'ur',
      label: 'Urdu',
    },
    {
      code: 'UZ',
      flag: '/assets/images/uz.png',
      i18nCode: 'uz',
      label: 'Uzbek',
    },
    {
      code: 'VI',
      flag: '/assets/images/vi.png',
      i18nCode: 'vi',
      label: 'Vietnamese',
    },
    {
      code: 'CY',
      flag: '/assets/images/cy.png',
      i18nCode: 'cy',
      label: 'Welsh',
    }
  ]

  const filteredLanguages = languagesData.filter(lang => availableTranslationArray.includes(lang.code));

  const [os, setOS] = useState('')

  useEffect(() => {
    const userAgent = window.navigator.userAgent

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setOS('iOS')
    } else if (/Android/.test(userAgent)) {
      setOS('Android')
    } else {
      setOS('Other')
    }
    if (selectedLanguage) {
      setSelectedLanguage(selectedLanguage);
    }
    else {
      setSelectedLanguage('RO')
    }
  }, [])

  const handleLinkClick = () => {
    setShowMenu(false)
    setLangOpen(false)
    setShowLang(false)
  }

  console.log(selectedLanguage);

  const changeLanguage = (language, code) => {
    i18n.changeLanguage(code.toLowerCase())
    setSelectedLanguage(language)
    setShowLang(false)
    setLangOpen(false)
  }

  const handleOpenMap = () => {
    // let testAddress = 'str Izlazului 10, Cluj-Napoca, Cluj, 400609'
    const appleMapsUrl = `maps://search?q=${encodeURIComponent(address)}`
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`
    if (location?.pin_url) {
      window.open(location?.pin_url, '_blank')
    } else {
      if (os.toLocaleLowerCase() == 'ios') {
        // here should be logic for ios user to choose which maps they want to use
        window.open(appleMapsUrl, '_blank')
      } else {
        window.open(googleMapsUrl, '_blank')
      }
    }
  }

  const add3Hours = () => {
    const currentDate = new Date()
    currentDate.setHours(currentDate.getHours() + 10)
    return currentDate.toISOString()
  }

  const handleClearTable = async (metaDt, withUrl = false) => {
    setLoadingClearTable(true)
    const currentDatePlus3Hours = add3Hours()

    const formattedDate = currentDatePlus3Hours.slice(0, 19)
    let bodyData = {
      locationId: locationId,
      tableId: tableId,
      metadata: metaDt,
      url: withUrl ? location?.segway ?? '' : '',
      expirationDate: formattedDate,
    }
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/partners/ebriza_pushtablemetadata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      })
      toast.success(t('header.successMessage'))
    } catch (error) {
      toast.error(t('errorMessage'), {
        position: 'bottom-right',
      })
    } finally {
      setShowPopUp(false)
      setLoadingClearTable(false)
    }
  }

  return (
    <div
      className={`fixed z-40 top-0 right-0 w-full h-full bg-[#00000091] transition-all ${active
        ? 'opacity-100 pointer-events-auto'
        : 'opacity-0 pointer-events-none'
        }`}
    >
      <div
        className="absolute w-full top-0 left-0 bg-white shadow-lg border flex flex-col h-full transform translate-x-0 ease-in-out duration-500 overflow-auto pb-[30px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full flex justify-end items-center mt-4 px-8">
          <div onClick={handleLinkClick}>
            <CloseIcon />
          </div>
        </div>
        {address && (
          <div
            className="mt-6 flex items-center justify-between text-left pl-5 pr-9 duration-300 w-full"
            style={{ borderColor: '#FFB800' }}
            onClick={handleOpenMap}
          >
            <div className="flex">
              <img
                alt="Location"
                src="/assets/images/icons/location.svg"
                width={24}
              />
              <span className="text-base ml-2 text-clip">{address}</span>
            </div>
            <RiArrowDropDownLine
              color={'#3C3A34'}
              size={35}
              className="min-w-[35px]"
            />
          </div>
        )}
        <div className="mt-8 pl-5 pr-9">
          {location?.call_waiter && (
            <div
              onClick={() => setShowCallWaiter(true)}
              className="flex py-3 border-b-2"
            >
              <img
                alt="call-waiter"
                src="/assets/images/icons/call_waiter.png"
                width={22}
                className="h-[22px]"
              />
              <span className="text-base ml-2">{t('header.call_waiter')}</span>
            </div>
          )}
          {location?.ask_bill && (
            <div
              onClick={() => setShowAskBill(true)}
              className="flex py-3 border-b-2"
            >
              <img
                alt="ask-bill"
                src="/assets/images/icons/ask_bill.png"
                width={22}
              />
              <span className="text-base ml-2">{t('header.ask_bill')}</span>
            </div>
          )}
          {location?.clear_table && (
            <div
              onClick={() => setShowPopUp(true)}
              className="flex py-3 border-b-2"
            >
              <img
                alt="clear-table"
                src="/assets/images/icons/new-broom.png"
                width={22}
              />
              <span className="text-base ml-2">{t('header.clearTable')}</span>
            </div>
          )}
          <div
            className="mt-10 flex items-center justify-between text-left duration-300 "
            style={{ borderColor: '#FFB800' }}
            onClick={() => {
              setShowLang((prev) => !prev)
              setLangOpen((prev) => !prev)
            }}
          >
            <div className="flex">
              <img
                alt="globe"
                src="/assets/images/icons/globe.svg"
                width={22}
              />
              <span className="text-base ml-2">
                {`${t('header.language')}: ${languagesData.find((lang) => lang.code === selectedLanguage)?.code
                  }`}
              </span>
            </div>
            {availableTranslation &&
              (langOpen ? (
                <RiArrowDropUpLine color={'#3C3A34'} size={35} />
              ) : (
                <RiArrowDropDownLine color={'#3C3A34'} size={35} />
              ))}
          </div>
          {showLang && (
            <div className="absolute z-0 mt-2 w-[calc(100%_-_56px)] shadow-lg bg-white rounded-md">
              {availableTranslationArray && filteredLanguages.map((lang) => (
                <div
                  key={lang.code}
                  className={`cursor-pointer p-2 px-3 flex justify-between text-black text-[10px] items-center ${selectedLanguage === lang.code ? 'bg-primary bg-opacity-10' : ''}`}
                  onClick={() => {
                    localStorage.setItem('selectedLanguage', lang.code)
                    setSelectedLanguage(lang.code)
                    changeLanguage(lang.code, lang.i18nCode);
                  }}
                >
                  <div>
                    <img
                      src={lang.flag}
                      alt={lang.code}
                      className="inline-block w-6 h-4 mr-2"
                    />
                    {lang.label}
                  </div>
                  {selectedLanguage === lang.code && (
                    <div>
                      <span
                        style={{ color: colorCode }}
                        className={`text-lg material-symbols-outlined`}
                      >
                        check
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="pl-5 pr-9 flex flex-col-reverse  h-full">
          <div className="flex space-x-5 justify-center items-center">
            {location?.instagram && (
              <a
                rel="noopener"
                href={`https://${location?.instagram}`}
                target="_blank"
              >
                <img src="/assets/images/icons/ig.png" alt="IG" width={24} />
              </a>
            )}
            {location?.linkedin && (
              <a
                rel="noopener"
                href={`https://${location?.linkedin}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/linkedin.png"
                  alt="linkedin"
                  width={24}
                />
              </a>
            )}
            {location?.facebook && (
              <a
                rel="noopener"
                href={`https://${location?.facebook}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/facebook.png"
                  alt="FB"
                  width={24}
                />
              </a>
            )}
            {location?.tiktok && (
              <a
                rel="noopener"
                href={`https://${location?.tiktok}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/tiktok.png"
                  alt="tiktok"
                  width={24}
                />
              </a>
            )}
            {location?.youtube && (
              <a
                rel="noopener"
                href={`https://${location?.youtube}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/youtube.png"
                  alt="youtube"
                  width={24}
                />
              </a>
            )}
            {location?.twitter && (
              <a
                rel="noopener"
                href={`https://${location?.twitter}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/twitter.png"
                  alt="twitter"
                  width={24}
                />
              </a>
            )}
            {location?.website && (
              <a
                rel="noopener"
                href={`https://${location?.website}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/website.png"
                  alt="website"
                  width={24}
                />
              </a>
            )}
            {location?.website_url && (
              <a
                rel="noopener"
                href={`https://${location?.website_url}`}
                target="_blank"
              >
                <img
                  src="/assets/images/icons/website.png"
                  alt="website"
                  width={24}
                />
              </a>
            )}
          </div>
          {(location?.google_review_url ||
            location?.google_review_link ||
            location?.review_url ||
            location?.review_link) && (
              <div className="mb-14 space-y-2">
                {(location?.google_review_url ||
                  location?.google_review_link) && (
                    <a
                      href={`https://${location?.google_review_url || location?.google_review_link
                        }`}
                      rel="noopener"
                      target="_blank"
                    >
                      <div className="flex items-center justify-center border-2 rounded-full py-3">
                        {t('header.leave_feedback')}
                        <img
                          src="/assets/images/icons/google-maps.png"
                          className="h-[18px] ml-1"
                          alt="gmaps"
                          width={13}
                        />
                      </div>
                    </a>
                  )}
                {(location?.review_url || location?.review_link) && (
                  <div className="border-2 rounded-full py-3">
                    <a
                      className="flex items-center justify-center"
                      rel="noopener"
                      href={`https://${location?.review_url || location?.review_link
                        }`}
                      target="_blank"
                    >
                      {t('header.give_feedback')}
                      <img
                        src="/assets/images/icons/chat.png"
                        className="ml-1"
                        alt="gmaps"
                        width={20}
                      />
                    </a>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      {showPopUp && (
        <PopUpHamburgMenu
          desc={t('header.askToClearTable')}
          icon={'/assets/images/icons/new-broom.png'}
          handleClosePopUp={setShowPopUp}
          onConfirmClearTable={() => handleClearTable('DEBARASARE', true)}
          loading={loadingClearTable}
          colorCode={colorCode}
        />
      )}
      {showAskBill && (
        <PopUpHamburgMenu
          desc={t('header.ask_bill')}
          icon={'/assets/images/icons/ask_bill.png'}
          handleClosePopUp={setShowAskBill}
          onConfirmClearTable={() => handleClearTable('NOTA')}
          loading={loadingClearTable}
          colorCode={colorCode}
        />
      )}
      {showCallWaiter && (
        <PopUpHamburgMenu
          desc={t('header.call_waiter')}
          icon={'/assets/images/icons/call_waiter.png'}
          handleClosePopUp={setShowCallWaiter}
          onConfirmClearTable={() => handleClearTable('HELP')}
          loading={loadingClearTable}
          colorCode={colorCode}
        />
      )}
    </div>
  )
}

export default HamburgerInside
