import { useState } from 'react'
import { BiUser } from 'react-icons/bi'
import { FiChevronDown } from 'react-icons/fi'
import { AiOutlineMenu } from 'react-icons/ai'
import HeaderPopupMenu from './HeaderPopupMenu'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const HeaderDark = () => {
  const { i18n, t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  let selectedLanguage = i18n.language

  const languages = [
    { code: 'en', flag: '/assets/images/en.png' },
    { code: 'ro', flag: '/assets/images/ro.png' },
    { code: 'hu', flag: '/assets/images/hungary.png' },
  ]

  const changeLanguage = (langCode) => {
    selectedLanguage = langCode.toLowerCase()
    i18n.changeLanguage(langCode.toLowerCase())
    setShowMenu(false)
  }

  if (showDropdownMenu) {
    document.body.classList.add('body-lock')
  } else {
    document.body.classList.remove('body-lock')
  }

  return (
    <div className="z-50 absolute top-0 left-0 w-full bg-[#263238]">
      <div className="container">
        <header className="h-[80px] py-4 flex justify-between items-center w-full">
          <div className="w-[97px] md:w-[125px] xl:w-[160px] h-auto">
            <a href="/">
              <img src="/assets/images/logo.png" alt={t('header.logo_alt')} />
            </a>
          </div>
          <div className="lg:flex hidden">
            <ul className="flex justify-center gap-[40px] text-white">
              <li className="cursor-pointer">
                <Link to="/#home">{t('header.home')}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/#about">{t('header.about')}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/#features">{t('header.features')}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/#prices">{t('header.services')}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/#articles">{t('header.blog')}</Link>
              </li>
              <li className="cursor-pointer">
                <Link to="/#contact">{t('header.contact')}</Link>
              </li>
            </ul>
          </div>
          <div className="lg:flex hidden">
            <button className="text-white gap-3 items-center p-1 px-3 flex">
              <BiUser />
              Login
            </button>
            <div className="border-r-[1px] border-white p-1 px-2"></div>
            <div className="relative inline-block ml-6">
              <button
                className="text-white border border-white p-1 px-2 rounded-md flex items-center"
                onClick={() => setShowMenu(!showMenu)}
              >
                <img
                  src={
                    languages.find((lang) => lang.code === selectedLanguage)
                      .flag
                  }
                  alt="Selected Flag"
                  className="inline-block w-5 h-5 mr-2 rounded-full"
                />
                <FiChevronDown />
              </button>
              {showMenu && (
                <div className="absolute z-0 mt-2 shadow-lg bg-white rounded-md">
                  {languages.map((lang) => (
                    <div
                      key={lang.code}
                      className={`capitalize cursor-pointer p-2 px-3 flex text-black text-[10px] items-center ${
                        selectedLanguage === lang.code ? 'bg-primary' : ''
                      }`}
                      onClick={() => changeLanguage(lang.code)}
                    >
                      <img
                        src={lang.flag}
                        alt={lang.code}
                        className="inline-block w-5 h-5 mr-2 rounded-full"
                      />
                      {lang.code}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="lg:hidden flex items-center">
            <div className="relative inline-block ml-6">
              <button
                className="text-white border border-white p-1 px-2 rounded-md flex items-center"
                onClick={() => setShowMenu(!showMenu)}
              >
                <img
                  src={
                    languages.find((lang) => lang.code === selectedLanguage)
                      .flag
                  }
                  alt="Selected Flag"
                  className="inline-block w-5 h-5 mr-2 rounded-full"
                />
                <FiChevronDown />
              </button>
              {showMenu && (
                <div className="absolute z-0 mt-2 shadow-lg bg-white rounded-md">
                  {languages.map((lang) => (
                    <div
                      key={lang.code}
                      className={`capitalize cursor-pointer p-2 px-3 flex text-black text-[10px] items-center ${
                        selectedLanguage === lang.code ? 'bg-primary' : ''
                      }`}
                      onClick={() => changeLanguage(lang.code)}
                    >
                      <img
                        src={lang.flag}
                        alt={`${lang.code} Flag`}
                        className="inline-block w-5 h-5 mr-2 rounded-full"
                      />
                      {lang.code}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button className="text-white p-1 ml-3">
              <BiUser />
            </button>
            <div className="relative ml-3">
              <button
                className="text-white p-1"
                onClick={() => setShowDropdownMenu(!showDropdownMenu)}
              >
                <AiOutlineMenu size="1.5em" />
              </button>
              <HeaderPopupMenu
                setShowPopup={setShowDropdownMenu}
                active={showDropdownMenu}
              />
            </div>
          </div>
        </header>
      </div>
    </div>
  )
}

export default HeaderDark
