import { useTranslation } from 'react-i18next'
import RectangleButton from '../../common/RectangleButton'
import { BsArrowRight } from 'react-icons/bs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const Choose = () => {
  const { t } = useTranslation()
  return (
    <div className="relative">
      <div className="mt-[80px]">
        <div className="container">
          <h2 className="text-center uppercase md:text-[60px] text-[33px] leading-[45px] font-bold font-[Poppins] md:leading-[75px] not-italic">
            {t('homepage.choose_title')}
          </h2>
        </div>
        <div className="flex px-[11px] md:px-[15px] mt-[55px] w-full overflow-auto md:overflow-hidden snap-x gap-[20px] md:grid md:grid-cols-2 md:gap-[33px] md:max-w-[1140px] md:mx-auto">
          <div className="min-w-[300px] snap-center">
            <a
              href="/"
              className="block overflow-hidden w-full h-[190px] md:h-[340px]"
            >
              <LazyLoadImage
                src="/assets/images/choose1.png"
                alt="Holder Vertical “L” Plexiglas Personnalizat - A6"
                className="rounded-[15px] w-full h-full select-none object-cover"
                effect="opacity"
              />
            </a>
            <div className="flex flex-col justify-between mt-[17px] gap-[12px] md:mt-[22px] md:gap-[25px]">
              <h3 className="text-[16px] leading-[22px] md:text-[25px] md:leading-[30px] font-medium">
                {t('homepage.choose_heading1')}
              </h3>
              <div className="flex flex-row gap-[10px]">
                <p className="text-[20px] leading-[26px] md:text-[25px] not-italic font-bold">
                  {t('homepage.choose_heading1_price')}
                </p>
                <LazyLoadImage
                  src="/assets/images/Group 130.svg"
                  alt="5 stars"
                  effect="opacity"
                />
              </div>
            </div>
          </div>
          <div className="min-w-[300px] snap-center">
            <a
              href="/"
              className="block overflow-hidden w-full h-[190px] md:h-[340px]"
            >
              <LazyLoadImage
                src="/assets/images/choose2.png"
                alt="Stickere laminate Personalizate Rezistente la Intemperli -
                      10x7 cm"
                className="rounded-[15px] w-full h-full select-none object-cover"
                effect="opacity"
              />
            </a>
            <div className="flex flex-col justify-between mt-[17px] gap-[12px] md:mt-[22px] md:gap-[25px]">
              <h3 className="text-[16px] leading-[22px] md:text-[25px] md:leading-[30px] font-medium">
                {t('homepage.choose_heading2')}
              </h3>
              <div className="flex flex-row gap-[10px]">
                <p className="text-[20px] leading-[26px] md:text-[25px] not-italic font-bold">
                  {t('homepage.choose_heading2_price')}
                </p>
                <LazyLoadImage
                  src="/assets/images/Group 130.svg"
                  alt="5 stars"
                  effect="opacity"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-[22px]">
          <RectangleButton
            icon={<BsArrowRight />}
            text={t('homepage.pricing_details')}
            className="h-[41px] rounded-lg border-[#46464687] border-solid bg-[#FFF] border-[1px] hover:bg-[#808080] hover:text-white transition duration-200 py-[10px] px-[30px]"
            variant="icon"
          />
        </div>
      </div>
    </div>
  )
}

export default Choose
