export default function convertTimeStringToDateISO (timeString) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  
    const [dayString, timeRange] = timeString.split(", ");
    const [timeStart, timeEnd] = timeRange.split(" - ");
  
    let date;
    let mlscnd;
    if (dayString === "Today") {
      date = new Date(today.toLocaleString("en-US", { timeZone }));
      mlscnd = today.getTime();
    } else if (dayString === "Tomorrow") {
      date = new Date(tomorrow.toLocaleString("en-US", { timeZone }));
      mlscnd = tomorrow.getTime();
    } else {
      date = new Date(today.toLocaleString("en-US", { timeZone }));
      mlscnd = today.getTime();
    }
  
    let startHour;
    let startMinute;

  if (timeStart === "Now") {
    startHour = date.getHours() - 3; // Romanian time 3 hours ahead from UTC
    startMinute = date.getMinutes();
  } else {
    const [startHr, startMn] = timeStart.split(":").map(Number);
    startHour = startHr - 3; // Romanian time 3 hours ahead from UTC
    startMinute = startMn;
  }

  date.setHours(startHour, startMinute);

  date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);

  const isoStartDate = date.toISOString();

  return isoStartDate;
  };