import { forwardRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Image = forwardRef(
  (
    {
      onError,
      fallback,
      src,
      alt,
      effect = 'opacity',
      isLazy = true,
      ...props
    },
    ref
  ) => {
    const [imageSrc, setImageSrc] = useState(src)
    const handleError = (e) => {
      setImageSrc(fallback)
      e.target.setAttribute('data-invalid', true)
      onError?.(e)
    }

    return (
      <>
        {isLazy ? (
          <LazyLoadImage
            {...props}
            ref={ref}
            alt={alt}
            src={imageSrc}
            onError={handleError}
            effect='opacity'
          />
        ) : (
          <img
            {...props}
            ref={ref}
            alt={alt}
            src={imageSrc}
            onError={handleError}
          />
        )}
      </>
    )
  }
)

export default Image
