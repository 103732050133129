import { useId, useRef } from 'react'
import { TextArea } from '../../../common'

export const CommentBox = ({ onChange, value, className }) => {
  const inputId = useId()
  const ref = useRef()

  const handleChange = (e) => {
    ref.current.style.height = 'auto'
    ref.current.style.height = ref.current.scrollHeight + 'px'
    onChange(e.target.value)
  }

  return (
    <div className={`${className} flex flex-col gap-2`}>
      <label htmlFor={inputId}>Feedback</label>
      <TextArea
        ref={ref}
        value={value}
        onChange={handleChange}
        maxLength={150}
        style={{
          outline: 'none',
        }}
      />
    </div>
  )
}
