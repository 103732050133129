import { useEffect, useState } from 'react'
import { isValidImage } from '../../utils/isValidImage'
import {getProductFallbackImage} from "./ProductCategory";

export const useCategoryImage = (category) => {
  const [src, setSrc] = useState('')

  useEffect(() => {
    const fetchCategoryImage = async () => {
      const imageSrc = await getProductCategoryImg(category);
      if (!imageSrc) {
        const fallbackImageSrc = getProductFallbackImage(category, category?.Name);
        setSrc(fallbackImageSrc);
      } else {
        setSrc(imageSrc);
      }
    }

    if (category) {
      fetchCategoryImage()
    }
  }, [])

  return src
}

const getProductCategoryImg = async (category) => {
  if (category?.ImageUrl) {
    try {
      await isValidImage(category.ImageUrl)
      return category.ImageUrl
    } catch (error) {}
  }

  if (category?.Items) {
    for (let i = 0; i < category.Items.length; i++) {
      const result = await getProductCategoryImg(category.Items[i])
      if (result) return result
    }
  }

  return null
}
