import moment from 'moment-timezone'
const timeZone = 'Europe/Bucharest'

moment.locale('ro')

const getMomentInstanceFromString = (date, format) => {
  return moment.tz(date, format, true, timeZone)
}

const formatDate = (momentDate) => {
  return momentDate
    .toDate()
    .toLocaleDateString('ro-RO', { dateStyle: 'medium' })
}

export const formatReviewDate = (dateStr) => {
  const format = 'YYYY-MM-DD'
  let date = getMomentInstanceFromString(dateStr, format)
  if (date.isValid()) {
    return formatDate(date)
  }
  date = moment.tz(dateStr, timeZone)

  if (date.isValid()) {
    return formatDate(date)
  }
}

export const getNewReviewDate = () => {
  return moment().tz(timeZone).format('YYYY-MM-DD')
}
