export const addReview = async (body) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/partners/locations/rating_reviews`, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    throw new Error('Failed to add review')
  }

  return response.json()
}
