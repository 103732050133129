import React, { useContext, useEffect, useState } from 'react'
import { CloseIcon } from '../../components/icon'
import Picker from 'react-mobile-picker'
import { Button } from '../../common'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from '../../appContext/GlobalContext'
import toast from 'react-hot-toast'
  

const PopupSchedule = ({handleClose, colorCode, setPickupTime, showPopUp, pickupTime}) => {
    const { t } = useTranslation()
    const { openCloseHour } = useContext(GlobalContext)
    const [scheduleList, setScheduleList] = useState()
    const [tmrwSch, setTmrwSch] = useState([])
    const [todaySch, setTodaySch] = useState([])

    const defaultSchedule = {
        day: ['Today', 'Tomorrow'],
        time: ['12:00 - 12:30', '12:30 - 13:00', '13:00 - 13:30', '13:30 - 14:00', '14:00 - 14:30', '14:30 - 15:00', '15:00 - 15:30', '15:30 - 16:00', '16:30 - 17:00', '17:30 - 18:00', '18:00 - 18:30', '18:30 - 19:00', '19:00 - 19:30', '19:30 - 20:00']
        }

    const [pickerValue, setPickerValue] = useState()

    const handleConfirmSchedule = () => {
        if(!scheduleList.time.includes(pickerValue?.time)){
            toast.error(t('product.error_pickup_time'), {
                position: 'top',
              })
        } else {
            setPickupTime(pickerValue)
            handleClose(false)
        }
    }

    useEffect(() => {        
        if(openCloseHour.openHour) {

            let openHour = parseInt(openCloseHour.openHour.substring(0, 2));
            let closeHour = parseInt(openCloseHour.closeHour.substring(0, 2));

            let openMinute = parseInt(openCloseHour.openHour.substring(3, 5));

            let timeList = [];
            let tomorrowTimeList = []

            let currentHour = new Date().getHours();

            if(openHour < closeHour) {

                for (let hour = openHour; hour < closeHour; hour++) {
                    for (let minute = openMinute; minute < 60; minute += 20) {
                        let startHour = hour;
                        let startMinute = minute;
                        let endHour = hour;
                        let endMinute = minute + 20;
    
                        if (endMinute >= 60) {
                            endHour++; 
                            endMinute -= 60; 
                        }
    
                        let startTime = `${startHour < 10 ? '0' : ''}${startHour}:${startMinute < 10 ? '0' : ''}${startMinute}`;
                        let endTime = `${endHour < 10 ? '0' : ''}${endHour}:${endMinute < 10 ? '0' : ''}${endMinute}`;
                        let timeRange = `${startTime} - ${endTime}`;
                        
                        let currentMinute = new Date().getMinutes()
                        let currentTime = `${currentHour < 10 ? '0' : ''}${currentHour}:${currentMinute < 10 ? '0' : ''}${currentMinute}`;
    
                        if(hour + 1 == closeHour && minute == 40) {
                            let lastStartTime = `${startHour}:40`;
                            let lastEndTime = `${endHour}:00`;
                            let lastHour = `${lastStartTime} - ${lastEndTime}`;
                            timeList.push(lastHour)
                            tomorrowTimeList.push(lastHour)
                        }
                        if (hour === closeHour - 1 && minute + 20 >= 60) {
                            break; 
                        }
    
                        if (timeRange > currentTime) {
                            timeList.push(timeRange);
                        }
                        
                        tomorrowTimeList.push(timeRange)
                    }
    
                    openMinute = 0;
                }
                setTmrwSch(tomorrowTimeList)
                setTodaySch(timeList)
                setScheduleList(prev => ({day: ['Today', 'Tomorrow'], time: timeList}))
            }

            // let openToNum = openCloseHour.openHour.substring(0, 2)[0] == 0 ? openCloseHour.openHour.substring(0, 2)[1] : openCloseHour.openHour.substring(0, 2)
            // let closeToNum = openCloseHour.closeHour.substring(0, 2)[0] == 0 ? openCloseHour.closeHour.substring(0, 2)[1] : openCloseHour.closeHour.substring(0, 2)
            // let timeList = []
            // for (let i = Number(openToNum); i < Number(closeToNum); i++) {
            //     let plusOne = i+1
            //     let str = `${i < 10 ? '0' : ''}${i}:00 - ${plusOne < 10 ? '0' : ''}${plusOne}:00`
            //     timeList.push(str)
            // }
            
            if(pickupTime.time !== "Now") {
                setPickerValue(pickupTime)
            } else {
                setPickerValue({
                    day: 'Today',
                    time: timeList[0]
                })
            }
            // console.log(timeList)
        } else {
            setScheduleList(defaultSchedule)
            if(pickupTime.time !== "Now") {
                setPickerValue(pickupTime)
            } else {
                setPickerValue({
                    day: 'Today',
                    time: '15:00 - 15:30'
                })
            }
        }
    },[])

    useEffect(() => {
        if(pickerValue?.day == 'Tomorrow') {
            setScheduleList(prev => ({...prev, time: tmrwSch }))
        } else {
            setScheduleList(prev => ({...prev, time: todaySch}))
        }
    }, [pickerValue])
    
  
  return (
    <div className={`fixed flex flex-col justify-center items-center z-50 top-0 right-0 w-full h-full bg-[#00000091] transition-all `}>
        <div className='absolute flex flex-col bg-white border rounded h-auto top-2/4 bottom-0 w-auto px-5 py-4 rounded-t-3xl  inset-0 transition-opacity duration-300'>
            <div className='flex'>
                <div className='flex-1 text-center mt-3'>
                    {t('product.choose_pickup_time')}
                </div>
                <div onClick={() => handleClose(false)} >
                    <CloseIcon />
                </div>
            </div>
            {/* component time picker */}
            {scheduleList && (
                <div className='flex-1'>
                    <Picker value={pickerValue} onChange={setPickerValue} wheelMode="natural" height={190} >
                        {Object.keys(scheduleList).map(name => (
                            <Picker.Column key={name} name={name}>
                                {scheduleList[name].map(option => (
                                    <Picker.Item key={option} value={option}>
                                        {({ selected }) => (
                                            /* Use the `selected` state to conditionally style the selected item */
                                            <div style={{ opacity : selected ? '1' : '0.5', fontSize: selected ? '18px' : '12px'}}>
                                                {option}
                                            </div>
                                        )}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        ))}
                    </Picker>
                </div>
            )}
                <Button
                colorCode={colorCode}
                variant="outline"
                text={t('product.confirm')}
                onClick={handleConfirmSchedule}
                />
        </div>
    </div>
  )
}

export default PopupSchedule