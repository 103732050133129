import { useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { CSSTransition } from 'react-transition-group'
import '../styles/Dropdown.css'

const DropdownProduct = ({ title, content, iconColor, setShowProduct = () => {}, showProduct }) => {

  const toggleDropdown = () => {
    setShowProduct()
  }

  return 'length' in content && content.length > 0 ? (
    <div className="flex flex-col w-full">
      <div
        className="flex justify-between items-center cursor-pointer w-full pb-[10px] md:pb-[15px]"
        onClick={toggleDropdown}
      >
        <h1 className="text-[18px] md:text-[18px] font-semibold sm:text-[20px]">
          {title}
        </h1>
        {showProduct ? (
          <RiArrowDropUpLine color={!iconColor ? '#3C3A34' : ''} size={35} />
        ) : (
          <RiArrowDropDownLine color={!iconColor ? '#3C3A34' : ''} size={35} />
        )}
      </div>
      <CSSTransition classNames="slide" timeout={200} in={showProduct} unmountOnExit>
      <div className=''>{content}</div>
      </CSSTransition>
    </div>
  ) : ''
}

export default DropdownProduct
